import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ReactMarkdown from "react-markdown";

const serviceTermsText = `
# Privacy Policy 

## Introduction

Welcome to **Budgex**, a service provided by **FD Consult**. At **FD Consult**, we are committed to protecting your privacy and handling your personal data with care.

As a **data processor**, **FD Consult** collects and processes personal data on behalf of end-users of the Service. This means that the end-users are responsible for determining the purposes for which their personal data is collected and how it is used. **FD Consult** only collects and processes personal data as instructed by the end-users, and does not use personal data for any other purposes.

Please take a moment to read this Privacy Policy carefully, as it outlines how we collect, use, and share personal data in connection with the Service. By using the Service, you consent to the collection, use, and sharing of your personal data as described in this Privacy Policy. If you do not agree with our policies and practices, do not use the Service.

This Privacy Policy may be updated from time to time, so please review it periodically for any changes.

## Our role as Data Processor

**FD Consult** acts as a **data processor** in collecting and processing personal data on behalf of end-users of the Service. As a **data processor**, we assist the end-users, who act as data controllers, in performing an analysis of their expenses using the Service. We only collect and process personal data as instructed by the end-users, and do not use personal data for any other purposes. This includes, but is not limited to, the fact that we will not sell personal data in any way, including in an anonymized form.

Only a selected number of our employees have access to our production back-end systems that may allow them to see personal data. We take steps to ensure that access to personal data is restricted to those employees who need it in order to perform their duties, such as investigating a support request from an end-user.

## Data Collection

**FD Consult** collects certain personal data in order to provide the Service to end-users. 
In particular, we collect

1) Information around your **user profile, such as your name and email address**, which allows us to address you, contact you, and allow you to log in to the Service. This information is either provided by you when creating an account with us or received from a third-party Single Sign-On (SSO) service with your consent.

2) Any information that you directly input into **Budgex** through the Service, such as categories, classification rules, CSV files, and graphs. You input this information in order to categorize your transactions and gain insights from the categorization. This data is necessary for us to provide the Service to you, and it can be deleted by you from within the Service.

3) When you provide us with **explicit consent** to access your transactions history from your bank, we will collect this information from your bank on your behalf. This data is necessary for us to provide the Service to you.

## Cookies

**FD Consult** uses **functional cookies** in order to provide the Service to end-users. These cookies are used to make the Service work better, and they may include, but are not limited to, storing login information or storing user preferences in terms of layout and visualization. **FD Consult** does not use any tracking cookies.

End-users have the option to disable cookies by adjusting their browser settings. If end-users would like more information on cookies and how to disable or delete them, they can visit http://www.youronlinechoices.eu/. However, doing so may affect the functionality of the Service.

## Your rights as data owner

End-users have the following rights with regard to their personal data:

1) **The right to access the data**. End-users can exercise this right by requesting a copy of all information that **FD Consult** has about them. End-users can make this request by sending a mail to privacy@**budgex**.eu. **FD Consult** will comply with this request within 30 days.

2) **The right to rectify the data**. End-users can exercise this right by requesting that **FD Consult** correct any inaccurate or incomplete personal data. End-users can make this request by sending a mail to privacy@**budgex**.eu. **FD Consult** will comply with this request within 30 days.

3) **The right to erasure**. End-users can delete their account and all linked data from within **Budgex** by deleting their account. It may take up to 30 days before all data is fully removed from our servers.

## Security

**FD Consult** takes the security of end-users' personal data seriously. We store end-users' data in a safe way on our web servers, and all communication of this data between these servers and the outside world is end-to-end encrypted. We retain end-users' data until they request that we remove it from our servers.

`;

function PrivacyPolicy({}) {
  return (
    <ReactMarkdown
      children={serviceTermsText}
      components={{
        // Rewrite `em`s (`*like so*`) to `i` with a red foreground color.
        // p: ({ node, ...props }) => <i style={{ color: "red" }} {...props} />,
        h1: "h3",
        h2: "h4",
        p: ({ node, ...props }) => (
          <MDBox mt={2} mb={2}>
            <MDTypography {...props} variant="button" />
          </MDBox>
        ),
      }}
    />
  );
}

export default PrivacyPolicy;
