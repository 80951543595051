class ParsedGraphDefinition {
  constructor(title, graphType, graphItemsDefinition, timeRangesDefinition, id) {
    this.graphType = graphType;
    this.graphItemsDefinition = graphItemsDefinition;
    this.timeRangesDefinition = timeRangesDefinition;
    this.title = title;
    this.id = id;
  }

  generateGraphComponent(timeSeriesData) {}

  toJson() {
    return {
      graphType: this.graphType.toJson(),
      graphItemsDefinition: this.graphItemsDefinition.toJson(),
      timeRangesDefinition: this.timeRangesDefinition.toJson(),
      title: this.title.toJson(),
    };
  }
}

function parseGraphDefinition() {}

export default ParsedGraphDefinition;
