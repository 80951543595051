import { useState } from "react";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import React from "react";
import { GoogleLogin } from "@react-oauth/google";
import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
const FdcExpenses = require("fdc_expenses");

export function SignUpCard({ apiInstance, onLogin, apiCalls }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [mail, setMail] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const [serviceTermsAccepted, setServiceTermsAccepted] = useState(false);
  const [lastLoginError, setLastLoginError] = useState(false);
  const [serviceTermsNotAccepted, setServiceTermsNotAccepted] = useState(false);

  function loginCallback(error, data, response) {
    if (error) {
      apiCalls.addErrorAlert("Problem: " + error);
      setLastLoginError(true);
    } else {
      setLastLoginError(false);
      apiCalls.addInfoAlert("Login succesful", 3, "Login");
      onLogin(data.access_token);
    }
  }

  function googleSuccess(r) {
    if (serviceTermsAccepted) {
      apiInstance.loginWithGoogleToken(r.credential, loginCallback);
    } else {
      setServiceTermsNotAccepted(true);
    }
  }

  const tryToLogin = function (event) {
    setLastLoginError(false);
    apiInstance.login(username, password, {}, loginCallback);
  };

  function googleError(r) {
    console.log("Authentication by Google Failed", r);
  }

  const createNewUser = function () {
    const newUser = new FdcExpenses.UserWithLoginDetails(username);
    newUser.legacy_password = password;
    newUser.mail = mail;
    apiInstance.createNewUser(newUser, () => tryToLogin());
  };

  const passwordLongEnough = password.length > 5;
  const passwordMatches = password == passwordConfirmation;
  const correctMailAddress = mail.length > 0 && mail.includes("@") && mail.includes(".");
  const passwordTooEasy = !passwordLongEnough;

  return (
    <MDBox pt={4} pb={3} px={3}>
      <MDBox mb={3} mt={-2}>
        <Grid container justifyContent="center">
          <MDTypography variant="h6">Signup with Google</MDTypography>
        </Grid>
      </MDBox>
      {serviceTermsNotAccepted && !serviceTermsAccepted && (
        <Grid container justifyContent="center">
          <MDBox>
            <MDTypography variant="caption" color="error">
              Please accept the service terms
            </MDTypography>
          </MDBox>
        </Grid>
      )}
      <MDBox mb={2}>
        <Grid container justifyContent="center">
          <FormControl>
            <FormControlLabel
              label={
                <MDTypography variant="caption">
                  I accept the{" "}
                  <MuiLink style={{ color: "#0000FF" }} href="#" underline="always" onClick={() => setShowServiceTerms(true)}>
                    The Service Terms
                  </MuiLink>
                </MDTypography>
              }
              control={
                <Checkbox
                  checked={serviceTermsAccepted}
                  onChange={(ev) => {
                    setServiceTermsAccepted(ev.target.checked);
                  }}
                />
              }
            />
          </FormControl>
        </Grid>
      </MDBox>
      <MDBox mb={3}>
        <Grid container justifyContent="center">
          <Grid item>
            <GoogleLogin onSuccess={googleSuccess} onError={googleError} shape="pill"></GoogleLogin>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mb={3}>
        <Grid container justifyContent="center">
          <MDTypography variant="h6">OR</MDTypography>
        </Grid>
      </MDBox>

      <MDBox component="form" role="form">
        <div>
          <MDBox mb={2}>
            <MDInput type="email" label="Username" fullWidth value={username} onChange={(ev) => setUsername(ev.target.value)} />
          </MDBox>
          {mail.length > 5 && !correctMailAddress && (
            <MDBox mb={2}>
              <MDTypography variant="caption" color="error">
                Please use a valid mailadress
              </MDTypography>
            </MDBox>
          )}
          <MDBox mb={2}>
            <MDInput type="email" label="Emailadress" fullWidth value={mail} onChange={(ev) => setMail(ev.target.value)} />
          </MDBox>
          <MDBox mb={2}>
            <MDInput type="password" label="Password" fullWidth value={password} onChange={(ev) => setPassword(ev.target.value)} />
          </MDBox>
          {password.length > 5 && passwordConfirmation.length > 5 && !passwordMatches && (
            <MDBox mb={1}>
              <MDTypography variant="caption" color="error">
                Passwords do not match
              </MDTypography>
            </MDBox>
          )}
          <MDBox mb={2}>
            <MDInput type="password" label="Confirm Password" fullWidth value={passwordConfirmation} onChange={(ev) => setPasswordConfirmation(ev.target.value)} />
          </MDBox>

          <MDBox mb={2}>
            <Grid container justifyContent="center">
              <FormControl>
                <FormControlLabel
                  label={
                    <MDTypography variant="caption">
                      I accept the{" "}
                      <MuiLink style={{ color: "#0000FF" }} href="#" underline="always" onClick={() => setShowServiceTerms(true)}>
                        The Service Terms
                      </MuiLink>
                    </MDTypography>
                  }
                  control={
                    <Checkbox
                      checked={serviceTermsAccepted}
                      onChange={(ev) => {
                        setServiceTermsAccepted(ev.target.checked);
                      }}
                    />
                  }
                />
              </FormControl>
            </Grid>
          </MDBox>
          <MDBox mt={2} mb={3}>
            <MDButton
              variant="gradient"
              color="success"
              fullWidth
              onClick={(ev) => createNewUser(ev)}
              disabled={!username || !password || password != passwordConfirmation || passwordTooEasy || !serviceTermsAccepted || !correctMailAddress}
            >
              create new user
            </MDButton>
          </MDBox>
        </div>
      </MDBox>
    </MDBox>
  );
}
