/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import React from "react";
import MDButton from "components/MDButton";
import MenuItem from "@mui/material/MenuItem";

// @mui material components
import RuleCreatorV3 from "./rule_creator_v3";

import { useEffect, useState, useMemo } from "react";
import { CircularProgress, Dialog, LinearProgress, Select, Typography } from "@mui/material";
import TitledCard from "components/TitledCard/titled_card";
import { Box } from "@mui/system";
import MDProgress from "components/MDProgress";
import ClassificationProgress from "./classificationprogress";
import TransactionDetails from "layouts/pages/ruleanalysis/transactiondetails";

const FdcExpenses = require("fdc_expenses");

function ExpensesTable(props) {
  const getCategoryNameById = function (categoryId) {
    const matchedCategories = props.categories.filter((cat) => cat.id == categoryId);
    if (matchedCategories.length > 0) {
      return matchedCategories[0].name;
    } else {
      return "";
    }
  };

  const getExpensesTableData = function () {
    var relevantTransactions = [];

    if (props.expenses) {
      relevantTransactions = props.expenses;
    }

    const createRow = function (classified_expense) {
      return {
        time: classified_expense.timestamp.toLocaleDateString(),
        category_id: getCategoryNameById(classified_expense.category_id),
        amount: classified_expense.amount,
        account: classified_expense.account,
        description: classified_expense.description,
        comments: classified_expense.comments,
        counter_part_name: classified_expense.counter_part_name,
        counter_part_account: classified_expense.counter_part_account,
        transaction: classified_expense,
      };
    };

    const categoryData = {
      columns: [
        {
          Header: "Counter Part Name",
          accessor: "counter_part_name",
          Cell: ({ value, row }) => {
            return (
              <MDButton
                onClick={(event) => {
                  props.onProposeRuleFor([relevantTransactions[row.index], value]);
                }}
              >
                {value}
              </MDButton>
            );
          },
        },
        {
          Header: "Description",
          accessor: "description",
          Cell: ({ value, row }) => {
            return (
              <MDButton
                onClick={(event) => {
                  props.onProposeRuleFor([relevantTransactions[row.index], value]);
                }}
              >
                {value}
              </MDButton>
            );
          },
        },
        { Header: "Amount", accessor: "amount" },
        { Header: "Time", accessor: "time" },
        {
          Header: "Comments",
          accessor: "comments",
          Cell: ({ value, row }) => {
            return (
              <MDButton
                onClick={(event) => {
                  props.onProposeRuleFor([relevantTransactions[row.index], value]);
                }}
              >
                {value}
              </MDButton>
            );
          },
        },
        { Header: "Counter Part Account", accessor: "counter_part_account" },
        { Header: "Account", accessor: "account" },
        { Header: "Category ID", accessor: "category_id" },
        { Header: "ID", accessor: "id" },
      ],
      rows: relevantTransactions.map(createRow),
    };

    return categoryData;
  };

  const cachedGetExpensesTableData = function () {
    return useMemo(() => {
      return getExpensesTableData();
    }, [props.expenses]);
  };

  return (
    <MDBox pt={6} pb={3} id="MDBoxForTable">
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={1}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              justifyContent="center"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Transaction Table
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <DataTable
                table={cachedGetExpensesTableData()}
                isSorted={true}
                entriesPerPage={false}
                showTotalEntries={true}
                noEndBorder
                canSearch={true}
                key="datatableforexpenses"
              />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default ExpensesTable;
