import { Grid, Icon } from "@mui/material";
import MDButton from "components/MDButton";

export function ManualTransactionsToolBar({ apiCalls, onOpen }) {
  return (
    <Grid container justifyContent="center">
      <div class="AddManualTransaction">
        <MDButton startIcon={<Icon>add</Icon>} onClick={onOpen}>
          Create new manual transaction
        </MDButton>
      </div>
    </Grid>
  );
}
