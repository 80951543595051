import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import { useState } from "react";
import ReactJoyride from "react-joyride";
import { SetupStep } from "./SetupStep";
import { stopJoyRideWhenFinished } from "utils/JoyRideUtils";
import { useNavigate } from "react-router-dom";
import FDDemo from "utils/FDDemo";
import MDTypography from "components/MDTypography";

const openMenuStep = {
  target: ".MenuOpenButton",
  title: "Open Navigation menu",
  content: <MDTypography variant="button">Use this button to open the navigation menu</MDTypography>,
  disableBeacon: true,
};

const bankLinkSteps = [
  {
    target: ".SideNavItemBank_Link",
    title: "Bank Link Menu",
    content: <MDTypography variant="button">Use this menu to automatically download transactions from your bank</MDTypography>,
    disableBeacon: true,
    locale: { last: "Go to this page" },
  },
];

const ruleSteps = [
  {
    title: "Transaction List Menu",
    target: ".SideNavItemTransaction_List",

    content: (
      <MDTypography variant="button">
        "Use this menu to get an overview of your transactions. By clicking on a transaction, you can define rules or assign categories. Click next to go to this page and continue the demo"
      </MDTypography>
    ),
    disableBeacon: true,
    locale: { last: "Go to this page" },
  },
];

const csvSteps = [
  {
    title: "CSV Files Menu",
    target: ".SideNavItemCSV_Files",

    content: (
      <MDTypography variant="button">
        "Use this menu option to upload transactions from CSV files. Don't forget to parse the CSV files after uploading them. Click next to go to this page and continue the demo"
      </MDTypography>
    ),
    disableBeacon: true,
    locale: { last: "Go to this page" },
  },
];

const manualTransactionsSteps = [
  {
    title: "Manual Transactions Menu",
    target: ".SideNavItemManual_Transactions",
    content: <MDTypography variant="button">"Use this menu option to manually create or delete transactions"</MDTypography>,
    disableBeacon: true,
    locale: { last: "Go to this page" },
  },
];

const categoryManagementDemoSteps = [
  {
    title: "Category Mangement Menu",
    target: ".SideNavItemCategory_Management",

    content: (
      <MDTypography variant="button">
        "Use this menu option create, modify or delete categories. You can create as many categories (and subcategories) as you want. Click next to go to this page and continue the demo"
      </MDTypography>
    ),
    locale: { last: "Go to this page" },
    disableBeacon: true,
  },
];

const graphDemo = [
  {
    title: "Dashboard Menu",
    target: ".SideNavItemPersonal_Dashoard",

    content: (
      <MDTypography variant="button">
        "This menu button leads to your personal dashboard. On this page you get graphs and insights about your transaction categories. Click next to go to this page and continue the demo"
      </MDTypography>
    ),
    disableBeacon: true,
    locale: { last: "Go to this page" },
  },
];

function addMenuOpenStepIfNeeded(steps, applicationData) {
  if (applicationData.miniSidenav) {
    console.log("have to force the menu open");
    const resultingSteps = [openMenuStep].concat(steps);
    console.log(resultingSteps);
    return resultingSteps;
  } else {
    return steps;
  }
}

export function AccountSetup({ applicationData, apiCalls }) {
  const [showDemo, setShowDemo] = useState(false);
  const [steps, setSteps] = useState([]);
  const [onDoneCallback, setOnDoneCallback] = useState();

  const navigate = useNavigate();

  function showBankLinkDemo() {
    apiCalls.setForceExpand("data");
    setSteps(addMenuOpenStepIfNeeded(bankLinkSteps, applicationData));
    apiCalls.setMiniSidenav(false);
    setShowDemo(true);
    setOnDoneCallback(() => () => {
      navigate({ pathname: "/data/banklink", search: "?showHelp=True" });
    });
  }

  function showRuleDemo() {
    setSteps(addMenuOpenStepIfNeeded(ruleSteps, applicationData));
    apiCalls.setMiniSidenav(false);
    setShowDemo(true);
    setOnDoneCallback(() => () => {
      navigate({ pathname: "/transactionlist", search: "?showHelpRule=True" });
    });
  }

  function showSpecificAssignmentDemo() {
    setSteps(addMenuOpenStepIfNeeded(ruleSteps, applicationData));
    apiCalls.setMiniSidenav(false);
    setShowDemo(true);
    setOnDoneCallback(() => () => {
      navigate({ pathname: "/transactionlist", search: "?showHelpSingle=True" });
    });
  }

  function showCSVDemo() {
    apiCalls.setForceExpand("data");
    setSteps(addMenuOpenStepIfNeeded(csvSteps, applicationData));
    apiCalls.setMiniSidenav(false);
    setShowDemo(true);
    setOnDoneCallback(() => () => {
      navigate({ pathname: "/data/transactionsfiles", search: "?showHelp=True" });
    });
  }

  function showManualTransactionsDemo() {
    apiCalls.setForceExpand("data");
    setSteps(addMenuOpenStepIfNeeded(manualTransactionsSteps, applicationData));
    apiCalls.setMiniSidenav(false);
    setShowDemo(true);
    setOnDoneCallback(() => () => {
      navigate({ pathname: "/data/manual_transactions", search: "?showHelp=True" });
    });
  }

  function showCategoryDemo() {
    setSteps(addMenuOpenStepIfNeeded(categoryManagementDemoSteps, applicationData));
    apiCalls.setMiniSidenav(false);
    setShowDemo(true);
    setOnDoneCallback(() => () => {
      navigate({ pathname: "/categorymanagement", search: "?showHelp=True" });
    });
  }

  function showGraphDemo() {
    setSteps(addMenuOpenStepIfNeeded(graphDemo, applicationData));
    apiCalls.setMiniSidenav(false);
    setShowDemo(true);
    setOnDoneCallback(() => () => {
      navigate({ pathname: "/dashboard", search: "?showHelp=True" });
    });
  }

  const bankIsLinked = applicationData?.bankLinks.length > 0;
  const hasCsvFile = applicationData?.csvFiles.filter((c) => c.number_of_transactions > 0).length > 0;
  const hasManualTransactions = applicationData?.manualTransactions.length > 0;

  const hasGenericRule = applicationData?.rules.filter((rule) => !rule.specific && !rule.frozen).length > 0;
  const hasSpecificRule = applicationData?.rules.filter((rule) => rule.specific && !rule.frozen).length > 0;
  const hasPersonalGraph = applicationData?.setupStatus.graphs_created > 0;
  const didCreateCategory = applicationData?.setupStatus.categories_created > 0;

  return (
    <MDBox m={1}>
      <Grid container justifyContent={"center"}>
        <Grid item xs={12} md={4}>
          <SetupStep
            title="Step 1: Import data in Budgex"
            done={bankIsLinked || hasCsvFile || hasManualTransactions}
            description="Configure the datasources to import transactions in Budgex.eu"
            tasks={[
              { name: "Download transactions from your bank", done: bankIsLinked, callback: showBankLinkDemo },
              { name: "Import transactions from a CSV File", done: hasCsvFile, callback: showCSVDemo },
              { name: "Manually create transactions", done: hasManualTransactions, callback: showManualTransactionsDemo },
            ]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SetupStep
            title="Step 2: Classify transactions"
            done={(hasGenericRule || hasSpecificRule) && didCreateCategory}
            description="Setup classification rules for assigning the correct category to transactions"
            tasks={[
              { name: "Personalise categories", done: didCreateCategory, callback: showCategoryDemo },
              { name: "Create general classification rule for multiple transactions", done: hasGenericRule, callback: showRuleDemo },
              { name: "Assign a category to a single transaction", done: hasSpecificRule, callback: showSpecificAssignmentDemo },
            ]}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SetupStep
            title="Step 3: Setup your graphs"
            done={hasPersonalGraph}
            description="Setup the graphs that you want to see on your dashboard for getting more insight"
            tasks={[{ name: "Create your own graph", done: hasPersonalGraph, callback: showGraphDemo }]}
          />
        </Grid>
      </Grid>
      <FDDemo
        run={showDemo}
        steps={steps}
        onFinished={() => {
          // The Done callback is only done, when we properly finish the demo
          if (onDoneCallback != undefined) {
            onDoneCallback();
            setOnDoneCallback(undefined);
          }
        }}
        onStop={() => {
          // Stop the demo, and reset all information that was needed for running the demo
          apiCalls.setForceExpand(undefined);
          setShowDemo(false);
          apiCalls.setMiniSidenav(true);
        }}
      />
    </MDBox>
  );
}
