import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import React from "react";
import MDButton from "components/MDButton";
import MenuItem from "@mui/material/MenuItem";

// @mui material components
import RuleCreatorV3 from "./rule_creator_v3";

import { useEffect, useState, useMemo } from "react";
import { CircularProgress, Dialog, LinearProgress, Select, Typography } from "@mui/material";
import TitledCard from "components/TitledCard/titled_card";
import { Box } from "@mui/system";
import MDProgress from "components/MDProgress";

const FdcExpenses = require("fdc_expenses");

function ClassificationProgress({ categories, expenses, selectedCategoryId }) {
  function getAllExpensesForCategories(cats) {
    const catIds = cats.map((cat) => cat.id);
    return expenses.filter((exp) => catIds.includes(exp.category_id));
  }

  function getAllRecursiveChildren(cat) {
    const directChildren = categories.filter((exp) => exp.parent_id == cat.id);
    const indirectChildren = directChildren.flatMap((childCategory) => getAllRecursiveChildren(childCategory));

    return directChildren.concat(indirectChildren);
  }

  function getAllRecursiveExpensesForCategory(cat) {
    return getAllExpensesForCategories([cat]);
  }

  function getCategoryById(catId) {
    return categories.filter((cat) => cat.id == catId)[0];
  }

  function getClassificationRatios() {
    if (selectedCategoryId) {
      const selectedCategory = getCategoryById(selectedCategoryId);
      const directExpenses = getAllRecursiveExpensesForCategory(selectedCategory);
      const numberOfDirectExpenses = directExpenses.length;
      const throughtputOfDirectExpenses = directExpenses.map((exp) => exp.amount).reduce((a, b) => Math.abs(a) + Math.abs(b), 0);

      const childCategories = getAllRecursiveChildren(selectedCategory);
      const indirectExpenses = getAllExpensesForCategories(childCategories);
      const numberOfIndirectExpenses = indirectExpenses.length;
      const throughtputOfIndirectExpenses = indirectExpenses.map((exp) => exp.amount).reduce((a, b) => Math.abs(a) + Math.abs(b), 0);

      const numberOfTransactionsClassificationRatio = numberOfIndirectExpenses / (numberOfIndirectExpenses + numberOfDirectExpenses);
      const throughputOfTransactionsClassificationRatio = throughtputOfIndirectExpenses / (throughtputOfIndirectExpenses + throughtputOfDirectExpenses);

      return [numberOfTransactionsClassificationRatio, throughputOfTransactionsClassificationRatio];
    } else {
      return [0, 0];
    }
  }

  const classificationRatios = useMemo(() => getClassificationRatios(), [categories, expenses, selectedCategoryId]);

  return (
    <div>
      <MDBox padding={3}>
        <Grid container display="flex" alignItems="center" spacing={1}>
          <Grid item xs={2}>
            <MDTypography variant="button">Transactions classified</MDTypography>
          </Grid>
          <Grid item xs={8}>
            <MDProgress value={classificationRatios[0] * 100} />
          </Grid>
          <Grid item xs={1}>
            <MDTypography variant="button">{Math.round(classificationRatios[0] * 100)}%</MDTypography>
          </Grid>
          <Grid item xs={2}>
            <MDTypography variant="button">Total value classified</MDTypography>
          </Grid>
          <Grid item xs={8}>
            <MDProgress value={classificationRatios[1] * 100} />
          </Grid>
          <Grid item xs={1}>
            <MDTypography variant="button">{Math.round(classificationRatios[1] * 100)}%</MDTypography>
          </Grid>
        </Grid>
      </MDBox>
    </div>
  );
}

export default ClassificationProgress;
