import { modulo } from "./math";

const colors = [
  "#1f77b4",
  "#aec7e8",
  "#ff7f0e",
  "#ffbb78",
  "#2ca02c",
  "#98df8a",
  "#d62728",
  "#ff9896",
  "#9467bd",
  "#c5b0d5",
  "#8c564b",
  "#c49c94",
  "#e377c2",
  "#f7b6d2",
  "#7f7f7f",
  "#c7c7c7",
  "#bcbd22",
  "#dbdb8d",
  "#17becf",
  "#9edae5",
];

var colors2 = [
  // "#fdfdfd",
  "#ebce2b",
  "#702c8c",
  "#db6917",
  "#96cde6",
  "#ba1c30",
  "#c0bd7f",
  "#7f7e80",
  "#5fa641",
  "#d485b2",
  "#4277b6",
  "#df8461",
  "#463397",
  "#e1a11a",
  "#91218c",
  "#e8e948",
  "#7e1510",
  "#92ae31",
  "#6f340d",
  "#d32b1e",
  "#2b3514",
  "#1d1d1d",
];

const hashCode = function (s) {
  var h = 0,
    l = s.length,
    i = 0;
  if (l > 0) while (i < l) h = ((h << 5) - h + s.charCodeAt(i++)) | 0;
  return Math.abs(h);
};

const getCategoryName = function (categoryId, categories) {
  const relevantCats = categories.filter((cat) => cat.id == categoryId);
  if (relevantCats.length > 0) {
    return relevantCats[0].name;
  }
};

function getColorForCategoryName(catName) {
  if (catName == "Expenses") {
    return "#d62728";
  } else if (catName == "Income") {
    return "#98df8a";
  }

  return colors[modulo(hashCode(catName), colors.length)];
}

const getColorFor = function (categoryId, categories) {
  const catName = getCategoryName(categoryId, categories);

  if (catName) {
    if (catName == "Expenses") {
      return "#d62728";
    } else if (catName == "Income") {
      return "#98df8a";
    }

    return colors[modulo(hashCode(catName), colors.length)];
  } else {
    return "#FF0000";
  }
};

function getColorByIndex(index) {
  return colors[modulo(index, colors.length)];
}

export { getColorFor, colors, getColorByIndex, getColorForCategoryName };
