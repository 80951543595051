import { Card, Chip, Grid } from "@mui/material";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import GenericCard from "components/TitledCard/GenericCard";
import { QRCodeCanvas } from "qrcode.react";
import { useState } from "react";

function SecurityPage({ applicationData, apiClient, callbacks, apiCalls }) {
  const [urlToShow, setUrlToShow] = useState();
  const [tokenInput, setTokenInput] = useState();

  if (applicationData.twoFaEnabled) {
    return (
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <MDBox m={2}>
              <MDBadge variant="contained" color="success" badgeContent="2FA Enabled" container />
            </MDBox>
          </Grid>
        </Grid>
        <Grid item>
          <MDBox m={2}>
            <MDInput label="Enter 2FA Token Here" fullWidth value={tokenInput} onChange={(ev) => setTokenInput(ev.target.value)}></MDInput>
          </MDBox>
        </Grid>
        <Grid item>
          <MDBox m={2}>
            <MDButton
              color="dark"
              onClick={() => {
                apiCalls.disableTwoFa(tokenInput);
                setTokenInput();
              }}
            >
              Disable 2FA
            </MDButton>
          </MDBox>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container justifyContent="center">
        {!urlToShow && (
          <MDButton
            color="dark"
            onClick={() =>
              apiClient.generateNewTwoFaSecret((error, data, response) => {
                if (!error) {
                  setUrlToShow(data);
                }
              })
            }
          >
            Enable 2FA
          </MDButton>
        )}
        {urlToShow && (
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <MDBox m={1}>
                <QRCodeCanvas value={urlToShow}></QRCodeCanvas>
              </MDBox>
            </Grid>
          </Grid>
        )}
        {urlToShow && (
          <Grid>
            <MDBox m={1}>
              <MDInput label="Enter 2FA Token Here" fullWidth value={tokenInput} onChange={(ev) => setTokenInput(ev.target.value)}></MDInput>
            </MDBox>
          </Grid>
        )}
        {urlToShow && (
          <Grid item>
            <MDBox m={1}>
              <MDButton
                color="dark"
                onClick={() => {
                  apiCalls.enableTwoFa(tokenInput);
                  setTokenInput();
                }}
              >
                Enable 2FA
              </MDButton>
            </MDBox>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default SecurityPage;
