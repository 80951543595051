/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import ExpensesTable from "layouts/expenses/expensestable";
import ExpensesTrend from "layouts/dashboards/trends/expensestrend";

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images

import CategoryManagement from "layouts/categorymanagement/categorymanagement";

import RuleAnalysis from "layouts/pages/ruleanalysis/ruleanalysis";
import ClassificationFlowPage from "layouts/pages/classificationflow/classificationflowpage";

import TrendPageV2 from "layouts/dashboards/trendv2/trendpagev2";

import TransactionsFilesPage from "layouts/pages/transactionsfilepage/TransactionsFilesPage";
import BarPageV2 from "layouts/pages/barpagev2/BarPageV2";
import BankLinkPageV2 from "layouts/pages/banklink/BankLinkPageV2";
import DashboardPage from "layouts/pages/dashboard/DashboardPage";
import GraphBuilderPage from "layouts/pages/graphbuilder/GraphBuilderPage";
import PersonalGraphsPage from "layouts/pages/graphbuilder/PersonalGraphsPage";
import TransactionListPage from "layouts/pages/expensestable/TransactionListPage";
import ManualTransactionsPage from "layouts/pages/manualtransactions/ManualTransactionsPage";
import ConflictsPage from "layouts/pages/ruleanalysis/ConflictsPage";
import RuleStatisticsPage from "layouts/pages/ruleanalysis/RuleStatisticsPage";
import AccountPage from "layouts/pages/budgexaccount/AccountPage";
import ProblemReportPage from "layouts/pages/reportproblems/ProblemReportPage";
import DeleteMePage from "layouts/pages/DeleteMePage";
import AccountDetailsPage from "layouts/pages/budgexaccount/AccountDetailsPage";
import SetupAccountPage from "layouts/pages/budgexaccount/SetupAccountPage";
import SubscriptionPage from "layouts/pages/budgexaccount/SubscriptionsPage";
import SecurityPage from "layouts/pages/budgexaccount/SecurityPage";

const routes = function (apiClient, categories, rules, csvFiles, manualAccountNames, dailyTimeseriesData, callbacks, apiCalls, applicationData) {
  return [
    {
      type: "collapse",
      name: "Dashboard",
      key: "dashboard",
      route: "/dashboard",
      icon: <Icon fontSize="medium">show_chart</Icon>,
      component: (
        <PersonalGraphsPage apiClient={apiClient} applicationData={applicationData} callbacks={callbacks} categories={categories} apiCalls={apiCalls} dailyTimeseriesData={dailyTimeseriesData} />
      ),
      noCollapse: true,
    },
    {
      name: "Transaction List",
      key: "transactionlist",
      route: "/transactionlist",
      type: "collapse",
      noCollapse: true,
      icon: <Icon fontSize="medium">receipt</Icon>,
      component: (
        <TransactionListPage
          applicationData={applicationData}
          apiClient={apiClient}
          categories={categories}
          rules={rules}
          callbacks={callbacks}
          apiCalls={apiCalls}
          csvFiles={csvFiles}
          manualAccountNames={manualAccountNames}
        />
      ),
    },
    {
      name: "Category Management",
      key: "categorymanagement",
      route: "/categorymanagement",
      type: "collapse",
      noCollapse: true,
      icon: <Icon fontSize="medium">account_tree</Icon>,

      component: (
        <CategoryManagement
          apiClient={apiClient}
          categories={categories}
          rules={rules}
          callbacks={callbacks}
          apiCalls={apiCalls}
          manualAccountNames={manualAccountNames}
          applicationData={applicationData}
        />
      ),
    },

    {
      type: "collapse",
      name: "Data Sources",
      key: "data",
      icon: <Icon fontSize="medium">storage</Icon>,
      collapse: [
        {
          name: "Link Bank Account",
          key: "banklink",
          route: "/data/banklink",
          component: (
            <BankLinkPageV2
              apiClient={apiClient}
              categories={categories}
              rules={rules}
              callbacks={callbacks}
              apiCalls={apiCalls}
              manualAccountNames={manualAccountNames}
              applicationData={applicationData}
            />
          ),
        },
        {
          name: "Manual Data Input",
          key: "manual_transactions",
          route: "/data/manual_transactions",
          component: <ManualTransactionsPage applicationData={applicationData} apiCalls={apiCalls} />,
        },

        {
          name: "Upload Data",
          key: "transactionsfiles",
          route: "/data/transactionsfiles",
          component: (
            <TransactionsFilesPage apiClient={apiClient} categories={categories} rules={rules} callbacks={callbacks} apiCalls={apiCalls} csvFiles={csvFiles} manualAccountNames={manualAccountNames} />
          ),
        },
      ],
    },

    {
      type: "collapse",
      name: "Advanced",
      key: "management",
      icon: <Icon fontSize="medium">display_settings</Icon>,
      collapse: [
        {
          name: "Classification Conflicts",
          key: "conflicts",
          route: "/management/conflicts",
          component: <ConflictsPage applicationData={applicationData} apiCalls={apiCalls} apiClient={apiClient} callbacks={callbacks} />,
        },
        {
          name: "Rule Statistics",
          key: "rules",
          route: "/management/rules",
          component: <RuleStatisticsPage applicationData={applicationData} apiCalls={apiCalls} apiClient={apiClient} callbacks={callbacks} />,
        },
        {
          name: "Report a problem",
          key: "problem",
          route: "/problem",
          type: "collapse",
          noCollapse: true,
          icon: <Icon fontSize="medium">report</Icon>,

          component: <ProblemReportPage apiCalls={apiCalls} applicationData={applicationData} apiClient={apiClient} callbacks={callbacks} />,
        },
      ],
    },

    {
      type: "collapse",
      name: "Account",
      key: "account",
      icon: <Icon fontSize="medium">manage_accounts</Icon>,
      collapse: [
        {
          name: "Account Details",
          key: "account_details",
          route: "/account/details",
          component: <AccountDetailsPage apiCalls={apiCalls} applicationData={applicationData} apiClient={apiClient} callbacks={callbacks} />,
        },
        {
          name: "Account Setup",
          key: "account_setup",
          route: "/account/setup",
          component: <SetupAccountPage apiCalls={apiCalls} applicationData={applicationData} apiClient={apiClient} callbacks={callbacks} />,
        },
        {
          name: "Subscriptions",
          key: "subscriptions",
          route: "/account/subscriptions",
          component: <SubscriptionPage apiCalls={apiCalls} applicationData={applicationData} apiClient={apiClient} callbacks={callbacks} />,
        },
        {
          name: "Account Security",
          key: "security",
          route: "/account/security",
          component: <SecurityPage apiCalls={apiCalls} applicationData={applicationData} apiClient={apiClient} callbacks={callbacks} />,
        },
      ],
    },

    // {
    //   name: "Account",
    //   key: "account",
    //   route: "/account",
    //   type: "collapse",
    //   noCollapse: true,
    //   icon: <Icon fontSize="medium">manage_accounts</Icon>,

    //   component: <AccountPage apiCalls={apiCalls} applicationData={applicationData} apiClient={apiClient} callbacks={callbacks} />,
    // },

    // {
    //   name: "DeleteMe",
    //   key: "DeleteMe",
    //   route: "/DeleteMe",
    //   type: "collapse",
    //   noCollapse: true,
    //   icon: <Icon fontSize="medium">manage_accounts</Icon>,

    //   component: <DeleteMePage apiCalls={apiCalls} applicationData={applicationData} apiClient={apiClient} callbacks={callbacks} />,
    // },
  ];
};

export default routes;
