import { Dialog, Divider } from "@mui/material";
import MDBox from "components/MDBox";
import { useEffect, useMemo, useState } from "react";
import ConflictDetails from "./conflictdetails";
import ConflictTable from "./conflicttable";
import RuleTable from "./ruletable";

const { default: TitledCard } = require("components/TitledCard/titled_card");

function RuleAnalysis({ categories, rules, analysisResult, apiClient, callbacks, apiCalls }) {
  // const [categories, setCategories] = useState([]);
  // const [analysisResult, setAnalysisResult] = useState()
  // const [rules, setRules] = useState([])
  const [analyseConflict, setAnalyseConflict] = useState(false);
  const [conflictToAnalyse, setConflictToAnalyse] = useState();

  useEffect(() => apiCalls.updateAnalysisV2IfNeeded());

  return (
    <div>
      <TitledCard title="Conflicts">
        <ConflictTable
          categories={categories}
          analysisResult={analysisResult}
          rules={rules}
          onSelectedConflict={(conflict) => {
            setAnalyseConflict(true);
            setConflictToAnalyse(conflict);
          }}
        />
      </TitledCard>
      <TitledCard title="Rule Analysis">
        <MDBox ml={3}>
          <RuleTable categories={categories} analysisResult={analysisResult} rules={rules} callbacks={callbacks} apiClient={apiClient} />
        </MDBox>
      </TitledCard>

      <Dialog open={analyseConflict} fullWidth={true} maxWidth={false}>
        <ConflictDetails
          conflict={conflictToAnalyse}
          transactions={analysisResult?.classified_transactions}
          categories={categories}
          rules={rules}
          apiCalls={apiCalls}
          callbacks={callbacks}
          apiClient={apiClient}
          onClose={() => {
            setAnalyseConflict(false);
          }}
        />
      </Dialog>
    </div>
  );
}

export default RuleAnalysis;
