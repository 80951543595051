/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import React, { useEffect, useMemo, useState } from "react";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";

import CategorySelector from "./categoryselector";
import GraphScalingSelector from "./graphscalingselector";
import { getColorFor, colors } from "utils/color_util";

var sma = require("sma");

const FdcExpenses = require("fdc_expenses");
import { TimeseriesAggregation } from "fdc_expenses";
import MultiCategorySelector from "layouts/pages/classificationflow/multicategoryselector";
import TitledCard from "components/TitledCard/titled_card";

function ExpensesTrend({ categories, apiClient }) {
  const [selectedCategories, setSelectedCategories] = useState([]);

  const [scaling, setScaling] = useState();
  const [averaging, setAveraging] = useState();

  const [timeseriesData, setTimeseriesData] = useState();

  useEffect(() => fetchTransactionsTimeSeriesFromAPI(), [apiClient]);

  function fetchTransactionsTimeSeriesFromAPI() {
    const processResponse = function (error, data, response) {
      setTimeseriesData(data);
    };
    const dailyAggregation = new TimeseriesAggregation(1, "day");
    apiClient.getTransactionsAsTimeseries(dailyAggregation, processResponse);
  }

  function getChartData() {
    if (timeseriesData && timeseriesData.times) {
      const selectedCatIds = selectedCategories.map((cat) => cat.id);

      const chartData = {
        labels: timeseriesData.times.slice(averaging || 365).map((time) => time.toLocaleDateString()),
        datasets: timeseriesData.timeseries
          .filter((catTimeSeries) => selectedCatIds.includes(catTimeSeries.category.id))
          .map((catTimeSeries) => ({
            label: catTimeSeries.category.name,
            color: getColorFor(catTimeSeries.category.id, categories),
            data: sma(
              catTimeSeries.values.map((v) => v * (scaling || 31)),
              averaging || 365
            ),
          })),
      };
      return chartData;
    } else {
      return { labels: [], datasets: [] };
    }
  }

  const cachedChartData = useMemo(() => getChartData(), [timeseriesData, scaling, averaging, selectedCategories]);

  return (
    <div>
      <TitledCard title="Select categories to show">
        <MDBox m={3}>
          <AutocompleteCategorySelector
            doesSupportMultiple={true}
            categories={categories ? categories : []}
            selectedCategoriesChanged={(selectedCategoryNames, selectedCategories) => {
              setSelectedCategories(selectedCategories);
            }}
            selectedCategoryNames={selectedCategories.map((cat) => cat.name)}
          />
        </MDBox>
      </TitledCard>

      <MDBox pt={5}>
        <GraphScalingSelector onUpdatedAveraging={(av) => setAveraging(av)} onUpdatedScaling={(av) => setScaling(av)} />
      </MDBox>

      <MDBox pt={5}>
        <GradientLineChart icon={{ component: "show_chart" }} title="Expenses Trend" description="Evolution through time" chart={cachedChartData} />
      </MDBox>
    </div>
  );
}

export default ExpensesTrend;
