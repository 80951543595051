import {
  Autocomplete,
  Button,
  Card,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Divider,
} from "@mui/material";
import MDBox from "components/MDBox";
import { useMemo, useState } from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Popover from "@mui/material/Popover";
import SortIcon from "@mui/icons-material/Sort";
import SortPopover from "./SortPopover";
import FilterPopover from "./FilterPopover";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { Clear } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import { Stack } from "rsuite";
import RichCategories from "domain/RichCategories";

import MDTypography from "components/MDTypography";
import MultiAutocompleteCategorySelector from "../classificationflow/multiautocompletecategoryselector";

export function ExpensesPageToolBar({ sortValue, sortValueChange, reversed, reversedChange, categories, searchText, setSearchText, selectedCategories, selectedCategoriesChanged }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menu, setMenu] = useState(null);
  const [selectedLabel, setSelectedLabel] = useState("");

  const [popoverType, setPopoverType] = useState("");

  const handleClickSort = (event) => {
    setPopoverType("Sort");
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function getPopoverContent() {
    if (popoverType == "Filter") {
      return (
        <div>Not supported anymore</div>
        // <FilterPopover
        //   key="ToolbarFilterPopover"
        //   categories={categories}
        //   selectedCategories={selectedCategories}
        //   selectedCategoriesChanged={selectedCategoriesChanged}
        // />
      );
    } else if (popoverType == "Sort") {
      return <SortPopover key="ToolbarSortPopover" sortValue={sortValue} sortValueChange={sortValueChange} reversed={reversed} reversedChange={reversedChange} />;
    }
  }

  function selectNonClassifiedTransactionsOnly(event) {
    const richCats = new RichCategories(categories);
    const allCats = [];

    const exp = richCats.getCategoryByName("Expenses");
    const inc = richCats.getCategoryByName("Income");

    if (exp) {
      allCats.push(exp);
    }

    if (inc) {
      allCats.push(inc);
    }

    selectedCategoriesChanged(
      allCats,
      allCats.map((cat) => cat.name)
    );
  }

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const multiSelectCategories = useMemo(() => {
    return categories.map((cat) => cat.name);
  }, [categories]);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  function renderMenu() {
    return (
      <Menu anchorEl={menu} anchorOrigin={{ vertical: "bottom", horizontal: "left" }} transformOrigin={{ vertical: "top", horizontal: "left" }} open={Boolean(menu)} onClose={closeMenu} keepMounted>
        <MenuItem
          label="MostRecentFirst"
          selected={reversed && sortValue == "timestamp"}
          onClick={() => {
            sortValueChange("timestamp");
            reversedChange(true);
            closeMenu();
          }}
        >
          Most recent first
        </MenuItem>
        <MenuItem
          label="OldestFirst"
          selected={!reversed && sortValue == "timestamp"}
          onClick={() => {
            sortValueChange("timestamp");
            reversedChange(false);
            closeMenu();
          }}
        >
          Oldest first
        </MenuItem>
        <Divider sx={{ margin: "0.5rem 0" }} />
        <MenuItem
          label="HighestFirst"
          selected={reversed && sortValue == "amount"}
          onClick={() => {
            sortValueChange("amount");
            reversedChange(true);
            closeMenu();
          }}
        >
          Largest Amount First
        </MenuItem>
        <MenuItem
          selected={!reversed && sortValue == "amount"}
          label="LowestFirst"
          onClick={() => {
            sortValueChange("amount");
            reversedChange(false);
            closeMenu();
          }}
        >
          Smallest Amount First
        </MenuItem>
        <Divider sx={{ margin: "0.5rem 0" }} />
        <MenuItem
          selected={reversed && sortValue == "counter_part_name"}
          label="AZ"
          onClick={() => {
            sortValueChange("counter_part_name");
            reversedChange(true);
            closeMenu();
          }}
        >
          Alphabetically A-Z
        </MenuItem>
        <MenuItem
          label="ZA"
          selected={!reversed && sortValue == "counter_part_name"}
          onClick={() => {
            sortValueChange("counter_part_name");
            reversedChange(false);
            closeMenu();
          }}
        >
          Alphabetically Z-A
        </MenuItem>
      </Menu>
    );
  }

  return (
    <div>
      <MDBox m={3}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={12} md={6}>
                <Grid container justifyContent="flex-start">
                  <MDBox m={2} ml={0} className="SortTransactions">
                    <MDButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu} endIcon={<Icon>keyboard_arrow_down</Icon>}>
                      Sort
                    </MDButton>
                  </MDBox>
                  {renderMenu()}
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid container justifyContent={{ xs: "flex-start", md: "flex-end" }} className="SearchTransactions">
                  <TextField id="outlined-basic" label="Search" variant="outlined" value={searchText} onChange={(ev) => setSearchText(ev.target.value)} />
                  <IconButton onClick={() => setSearchText("")}>
                    <ClearIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <MDBox mt={3}>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={12} md={9}>
                  <Grid container justifyContent="flex-start" alignItems="center">
                    <Grid item>
                      <MDBox mr={2} mt={0.5} mb={0.5} className="ShowNonClassifiedOnly">
                        <MDButton onClick={selectNonClassifiedTransactionsOnly} startIcon={<FilterAltIcon />}>
                          Show non classified transactions only
                        </MDButton>
                      </MDBox>
                    </Grid>
                    <Grid item>
                      <MDBox mr={2} mt={0.5} mb={0.5} className="ShowAll">
                        <MDButton onClick={() => selectedCategoriesChanged([])} startIcon={<FilterAltIcon />}>
                          Show all transactions
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Grid container justifyContent={{ md: "flex-end", xs: "flex-start" }} alignItems="center">
                    <MDBox mt={0.5} mb={0.5} className="SelectCategories">
                      <MultiAutocompleteCategorySelector
                        label="Filter transactions by categories"
                        selectedCategories={selectedCategories}
                        selectedCategoriesChanged={selectedCategoriesChanged}
                        categories={categories}
                      />
                    </MDBox>
                  </Grid>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          transformOrigin={{
            horizontal: "center",
            vertical: "top",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Card>
            <MDBox m={3}>{getPopoverContent()}</MDBox>
          </Card>
        </Popover>
        {/* </Grid> */}
      </MDBox>
    </div>
  );
}
