
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDDatePicker from "components/MDDatePicker";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

import React from "react";
import MDButton from "components/MDButton";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { TextField } from "@mui/material";
import { Label } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";

import Header from "layouts/ecommerce/orders/order-details/components/Header";
import OrderInfo from "layouts/ecommerce/orders/order-details/components/OrderInfo";
import TrackOrder from "layouts/ecommerce/orders/order-details/components/TrackOrder";
import PaymentDetails from "layouts/ecommerce/orders/order-details/components/PaymentDetails";
import BillingInformation from "layouts/ecommerce/orders/order-details/components/BillingInformation";
import OrderSummary from "layouts/ecommerce/orders/order-details/components/OrderSummary";

// @mui material components
import Divider from "@mui/material/Divider";
import DatePicker from "react-flatpickr";


class GraphScalingSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {enabledState: {}};

  }

  getValueFor(categoryName) {
      if(categoryName in this.state.enabledState){
          return this.state.enabledState[categoryName]
      } else {
        return false
      }
  }

  onSelectionChange(event, categoryName) {
    const newEnabledState = this.state.enabledState
    newEnabledState[categoryName] = !newEnabledState[categoryName]
    this.setState({enabledState: newEnabledState})

    if(this.props.onSelectionChanged){
        const selectedCategoryNames = []
        this.props.onSelectionChanged(this.props.categories.filter(cat => newEnabledState[cat.name]))
    }

  }
  
  handleChangedAveraging(ev){
    this.setState({averaging: ev.target.value})
    if(this.props.onUpdatedAveraging){
        this.props.onUpdatedAveraging(ev.target.value)
    }
  }

    
  handleChangedScaling(ev){
    this.setState({scaling: ev.target.value})
    if(this.props.onUpdatedScaling){
        this.props.onUpdatedScaling(ev.target.value)
    }
 }

  render() {
    return (
        <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={1}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              justifyContent="center"
              coloredShadow="info">
              <MDTypography variant="h6" color="white">
              Scale Options
              </MDTypography>

          </MDBox>
            <Grid container>            
                <Grid item ml={3}>
    
                <MDTypography variant="button" fontWeight="regular" color="text">
                    Show
                </MDTypography>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.scaling || 31}
                    label="Averaging"
                    onChange={(ev) => this.handleChangedScaling(ev)}>
                    <MenuItem value={7}>Weekly</MenuItem>
                    <MenuItem value={31}>Monthly</MenuItem>
                    <MenuItem value={91}>Quarterly</MenuItem>
                    <MenuItem value={365}>Yearly</MenuItem>
                </Select>
                <MDTypography variant="button" fontWeight="regular" color="text">
                    costs and smooth over a
                </MDTypography>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.averaging || 365}
                    label="Scaling"
                    onChange={(ev) => this.handleChangedAveraging(ev)}>
                    <MenuItem value={7}>Weekly</MenuItem>
                    <MenuItem value={31}>Monthly</MenuItem>
                    <MenuItem value={91}>Quarterly</MenuItem>
                    <MenuItem value={365}>Yearly</MenuItem>
                </Select>
                <MDTypography variant="button" fontWeight="regular" color="text">
                    period
                </MDTypography>
                </Grid>
    
            </Grid>
        </Card>
    );
    }
}

export default GraphScalingSelector;
