import ReactJoyride, { ACTIONS, EVENTS, LIFECYCLE, STATUS } from "react-joyride";

export function stopJoyRideWhenFinished(data, callback) {
  const { status, type } = data;
  const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
  if (finishedStatuses.includes(status)) {
    callback();
  }
}

export function demoCallbacks(data, onFinished, onSkipped, onStop) {
  const { status, type } = data;
  const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

  if (status == STATUS.RUNNING) {
    if (type == EVENTS.STEP_BEFORE) {
      if (data.step.beforeAction != undefined) {
        data.step.beforeAction();
      }
    }
    if (type == EVENTS.STEP_AFTER) {
      if (data.step.afterAction != undefined) {
        data.step.afterAction();
      }
    }
  }

  if (status == STATUS.FINISHED) {
    if (onFinished != undefined) {
      onFinished();
    }
    if (onStop != undefined) {
      onStop();
    }
  }
  if (status == STATUS.SKIPPED) {
    if (onSkipped != undefined) {
      onSkipped();
    }
    if (onStop != undefined) {
      onStop();
    }
  }
}
