/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import React, { useEffect, useMemo, useState } from "react";

// @mui material components

import { Autocomplete, CardActionArea, IconButton, MenuItem, Select, TextField } from "@mui/material";

const FdcExpenses = require("fdc_expenses");
import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import { CloseableCard } from "components/TitledCard/GenericCard";
import select from "assets/theme/components/form/select";
import ReactJoyride from "react-joyride";
import { stopJoyRideWhenFinished, demoCallbacks } from "utils/JoyRideUtils";
import FDDemo from "utils/FDDemo";

const countries = [
  { name: "Belgium", code: "BE" },
  { name: "France", code: "FR" },
  { name: "Germany", code: "DE" },
  { name: "Denmark", code: "DK" },
  { name: "Sweden", code: "SE" },
  { name: "Netherlands", code: "NL" },
];

const steps = [
  {
    target: ".SelectCountry",
    content: <MDTypography variant="button">Select the country of the bank you want to sync your transactions with</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".SearchBank",
    content: <MDTypography variant="button">Use the search bar to find your bank</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".Bank_0",
    content: (
      <MDTypography variant="button">
        Click on the tile for the relevant bank. You will be redirected to your bank page, where you will be asked whether Budgex.eu can access your transactions. The permission that you give, is only
        valid for 90 days, can be revoked at any point in time and only provides access to the history of your transactions
      </MDTypography>
    ),
    disableBeacon: true,
  },
];

const mostPopularBanks = {
  BE: ["BELFIUS_GKCCBEBB", "KBC_KREDBEBB", "ING_BBRUBEBB", "BNP_BE_GEBABEBB", "AXA_BANK_AXABBE22", "ARGENTA_ARSPBE22"],
  FR: ["BNP_PARIBAS_BNPAFRPPXXX", "AXA_AXABFRPPXXX", "ING_INGBFR21", "KBC KBC_KREDFRPP"],
  DE: [
    "DEUTSCHE_BANK_DEUTDEFF",
    "DZ_BANK_AG_DEUTSCHE_ZENTRAL_GENOSSENSCHAFTSBANK_GENODEFF",
    "COMMERZBANK_COBADEFF",
    "UNICREDIT_HYVEDEMM",
    "UNICREDIT_EBANKING_HYVEDEMM",
    "LANDESBANK_SAAR_SALADE55XXX",
    "BAYERISCHE_BODENSEEBANK_GENODEF1LBB",
  ],
  NL: ["ABNAMRO_ABNANL2A", "RABOBANK_RABONL2U", "ING_INGBNL2A", "SNS_BANK_SNSBNL2A"],
};

function BankLinkCreatorV2({ apiClient, apiCalls, onClose, externalDemo }) {
  const [banks, setBanks] = useState([]);
  const [country, setCountry] = useState(countries[0]);
  const [bankSearch, setBankSearch] = useState("");
  const [showDemo, setShowDemo] = useState(externalDemo || false);
  const [initialBankDownloadSuccess, setInitialBankDownloadSuccess] = useState(false);

  const [offset, setOffset] = useState(0);

  function updateBanks(country) {
    const storeBanks = function (error, data, response) {
      if (!error) {
        setInitialBankDownloadSuccess(true);
        setBanks(data);
      }
    };

    if (country != undefined) {
      apiClient.getAllSupportedBanksForCountry(country.code, storeBanks);
    }
  }

  useEffect(() => updateBanks(country), [country]);

  function changeCountry(ev) {
    setOffset(0);
    setCountry(ev.target.value);
    updateBanks(ev.target.value);
  }

  function getBanksSortedForCountry() {
    var preferredBankCodeList = [];
    const allBankCodes = banks.map((bank) => bank.code);
    if (country.code in mostPopularBanks) {
      preferredBankCodeList = mostPopularBanks[country.code].filter((code) => allBankCodes.includes(code));
    }

    const preferredBanks = preferredBankCodeList.map((preferredBankCode) => banks.filter((bank) => bank.code == preferredBankCode)[0]);
    const otherBanks = banks.filter((bank) => !preferredBankCodeList.includes(bank.code));

    return preferredBanks.concat(otherBanks);
  }

  const banksThatMatchFiltering = useMemo(() => {
    return getBanksSortedForCountry().filter((bank) => bankSearch.length < 1 || bank.name.toLowerCase().includes(bankSearch.toLowerCase()));
  }, [banks, bankSearch]);

  const resultsPerPage = 30;
  const total = banksThatMatchFiltering.length;
  const offsetStart = Math.min(offset, total);
  const offsetEnd = Math.min(offset + resultsPerPage, total);

  function onNext() {
    setOffset(Math.min(offset + resultsPerPage, total));
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }

  function onPrevious() {
    setOffset(Math.max(0, offset - resultsPerPage));
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }

  const renderedBanks = useMemo(() => {
    return banksThatMatchFiltering.slice(offset, offset + resultsPerPage).map((bank, index) => {
      return (
        <Grid item xl={4} xs={6} key={"Bank_" + bank.code}>
          <div class={"Bank_" + index}>
            <MDBox m={1}>
              <Card>
                <CardActionArea
                  onClick={(ev) => {
                    apiCalls.linkBankWithCode(bank.code);
                    onClose();
                  }}
                >
                  <MDBox m={1}>
                    <Grid container alignContent={"center"}>
                      <Grid item xs={6}>
                        <MDAvatar src={bank.logo_link}></MDAvatar>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid container alignContent={"center"}>
                          <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                            {bank.name}
                          </MDTypography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </MDBox>
                </CardActionArea>
              </Card>
            </MDBox>
          </div>
        </Grid>
      );
    });
  }, [banksThatMatchFiltering, offset]);

  return (
    <div>
      <CloseableCard onClose={onClose} onHelp={() => setShowDemo(true)}>
        <MDBox m={1}>
          <Grid container>
            <Grid item xs={12}>
              <MDBox lineHeight={1} ml={2} mb={3}>
                <MDTypography variant="h5" fontWeight="medium">
                  Link new bank account
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox mb={3} ml={5} mr={5}>
                <div class={"SelectCountry"}>
                  <Autocomplete
                    options={countries}
                    renderOption={(props, option) => (
                      <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                        <img loading="lazy" width="20" src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`} srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`} alt="" />
                        {option.name} ({option.code})
                      </Box>
                    )}
                    getOptionLabel={(option) => option.name}
                    value={country}
                    onChange={(event, selectedOption) => {
                      setCountry(selectedOption);
                      setBanks([]);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose a country"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </div>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox mb={3} ml={5} mr={5}>
                <div class={"SearchBank"}>
                  <TextField
                    label="Search Bank"
                    value={bankSearch}
                    onChange={(ev) => {
                      setBankSearch(ev.target.value);
                      setOffset(0);
                    }}
                    fullWidth
                  />
                </div>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox lineHeight={1} mt={2} ml={2} mr={2} mb={2}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container justifyContent="flex-end">
                      <Grid item>
                        <MDTypography variant="button" fontWeight="light" textTransform="capitalize">
                          Showing {offsetStart + 1 || 0} - {offsetEnd || 0} of {total || 0} banks
                        </MDTypography>
                      </Grid>
                      <Grid item>
                        <IconButton onClick={onPrevious}>
                          <NavigateBeforeIcon />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <IconButton onClick={onNext}>
                          <NavigateNextIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  {renderedBanks}
                </Grid>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </CloseableCard>

      <FDDemo
        run={showDemo && initialBankDownloadSuccess}
        steps={steps}
        onStop={() => {
          // No matter the reason for stopping, we stop the demo
          // If this was triggered due to an external demo, we close it as well
          setShowDemo(false);
          if (externalDemo) {
            onClose();
          }
        }}
      />
    </div>
  );
}

export default BankLinkCreatorV2;
