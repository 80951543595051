import { Grid } from "@mui/material";
import { Purchase } from "./Purchase";

export function Purchases({ applicationData }) {
  const renderedSubscriptions = applicationData.purchases.map((p) => (
    <Grid item xs={12} key={"Purchase_" + p.id}>
      <Purchase purchase={p} />
    </Grid>
  ));
  return (
    <Grid container justifyContent="space-around">
      {renderedSubscriptions}
    </Grid>
  );
}
