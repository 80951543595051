/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import MDInput from "components/MDInput";
import Switch from "@mui/material/Switch";

// NewProduct page components
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";
import { AppBar, Divider, MenuItem, Select, Tabs, Tab, Icon, Dialog, FormControlLabel } from "@mui/material";
import MDButton from "components/MDButton";
import { TabPanelUnstyled } from "@mui/base";
import SingleAutocompleteCategorySelector from "layouts/pages/classificationflow/singleautocompletecategoryselector";
import { IconButton } from "rsuite";
import { CloseableCard } from "components/TitledCard/GenericCard";
import CreateNewCategoryCard from "layouts/categorymanagement/CreateNewCategoryCard";

const FdcExpenses = require("fdc_expenses");

function RuleCreatorV3(props) {
  const generateMenuItems = function () {
    return props.categories.map((cat) => {
      return (
        <MenuItem value={cat.id} key={"LabelForRuleCreateor_" + cat.name}>
          {cat.name}
        </MenuItem>
      );
    });
  };

  const [tabValue, setTabValue] = useState(0);

  const [filterValue, setFilterValue] = useState("Search Text");
  const [category, setCategory] = useState(props.defaultCategory);

  const [counterPartName, setCounterPartName] = useState(true);
  const [description, setDescription] = useState(true);
  const [comments, setComments] = useState(true);

  const [caseSensitive, setCaseSensitive] = useState(false);
  const [exactMatch, setExactMatch] = useState(false);

  const [onlyExpenses, setOnlyExpenses] = useState(false);
  const [onlyIncome, setOnlyIncome] = useState(false);

  const [showCreateNewCategory, setShowCreateNewCategory] = useState(false);

  useEffect(() => {
    setFilterValue(props.proposedSearchText || "");
  }, [props.proposedSearchText]);

  const describeRuleInText = function () {
    var locations = "";
    var isFirst = true;
    if (counterPartName) {
      isFirst = false;
      locations += "name of the counterpart";
    }
    if (description) {
      if (!isFirst) {
        locations += " or ";
      }

      locations += "description";
      isFirst = false;
    }
    if (comments) {
      if (!isFirst) {
        locations += " or ";
      }

      locations += "comments";

      isFirst = false;
    }

    const relevantCategories = props.categories.filter((cat) => cat.id == category);

    if (relevantCategories.length > 0) {
      const categoryName = relevantCategories[0].name;
      return "This rule assigns the category '" + categoryName + "' a new rule for all transactions that contain '" + filterValue + "' in the " + locations;
    } else {
      return "";
    }
  };

  const createStringFilter = function (propertyName) {
    const stringCondition = new FdcExpenses.StringCondition();
    stringCondition.field_name = propertyName;
    stringCondition.query = filterValue;
    stringCondition.exact_match = false;

    const condition = new FdcExpenses.Condition();
    condition.string_condition = stringCondition;
    return condition;
  };

  const createNewRule = function () {
    const ruleConditions = [];

    if (counterPartName) {
      ruleConditions.push(createStringFilter("counter_part_name"));
    }
    if (description) {
      ruleConditions.push(createStringFilter("description"));
    }
    if (comments) {
      ruleConditions.push(createStringFilter("comments"));
    }

    const combinedStringCondition = new FdcExpenses.Condition();
    combinedStringCondition.or_condition = new FdcExpenses.OrCondition(ruleConditions);

    var totalCondition;

    if (onlyExpenses || onlyIncome) {
      totalCondition = new FdcExpenses.Condition();
      const ruleConditions = [combinedStringCondition];

      if (onlyExpenses) {
        const onlyExpensesCondition = new FdcExpenses.Condition();
        onlyExpensesCondition.amount_condition = new FdcExpenses.AmountCondition();
        onlyExpensesCondition.amount_condition.upper_boundary = 0;
        ruleConditions.push(onlyExpensesCondition);
      }

      if (onlyIncome) {
        const onlyExpensesCondition = new FdcExpenses.Condition();
        onlyExpensesCondition.amount_condition = new FdcExpenses.AmountCondition();
        onlyExpensesCondition.amount_condition.lower_boundary = 0;
        ruleConditions.push(onlyExpensesCondition);
      }

      totalCondition.and_condition = new FdcExpenses.AndCondition(ruleConditions);
    } else {
      totalCondition = combinedStringCondition;
    }

    const newRule = new FdcExpenses.Rule(category.id, totalCondition);

    if (props.onNewRule) {
      props.onNewRule(newRule);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} pl={3}>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MDTypography component="label" variant="button">
                {describeRuleInText()}
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
      </Grid>

      <Grid item xs={12} p={3}>
        <Grid container spacing={1} display="flex" alignItems="stretch" justifyContent="flex-start">
          <Grid item xs={3}>
            <MDTypography component="label" variant="button" fontWeight="regular" color="text" textTransform="capitalize">
              Category:
            </MDTypography>
          </Grid>
          <Grid item xs={3}>
            <SingleAutocompleteCategorySelector
              categories={props.categories}
              selectedCategory={category || null}
              selectedCategoryChanged={(selectedCategory, selectedCategoryLabel) => {
                if (selectedCategory) {
                  setCategory(selectedCategory);
                } else {
                  setCategory(null);
                }
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <MDButton onClick={(ev) => setShowCreateNewCategory(true)} startIcon={<Icon>add</Icon>}>
              Create new category
            </MDButton>
          </Grid>
        </Grid>
        <Dialog open={showCreateNewCategory}>
          <CloseableCard onClose={(ev) => setShowCreateNewCategory(false)}>
            <CreateNewCategoryCard categories={props.categories} onClose={(ev) => setShowCreateNewCategory(false)} apiCalls={props.apiCalls} />
          </CloseableCard>
        </Dialog>
      </Grid>

      <Grid item xs={12} p={3}>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormField type="text" label="Filter text" value={filterValue} onChange={(ev) => setFilterValue(ev.target.value)} />
            </Grid>
          </Grid>
        </MDBox>
      </Grid>

      <Grid item xs={12} p={3}>
        <Grid container spacing={3} display="flex" alignItems="center" justifyContent="space-evenly">
          <Grid item xs={12} sm={3}>
            <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
              <MDBox mt={0.5}>
                <FormControlLabel label="Search in counterpart name" control={<Switch checked={counterPartName} onChange={() => setCounterPartName(!counterPartName)} />} />
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
              <MDBox mt={0.5}>
                <Switch checked={description} onChange={() => setDescription(!description)} />
              </MDBox>
              <MDBox width="80%" ml={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  Search in description
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
              <MDBox mt={0.5}>
                <Switch checked={comments} onChange={() => setComments(!comments)} />
              </MDBox>
              <MDBox width="80%" ml={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  Search in comments
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} p={3}>
        <Grid container spacing={3} display="flex" alignItems="center" justifyContent="space-evenly">
          <Grid item xs={12} sm={3}>
            <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
              <MDBox mt={0.5}>
                <Switch checked={caseSensitive} onChange={() => setCaseSensitive(!caseSensitive)} />
              </MDBox>
              <MDBox width="80%" ml={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  Case sensitive
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
              <MDBox mt={0.5}>
                <Switch checked={exactMatch} onChange={() => setExactMatch(!exactMatch)} />
              </MDBox>
              <MDBox width="80%" ml={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  Exact match
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
              <MDBox mt={0.5}>
                <Switch
                  checked={onlyIncome}
                  onChange={() => {
                    setOnlyIncome(!onlyIncome);
                    if (!onlyIncome) {
                      setOnlyExpenses(false);
                    }
                  }}
                />
              </MDBox>
              <MDBox width="80%" ml={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  Only income
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={3}>
            <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
              <MDBox mt={0.5}>
                <Switch
                  checked={onlyExpenses}
                  onChange={() => {
                    setOnlyExpenses(!onlyExpenses);
                    if (!onlyExpenses) {
                      setOnlyIncome(false);
                    }
                  }}
                />
              </MDBox>
              <MDBox width="80%" ml={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  Only expenses
                </MDTypography>
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} display="flex" alignItems="stretch" justifyContent="space-evenly">
          <Grid item xs={3}>
            <MDBox>
              <MDButton color="success" disabled={!counterPartName && !description && !comments} onClick={createNewRule}>
                Create new rule
              </MDButton>
            </MDBox>
          </Grid>
          <Grid item xs={3}>
            <MDBox>
              <MDButton color="error" onClick={props.onCancel}>
                Cancel
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default RuleCreatorV3;
