import GraphBuilder from "./GraphBuilder";

function GraphBuilderPage({ categories, dailyTimeseriesData, applicationData, apiCalls, analysisResult, callbacks, apiClient }) {
  return (
    <GraphBuilder
      categories={categories}
      dailyTimeseriesData={dailyTimeseriesData}
      graphDefinitions={applicationData.graphDefinitions}
      apiCalls={apiCalls}
      analysisResult={analysisResult}
      callbacks={callbacks}
      apiClient={apiClient}
    />
  );
}

export default GraphBuilderPage;
