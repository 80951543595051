import { GraphItemsForCategories } from "domain/graphconfiguration/GraphItemsDefinition";
import { MonthlyTimeRanges } from "domain/graphconfiguration/TimeRangesDefinition";
import { WeeklyTimeRanges } from "domain/graphconfiguration/TimeRangesDefinition";
import { YearlyTimeRanges } from "domain/graphconfiguration/TimeRangesDefinition";
import { AllTimeTimeRange } from "domain/graphconfiguration/TimeRangesDefinition";
import { BarTrendGraphDefinition, TrendGraphDefinition, BarGraphDefinition, PieGraphDefinition } from "domain/graphconfiguration/GraphTypeDefinition";
import { DistributionOf } from "domain/graphconfiguration/GraphItemsDefinition";

import { parseGraphItemDefinition } from "domain/graphconfiguration/GraphItemsDefinition";
import { parseGraphTypeDefinition } from "domain/graphconfiguration/GraphTypeDefinition";
import { parseTimeRangesDefinition } from "domain/graphconfiguration/TimeRangesDefinition";
import { serializeGraphTypeDefinition } from "domain/graphconfiguration/GraphTypeDefinition";
import { serializeGraphItemDefinition } from "domain/graphconfiguration/GraphItemsDefinition";
import { serializeTimeRangesDefinition } from "domain/graphconfiguration/TimeRangesDefinition";

import { generateGraph } from "domain/graphs/GraphGenerator";
import { useEffect, useMemo, useState } from "react";
import RichCategories from "domain/RichCategories";
import { Card, Chip, Dialog, DialogContent, Grid, Icon, IconButton, Stack, Tooltip } from "@mui/material";

import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import ParsedGraphDefinition from "domain/graphconfiguration/ParsedGraphDefinition";
import { Divider } from "rsuite";
import { GraphItemForSingleCategory } from "domain/graphconfiguration/GraphItemsDefinition";

import { SingleTimeRangeWrapper } from "domain/graphconfiguration/TimeRangesDefinition";
import { Close, Fullscreen } from "@mui/icons-material";
import { CloseableCard } from "components/TitledCard/GenericCard";
import GenericCard from "components/TitledCard/GenericCard";

function PersonalGraph({ graphDefinition, applicationData, onShowTransactions, onEditCallback, apiCalls }) {
  const richCats = new RichCategories(applicationData.categories);
  const [graphDefinitionHistory, setGraphDefinitionHistory] = useState([graphDefinition]);
  const [showInteractionOptions, setShowInteractionoptions] = useState(false);

  const [selectedGraphItem, setSelectedGraphItem] = useState();
  const [selectedTimeRange, setSelectedTimeRange] = useState();

  const [showFullscreen, setShowFullScreen] = useState(false);

  const mostRecentGraphDefinition = graphDefinitionHistory[graphDefinitionHistory.length - 1];

  const showingOriginal = graphDefinitionHistory.length == 1;
  const canGoBack = graphDefinitionHistory.length > 1;

  function resetGraphHistory() {
    setGraphDefinitionHistory([graphDefinition]);
  }

  function resetInteractionOptions() {
    setSelectedGraphItem(undefined);
    setSelectedTimeRange(undefined);
    setShowInteractionoptions(false);
  }

  function goBack() {
    if (graphDefinitionHistory.length > 1) {
      const newGraphs = graphDefinitionHistory.slice(0, -1);
      setGraphDefinitionHistory(newGraphs);
      resetInteractionOptions();
    }
  }

  function pushNewGraph(newGraphDefinition) {
    const newGraphs = graphDefinitionHistory.concat([newGraphDefinition]);
    setGraphDefinitionHistory(newGraphs);

    resetInteractionOptions();
  }

  function showCompositionOfFullPeriodAsPieChart() {
    const newGraphItems = new DistributionOf(richCats.getCategoryById(selectedGraphItem.categoryIds[0]));
    const newGraphDefinition = new ParsedGraphDefinition("", new PieGraphDefinition(), newGraphItems, mostRecentGraphDefinition.timeRangesDefinition);

    pushNewGraph(newGraphDefinition);
  }

  function showCompositionOfSelectedPeriodAsPieChart() {
    const newTimeRange = new SingleTimeRangeWrapper(selectedTimeRange);
    const newGraphItems = new DistributionOf(richCats.getCategoryById(selectedGraphItem.categoryIds[0]));
    const newGraphDefinition = new ParsedGraphDefinition("", new PieGraphDefinition(), newGraphItems, newTimeRange);

    pushNewGraph(newGraphDefinition);
  }

  function showCompositionOfFullPeriodAsBarChart() {
    const newGraphItems = new DistributionOf(richCats.getCategoryById(selectedGraphItem.categoryIds[0]));
    const newGraphDefinition = new ParsedGraphDefinition("", new BarGraphDefinition(), newGraphItems, mostRecentGraphDefinition.timeRangesDefinition);

    pushNewGraph(newGraphDefinition);
  }

  function showCompositionOfSelectedPeriodAsBarChart() {
    const newTimeRange = new SingleTimeRangeWrapper(selectedTimeRange);
    const newGraphItems = new DistributionOf(richCats.getCategoryById(selectedGraphItem.categoryIds[0]));
    const newGraphDefinition = new ParsedGraphDefinition("", new BarGraphDefinition(), newGraphItems, newTimeRange);

    pushNewGraph(newGraphDefinition);
  }

  function showMonthlyTrendForFullPeriod() {
    const newGraphItems = new GraphItemForSingleCategory(richCats.getCategoryById(selectedGraphItem.categoryIds[0]), selectedGraphItem.pure);
    const newGraphDefinition = new ParsedGraphDefinition("", new BarTrendGraphDefinition("Monthly"), newGraphItems, mostRecentGraphDefinition.timeRangesDefinition);

    pushNewGraph(newGraphDefinition);
  }

  function showMonthlyTrendForSelectedPeriod() {
    const newGraphItems = new GraphItemForSingleCategory(richCats.getCategoryById(selectedGraphItem.categoryIds[0]), selectedGraphItem.pure);
    const newTimeRange = new SingleTimeRangeWrapper(selectedTimeRange);
    const newGraphDefinition = new ParsedGraphDefinition("", new BarTrendGraphDefinition("Monthly"), newGraphItems, newTimeRange);

    pushNewGraph(newGraphDefinition);
  }

  function showQuarterlyTrendForFullPeriod() {
    const newGraphItems = new GraphItemForSingleCategory(richCats.getCategoryById(selectedGraphItem.categoryIds[0]), selectedGraphItem.pure);
    const newGraphDefinition = new ParsedGraphDefinition("", new BarTrendGraphDefinition("Quarterly"), newGraphItems, mostRecentGraphDefinition.timeRangesDefinition);

    pushNewGraph(newGraphDefinition);
  }

  function showQuarterlyTrendForSelectedPeriod() {
    const newTimeRange = new SingleTimeRangeWrapper(selectedTimeRange);
    const newGraphItems = new GraphItemForSingleCategory(richCats.getCategoryById(selectedGraphItem.categoryIds[0]), selectedGraphItem.pure);
    const newGraphDefinition = new ParsedGraphDefinition("", new BarTrendGraphDefinition("Quarterly"), newGraphItems, newTimeRange);

    pushNewGraph(newGraphDefinition);
  }

  function onInteraction(graphItem, timeRange) {
    setShowInteractionoptions(true);
    setSelectedGraphItem(graphItem);
    setSelectedTimeRange(timeRange);
  }
  const fullPeriodName = mostRecentGraphDefinition.timeRangesDefinition.getTimeRange().label;

  var interactionOptions = [];

  var barDistributionOptions = [];
  var pieDistributionOptions = [];
  var trendOptions = [];
  const isShowingTrendGraph = mostRecentGraphDefinition.graphType.getType() == "BarTrendGraphDefinition";

  // const color1 = "#cfd9ff";
  // const color2 = "#ccfcca";
  // const color3 = "#facacf";
  // const color4 = "#ffedcf";

  const color1 = "#FFFFFF";
  const color2 = "#FFFFFF";
  const color3 = "#FFFFFF";
  const color4 = "#FFFFFF";

  if (showInteractionOptions) {
    interactionOptions.push(
      <MDButton
        style={{ backgroundColor: color1 }}
        variant="outlined"
        color="text"
        endIcon={<Icon>receipt</Icon>}
        fullWidth
        onClick={(ev) => {
          onShowTransactions(selectedGraphItem, selectedTimeRange);
          resetInteractionOptions();
        }}
      >
        Show Transactions for {selectedGraphItem.label} for {selectedTimeRange.label}
      </MDButton>
    );

    const singleCategoryIsSelected = selectedGraphItem.categoryIds.length == 1;
    const graphItemIsPure = selectedGraphItem.pure;

    if (singleCategoryIsSelected) {
      const selectedCategory = richCats.getCategoryById(selectedGraphItem.categoryIds[0]);
      const selectedCategoryHasChildren = richCats.categoryHasChildren(selectedCategory);

      const fullPeriodInDays = mostRecentGraphDefinition.timeRangesDefinition.getTimeRange().getDurationInDays();

      const fullPeriodCanBeSplitInMonths = fullPeriodInDays > 62;
      const fullPeriodCanBeSplitInQuarters = fullPeriodInDays > 180;

      const selectedPeriodInDays = selectedTimeRange.getDurationInDays();
      const selectedPeriodCanBeSplitInMonths = selectedPeriodInDays > 62;
      const selectedPeriodCanBeSplitInQuarters = selectedPeriodInDays > 180;

      if (!graphItemIsPure && selectedCategoryHasChildren) {
        pieDistributionOptions.push(
          <MDButton variant="outlined" color="text" endIcon={<Icon>pie_chart</Icon>} onClick={(ev) => showCompositionOfFullPeriodAsPieChart()} fullWidth style={{ backgroundColor: color2 }}>
            piechart for {fullPeriodName}
          </MDButton>
        );

        barDistributionOptions.push(
          <MDButton variant="outlined" color="text" endIcon={<Icon>bar_chart</Icon>} onClick={(ev) => showCompositionOfFullPeriodAsBarChart()} fullWidth style={{ backgroundColor: color3 }}>
            composition for {fullPeriodName}
          </MDButton>
        );

        if (isShowingTrendGraph) {
          pieDistributionOptions.push(
            <MDButton variant="outlined" color="text" endIcon={<Icon>pie_chart</Icon>} onClick={(ev) => showCompositionOfSelectedPeriodAsPieChart()} fullWidth style={{ backgroundColor: color2 }}>
              piechart for {selectedTimeRange.label}
            </MDButton>
          );

          barDistributionOptions.push(
            <MDButton variant="outlined" color="text" endIcon={<Icon>bar_chart</Icon>} onClick={(ev) => showCompositionOfSelectedPeriodAsBarChart()} fullWidth style={{ backgroundColor: color3 }}>
              composition for {selectedTimeRange.label}
            </MDButton>
          );
        }
      }

      if (fullPeriodCanBeSplitInMonths && !isShowingTrendGraph) {
        trendOptions.push(
          <MDButton fullWidth variant="outlined" color="text" endIcon={<Icon>bar_chart</Icon>} onClick={(ev) => showMonthlyTrendForFullPeriod()} style={{ backgroundColor: color4 }}>
            Monthly Trend for {fullPeriodName}
          </MDButton>
        );
      }

      if (isShowingTrendGraph && selectedPeriodCanBeSplitInMonths) {
        trendOptions.push(
          <MDButton fullWidth variant="outlined" color="text" endIcon={<Icon>bar_chart</Icon>} onClick={(ev) => showMonthlyTrendForSelectedPeriod()} style={{ backgroundColor: color4 }}>
            Monthly Trend for {selectedTimeRange.label}
          </MDButton>
        );
      }

      if (fullPeriodCanBeSplitInQuarters && !isShowingTrendGraph) {
        trendOptions.push(
          <MDButton fullWidth variant="outlined" color="text" endIcon={<Icon>bar_chart</Icon>} onClick={(ev) => showQuarterlyTrendForFullPeriod()} style={{ backgroundColor: color4 }}>
            Show Quarterly Trend for {fullPeriodName}
          </MDButton>
        );
      }

      if (isShowingTrendGraph && selectedPeriodCanBeSplitInQuarters) {
        trendOptions.push(
          <MDButton fullWidth variant="outlined" color="text" endIcon={<Icon>bar_chart</Icon>} onClick={(ev) => showQuarterlyTrendForSelectedPeriod()} style={{ backgroundColor: color4 }}>
            Show Quarterly Trend for {selectedTimeRange.label}
          </MDButton>
        );
      }
    }
  }

  const renderedInteractionOptions = interactionOptions.map((option) => {
    return (
      <Grid item xs={12}>
        <MDBox m={1}>{option}</MDBox>
      </Grid>
    );
  });

  function wrapInCard(options, title) {
    if (options.length > 0) {
      const renderedOptions = options.map((option) => {
        return (
          <Grid item xs={6}>
            <MDBox m={1}>{option}</MDBox>
          </Grid>
        );
      });

      return (
        <Grid item xs={12}>
          <MDBox m={2} sx={{ border: "1px dotted grey", "border-radius": "3px" }}>
            <GenericCard topcenter={title} bgColor="#f1f2f5">
              <Grid container>{renderedOptions}</Grid>
            </GenericCard>
          </MDBox>
        </Grid>
      );
    } else {
      return "";
    }
  }

  const showTransactionsCard = (
    <MDBox m={2}>
      <Grid container>{renderedInteractionOptions}</Grid>
    </MDBox>
  );

  var pieDistributionsCard = wrapInCard(
    pieDistributionOptions,
    <Stack direction="row">
      <MDTypography variant="h6">Composition as Pie Chart</MDTypography>
      <MDBox ml={1}>
        <Icon>pie_chart</Icon>
      </MDBox>
    </Stack>
  );
  var barDistributionsCard = wrapInCard(
    barDistributionOptions,
    <Stack direction="row">
      <MDTypography variant="h6">Composition as Bar Chart</MDTypography>
      <MDBox ml={1}>
        <Icon>bar_chart</Icon>
      </MDBox>
    </Stack>
  );
  var trendCard = wrapInCard(
    trendOptions,
    <Stack direction="row">
      <MDTypography variant="h6">Trend</MDTypography>
      <MDBox ml={1}>
        <Icon>bar_chart</Icon>
      </MDBox>
    </Stack>
  );

  function renderContent(isShowingTrendGraph) {
    return (
      <Grid container>
        <Grid xs={12} item>
          <Grid container justifyContent="flex-end">
            <MDBox mt={1}>
              <IconButton onClick={(ev) => setShowFullScreen(true)}>
                <Fullscreen />
              </IconButton>
            </MDBox>
          </Grid>
        </Grid>
        {showingOriginal && (
          <Grid xs={12} item>
            <MDBox mt={-6}>
              <Grid container justifyContent="center">
                <MDBadge variant="gradient" color="secondary" size="lg" badgeContent={mostRecentGraphDefinition.title} container />
              </Grid>
            </MDBox>
          </Grid>
        )}
        {!showingOriginal && (
          <Grid xs={12} item>
            <MDBox mb={2} mt={-2}>
              <Grid container justifyContent="space-between" alignItems="center">
                <MDBox>
                  <MDButton startIcon={<Icon fontSize="small">arrow_back</Icon>} onClick={(ev) => goBack()}>
                    Back
                  </MDButton>
                </MDBox>
              </Grid>
            </MDBox>
          </Grid>
        )}
        {showingOriginal && (
          <Grid xs={12} item>
            <MDBox mb={2} mt={-1}>
              <Grid container justifyContent="center">
                <Stack direction="row">
                  <MDBox>
                    <IconButton
                      onClick={(ev) => {
                        onEditCallback(mostRecentGraphDefinition);
                      }}
                    >
                      <Icon fontSize="small">edit</Icon>
                    </IconButton>
                  </MDBox>

                  <MDBox>
                    <IconButton onClick={(ev) => apiCalls.deleteGraphDefinitionById(graphDefinition.id)}>
                      <Icon fontSize="small">delete</Icon>
                    </IconButton>
                  </MDBox>
                </Stack>
              </Grid>
            </MDBox>
          </Grid>
        )}

        {
          <Grid xs={12} item>
            <MDBox m={1} mt={-1}>
              {generateGraph(
                applicationData.categories,
                applicationData.dailyTimeseriesData,
                mostRecentGraphDefinition.graphType,
                mostRecentGraphDefinition.graphItemsDefinition,
                mostRecentGraphDefinition.timeRangesDefinition,
                onInteraction,
                applicationData?.windowRatio
              )}
            </MDBox>
          </Grid>
        }
        {!isShowingTrendGraph && (
          <Grid xs={12} item>
            <Grid container justifyContent="center">
              <MDTypography variant="button">
                {mostRecentGraphDefinition.timeRangesDefinition.getTimeRange().start.toFormat("LLL dd, yyyy")} to{" "}
                {mostRecentGraphDefinition.timeRangesDefinition.getTimeRange().end.toFormat("LLL dd, yyyy")}
              </MDTypography>
            </Grid>
          </Grid>
        )}
        <Dialog fullWidth maxWidth={"xl"} open={showInteractionOptions}>
          {showInteractionOptions && (
            <CloseableCard onClose={() => setShowInteractionoptions(false)} title={"See details for " + selectedGraphItem.label + " in " + selectedTimeRange.label}>
              <Grid xs={12} item>
                <MDBox mb={1} mt={1}>
                  <Grid container justifyContent="space-around">
                    <Grid xs={12} item>
                      <Grid container justifyContent="center" alignItems="center">
                        <Grid item>
                          <MDBox>
                            <MDTypography variant="h6"></MDTypography>
                          </MDBox>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {showTransactionsCard}
                  {pieDistributionsCard}
                  {barDistributionsCard}
                  {trendCard}
                </MDBox>
              </Grid>
            </CloseableCard>
          )}
        </Dialog>
      </Grid>
    );
  }

  if (showFullscreen) {
    return (
      <div>
        {renderContent(isShowingTrendGraph)}
        <Dialog open={true} fullWidth maxWidth="xl">
          <CloseableCard onClose={() => setShowFullScreen(false)}>{renderContent(isShowingTrendGraph)}</CloseableCard>
        </Dialog>
      </div>
    );
  } else {
    return renderContent(isShowingTrendGraph);
  }
}

export default PersonalGraph;
