import { Grid, Card, AppBar, Tabs, Tab, Divider } from "@mui/material";
import MDTypography from "components/MDTypography";
import { CloseableCard } from "components/TitledCard/GenericCard";
import { useState } from "react";
import RuleCreatorV4 from "./RuleCreatorV4";
import SingleTransactionClassifier from "./singletransactionclassifier";

function Classifier({ categories, onNewRule, proposedSearchText, transaction, onCancel, proposeSingle, apiCalls, externalDemo, onNewRuleCreated, forAccount }) {
  const [tabValue, setTabValue] = useState(proposeSingle ? 1 : 0);
  const [showDemo, setShowDemo] = useState(externalDemo);

  function relevantTabValue() {
    if (tabValue == 0) {
      // return <RuleCreatorV3 apiCalls={apiCalls} categories={categories} onNewRule={onNewRule} proposedSearchText={proposedSearchText} onCancel={onCancel} defaultCategory={null} />;
      return (
        <RuleCreatorV4
          apiCalls={apiCalls}
          categories={categories}
          onNewRuleCreated={onNewRuleCreated}
          proposedSearchText={proposedSearchText}
          onCancel={onCancel}
          defaultCategory={null}
          showDemo={showDemo}
          forAccount={forAccount}
          onDemoFinished={() => {
            // If we got triggered due to an extenral demo, close this window
            if (externalDemo) {
              onCancel();
            }
            setShowDemo(false);
          }}
        />
      );
    } else if (tabValue == 1) {
      return (
        <SingleTransactionClassifier
          apiCalls={apiCalls}
          transaction={transaction}
          categories={categories}
          onNewRuleCreated={onNewRuleCreated}
          onCancel={onCancel}
          defaultCategory={null}
          showDemo={showDemo}
          onDemoFinished={() => {
            // If we got triggered due to an extenral demo, close this window
            if (externalDemo) {
              onCancel();
            }
            setShowDemo(false);
          }}
        />
      );
    } else {
      return <div>Select a classification option</div>;
    }
  }

  function getTitle() {
    if (tabValue == 0) {
      return "Create new classification rule";
    } else {
      return "Classify Single Transaction";
    }
  }

  return (
    <Card>
      <CloseableCard onClose={onCancel} onHelp={() => setShowJoyRide(true)}>
        <Grid container m={1} display="flex" justifyContent={"center"} mt={0} mb={1}>
          <Grid item xs={6} display="flex" justifyContent={"center"}>
            <MDTypography variant="h5">{getTitle()}</MDTypography>
          </Grid>

          <Grid item xs={12} m={1} mr={2} mb={0}>
            <AppBar position="static" className="RuleOrSingle">
              <Tabs orientation="horizontal" value={tabValue} onChange={(event, newValue) => setTabValue(newValue)}>
                <Tab label="Create rule to match transactions" />
                <Tab label="Assign a single transaction" />
              </Tabs>
            </AppBar>
          </Grid>
          <Grid item xs={12} ml={1} mr={1}>
            <Divider />
          </Grid>
          <Grid item xs={12} ml={1} mr={2}>
            {relevantTabValue()}
          </Grid>
        </Grid>
      </CloseableCard>
    </Card>
  );
}

export default Classifier;
