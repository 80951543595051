import { Card, CardActionArea, Chip, Grid, Icon, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { getColorFor } from "utils/color_util";

export function TransactionCard({ transaction, categoryId, onClick, categories, index }) {
  function getColorForAmount(amount) {
    if (amount > 0) {
      return "success";
    } else {
      return "error";
    }
  }
  const categoryForTransaction = categories.filter((cat) => cat.id == categoryId);

  var chips = [];

  if (false) {
    chips.push(
      <MDBox mr={0.2}>
        <div class={"ConflictBadge_" + index}>
          <Chip label={"Conflict"} style={{ backgroundColor: "#de4940" }} />
        </div>
      </MDBox>
    );
  }

  if (transaction.manual) {
    chips.push(
      <MDBox mr={0.2}>
        <div class={"ManualBadge_" + index}>
          <Chip label={"Manual"} style={{ backgroundColor: "#dea140" }} />
        </div>
      </MDBox>
    );
  }

  chips.push(<Chip label={categoryForTransaction[0].name} style={{ backgroundColor: getColorFor(categoryId, categories), opacity: 0.7 }} />);

  const isIgnored = categoryForTransaction[0].name == "Ignore";

  return (
    <MDBox m={0.2}>
      <Card style={{ backgroundColor: isIgnored ? "#fcdbd9" : "#FFFFFF" }}>
        <CardActionArea onClick={onClick}>
          <Grid container>
            <Grid item xs={12}>
              <MDBox m={3} mt={1.5} mb={1}>
                <MDBox width="100%" display="flex" flexDirection="column" lineHeight={1}>
                  <Grid container>
                    <Grid item xs={11}>
                      <MDBox>
                        <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                          {transaction.counter_part_name || "N/A"}
                        </MDTypography>
                      </MDBox>
                      <MDBox display="flex" flexDirection="column" lineHeight={1} mt={1}>
                        <MDTypography variant="button" fontWeight="light" textTransform="capitalize">
                          {transaction.timestamp.toLocaleDateString()}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item xs={1}>
                      <MDBox>
                        <MDTypography variant="button" fontWeight="medium" textTransform="capitalize" color={getColorForAmount(transaction.amount)}>
                          <Grid container justifyContent={"flex-end"}>
                            {transaction.amount} EUR
                          </Grid>
                        </MDTypography>
                      </MDBox>
                    </Grid>

                    <Grid item xs={9}>
                      <MDBox mb={1} lineHeight={0} mt={2}>
                        <MDTypography variant="caption" fontWeight="regular" color="text">
                          {transaction.description}
                        </MDTypography>
                      </MDBox>
                      <MDBox mb={1} lineHeight={0}>
                        <MDTypography variant="caption" fontWeight="regular" color="text">
                          {transaction.comments}
                        </MDTypography>
                      </MDBox>
                      <MDBox mb={1} lineHeight={0}>
                        <MDTypography variant="caption" fontWeight="bold" color="text">
                          {transaction.account}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item xs={3} display="flex" justifyContent="flex-end" alignContent="flex-end" flexDirection="column">
                      <Grid container justifyContent="flex-end" alignContent="stretch">
                        {chips}
                      </Grid>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
    </MDBox>
  );
}
