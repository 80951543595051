import { AppBar, Card, Dialog, Grid, Icon, IconButton, Tab, Tabs } from "@mui/material";
import MDBox from "components/MDBox";
import CardWithIcon from "components/TitledCard/CardWithIcon";
import TrendGraph from "layouts/dashboards/trendv2/TrendGraph";
import { getAllChildrenOfCategoryByName } from "utils/GraphConfigurationUtils";
import { createSingleCategoryGraphItem } from "utils/GraphConfigurationUtils";
import { getAllCategoriesByName } from "utils/GraphConfigurationUtils";

import { createMultiCategoryGraphItem } from "utils/GraphConfigurationUtils";
import { generateGraphItems } from "utils/GraphConfigurationUtils";
import { createThisYearTimeRange, createAllTimeTimeRange, createLastYearTimeRange } from "utils/GraphConfigurationUtils";
import { BarGraph } from "../barpagev2/BarGraph";
import { PiechartGraph, generatePieChartConfiguration } from "../piechart/PiechartGraph";
import { useMemo, useState } from "react";
import TitledCardWithCloseButton from "layouts/categorymanagement/TitledCardWithCloseButton";

import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const tabOptions = [createAllTimeTimeRange(), createThisYearTimeRange(), createLastYearTimeRange()];

function DashboardPage({ applicationData, apiCalls }) {
  const [tabValue, setTabValue] = useState(0);
  const [dialogContent, setDialogContent] = useState();

  const location = useLocation();
  useEffect(() => {
    apiCalls.setHelpCallback(undefined); // TODO
  }, [location]);

  const expensesTrendComponent = useMemo(
    () => (
      <TrendGraph
        dailyTimeseriesData={applicationData.dailyTimeseriesData}
        configuration={{
          graphItems: getAllCategoriesByName(applicationData.categories, "Expenses").map((cat) => {
            return createSingleCategoryGraphItem(cat, "Expenses", false, true, 6);
          }),

          averagingDays: 182,
        }}
      />
    ),
    [applicationData.dailyTimeseriesData, applicationData.categories]
  );

  const incomeTrendComponent = useMemo(
    () => (
      <TrendGraph
        dailyTimeseriesData={applicationData.dailyTimeseriesData}
        configuration={{
          graphItems: getAllCategoriesByName(applicationData.categories, "Income").map((cat) => {
            return createSingleCategoryGraphItem(cat, "Income", false, false, 4);
          }),

          averagingDays: 182,
        }}
      />
    ),
    [applicationData.dailyTimeseriesData, applicationData.categories]
  );

  const expensesDistributionComponent = useMemo(
    () => (
      <PiechartGraph
        dailyTimeseriesData={applicationData.dailyTimeseriesData}
        configuration={generatePieChartConfiguration(
          getAllChildrenOfCategoryByName(applicationData.categories, "Expenses")
            .map((cat, index) => {
              return createMultiCategoryGraphItem([cat], cat.name, false, false, index);
            })
            .concat(getAllCategoriesByName(applicationData.categories, "Expenses").map((cat) => createMultiCategoryGraphItem([cat], "Non classified expenses", true, false, 11))),
          tabOptions[tabValue]
        )}
      />
    ),
    [applicationData.dailyTimeseriesData, applicationData.categories, tabValue]
  );

  const expensesClassificationProgressComponent = useMemo(
    () => (
      <PiechartGraph
        dailyTimeseriesData={applicationData.dailyTimeseriesData}
        configuration={generatePieChartConfiguration(
          [
            createMultiCategoryGraphItem(getAllChildrenOfCategoryByName(applicationData.categories, "Expenses"), "Classified expenses", false, true, 4),
            createMultiCategoryGraphItem(
              applicationData.categories.filter((cat) => cat.name == "Expenses"),
              "Non-Classified expenses",
              true,
              true,
              6
            ),
          ],
          tabOptions[tabValue]
        )}
      />
    ),
    [applicationData.dailyTimeseriesData, applicationData.categories, tabValue]
  );

  const incomeClassificationProgressComponent = useMemo(
    () => (
      <PiechartGraph
        dailyTimeseriesData={applicationData.dailyTimeseriesData}
        configuration={generatePieChartConfiguration(
          [
            createMultiCategoryGraphItem(getAllChildrenOfCategoryByName(applicationData.categories, "Income"), "Classified income", false, true, 4),
            createMultiCategoryGraphItem(
              applicationData.categories.filter((cat) => cat.name == "Income"),
              "Non-Classified income",
              true,
              true,
              6
            ),
          ],
          tabOptions[tabValue]
        )}
      />
    ),
    [applicationData.dailyTimeseriesData, applicationData.categories, tabValue]
  );

  const incomeVsExpensesComponent = useMemo(
    () => (
      <BarGraph
        title="Expenses vs Income"
        aspectRatio={2}
        dailyTimeseriesData={applicationData.dailyTimeseriesData}
        graphConfiguration={{
          graphItems: generateGraphItems(applicationData.categories, "Expenses", false, true, false, true).concat(generateGraphItems(applicationData.categories, "Income", false, true, false, false)),
          timeRanges: [tabOptions[tabValue]],
        }}
      />
    ),
    [applicationData.dailyTimeseriesData, applicationData.categories, tabValue]
  );

  const tabs = tabOptions.map((to) => <Tab key={"Tab_" + to.label} label={to.label}></Tab>);

  return (
    <Grid container justifyContent={"space-between"} alignItems={"stretch"}>
      <Grid item xs={12} xl={12}>
        <MDBox m={1}>
          <Card>
            <MDBox m={1}>
              <AppBar position="static">
                <Tabs
                  orientation="horizontal"
                  value={tabValue}
                  onChange={(ev, newValue) => {
                    setTabValue(newValue);
                  }}
                >
                  {tabs}
                </Tabs>
              </AppBar>
            </MDBox>
          </Card>
        </MDBox>
      </Grid>
      <Grid item xs={12} xl={4}>
        <Grid container>
          <Grid item xs={12}>
            <MDBox m={1}>
              <CardWithIcon
                title=" "
                topright={
                  <IconButton onClick={(ev) => setDialogContent(expensesTrendComponent)}>
                    <Icon>fullscreen</Icon>
                  </IconButton>
                }
              >
                {expensesTrendComponent || ""}
              </CardWithIcon>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <MDBox m={1}>
              <CardWithIcon
                title=" "
                topright={
                  <IconButton onClick={(ev) => setDialogContent(incomeTrendComponent)}>
                    <Icon>fullscreen</Icon>
                  </IconButton>
                }
              >
                {incomeTrendComponent || ""}
              </CardWithIcon>
            </MDBox>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} xl={4}>
        <MDBox m={1}>
          <CardWithIcon
            title=" "
            topright={
              <IconButton onClick={(ev) => setDialogContent(expensesDistributionComponent)}>
                <Icon>fullscreen</Icon>
              </IconButton>
            }
          >
            {expensesDistributionComponent || ""}
          </CardWithIcon>
        </MDBox>
      </Grid>

      <Grid item xs={12} xl={4}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <MDBox m={1}>
                  <CardWithIcon
                    title=" "
                    topright={
                      <IconButton onClick={(ev) => setDialogContent(expensesClassificationProgressComponent)}>
                        <Icon>fullscreen</Icon>
                      </IconButton>
                    }
                  >
                    {expensesClassificationProgressComponent || ""}
                  </CardWithIcon>
                </MDBox>
              </Grid>
              <Grid item xs={6}>
                <MDBox m={1}>
                  <CardWithIcon
                    title=" "
                    topright={
                      <IconButton onClick={(ev) => setDialogContent(incomeClassificationProgressComponent)}>
                        <Icon>fullscreen</Icon>
                      </IconButton>
                    }
                  >
                    {incomeClassificationProgressComponent || ""}
                  </CardWithIcon>
                </MDBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <MDBox m={1}>
              <CardWithIcon
                title=" "
                topright={
                  <IconButton onClick={(ev) => setDialogContent(incomeVsExpensesComponent)}>
                    <Icon>fullscreen</Icon>
                  </IconButton>
                }
              >
                {incomeVsExpensesComponent || ""}
              </CardWithIcon>
            </MDBox>
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={typeof dialogContent !== "undefined"} fullWidth={true} maxWidth={"xl"}>
        <TitledCardWithCloseButton onClose={() => setDialogContent(undefined)}>
          <MDBox m={3}>{dialogContent || ""}</MDBox>
        </TitledCardWithCloseButton>
      </Dialog>
    </Grid>
  );
}

export default DashboardPage;
