import { Card, CardActionArea, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import GenericCard from "components/TitledCard/GenericCard";

export function OptionForSelector({ title, isSelected, onSelect, children }) {
  function getIconIfNeeded() {
    if (isSelected) {
      return <Icon sx={{ color: "green" }}>check_circle</Icon>;
    } else {
      return "";
    }
  }

  const bgColor = isSelected ? "#F7FBF7" : "#EEEDED";

  return (
    <Card style={{ backgroundColor: bgColor }}>
      <CardActionArea onClick={onSelect}>
        <MDBox m={1}>
          <GenericCard topleft={<MDTypography>{title}</MDTypography>} topright={getIconIfNeeded()} bgColor={bgColor}>
            {children}
          </GenericCard>
        </MDBox>
      </CardActionArea>
    </Card>
  );
}
