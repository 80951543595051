import MDBox from "components/MDBox";

import { Panel, Tree } from "rsuite";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Icon, IconButton, Stack } from "@mui/material";
import { useMemo } from "react";

// You can import the default tree with dnd context

function CategoryTreeV4({ categories, apiCalls, onCreateNewCategoryFor, onStartModifyingCategory, showRoot }) {
  function findRootCategories() {
    return categories.filter((cat) => cat.parent_id == null);
  }

  function getAllChildren(category) {
    return categories.filter((cat) => cat.parent_id == category.id);
  }

  function getCategoryById(catId) {
    return categories.filter((cat) => cat.id == catId)[0];
  }

  function recursiveCreateDataForCategory(category) {
    const children = getAllChildren(category);
    if (children.length > 0) {
      return {
        value: category.id,
        label: category.name,
        category: category,
        children: children.map((child) => recursiveCreateDataForCategory(child)),
      };
    } else {
      return {
        value: category.id,
        label: category.name,
        category: category,
      };
    }
  }

  function calculateData() {
    if (showRoot) {
      return [
        {
          value: -1,
          label: "Root",
          category: undefined,
          children: findRootCategories().map((cat) => recursiveCreateDataForCategory(cat)),
        },
      ];
    } else {
      return findRootCategories().map((cat) => recursiveCreateDataForCategory(cat));
    }
  }

  const data = useMemo(() => calculateData(), [categories]);

  function renderNode(inp) {
    var conditionalDelete = "";
    if (!inp.category?.frozen) {
      conditionalDelete = (
        <MDBox>
          <IconButton
            size="small"
            onClick={(ev) => {
              apiCalls.deleteCategory(inp.category);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </MDBox>
      );
    }

    return (
      <Grid container justifyContent={"space-between"} alignItems={"center"}>
        <Grid item>{inp.label}</Grid>
        <Grid item>
          <Stack direction="row">
            <MDBox ml={3}>
              <div class={inp.category.name + "_Add"}>
                <IconButton size="small" onClick={(ev) => onCreateNewCategoryFor(inp.category)}>
                  <AddIcon />
                </IconButton>
              </div>
            </MDBox>
            {!inp.category?.frozen && (
              <MDBox>
                <div class={inp.category.name + "_Edit"}>
                  <IconButton size="small" onClick={(ev) => onStartModifyingCategory(inp.category)}>
                    <Icon>edit</Icon>
                  </IconButton>
                </div>
              </MDBox>
            )}
            {!inp.category?.frozen && (
              <MDBox>
                <IconButton
                  size="small"
                  onClick={(ev) => {
                    apiCalls.deleteCategory(inp.category);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </MDBox>
            )}
          </Stack>
        </Grid>
      </Grid>
    );
  }

  return (
    <MDBox>
      <Tree data={data} defaultExpandAll={true} virtualized={false} listProps={{ height: 1200, rowCount: categories.length }} showIndentLine={true} renderTreeNode={renderNode} />
    </MDBox>
  );
}

export default CategoryTreeV4;
