import { colors } from "utils/color_util";
import { modulo } from "utils/math.js";

function getAllCategoriesByName(categories, categoryName) {
  return categories.filter((exp) => exp.name == categoryName);
}

function getCategoryByName(categories, categoryName) {
  const relevantCategories = categories.filter((exp) => exp.name == categoryName);
  if (relevantCategories.length == 1) {
    return relevantCategories[0];
  } else {
    return undefined;
  }
}

function getAllChildrenOf(categories, categoryId) {
  return categories.filter((cat) => cat.parent_id == categoryId);
}

function getAllChildrenOfCategoryByName(categories, categoryName) {
  const catByName = getCategoryByName(categories, categoryName);
  if (catByName) {
    return getAllChildrenOf(categories, catByName.id);
  } else {
    return [];
  }
}

function createAllTimeTimeRange() {
  const today = new Date();
  return {
    label: "All time",
    start: new Date(today.getFullYear() - 100, 0, 1),
    end: new Date(today.getFullYear() + 1, 0, 1),
  };
}

function createThisYearTimeRange() {
  const today = new Date();
  return {
    label: "This year",
    start: new Date(today.getFullYear(), 0, 1),
    end: new Date(today.getFullYear() + 1, 0, 1),
  };
}

function createLastYearTimeRange() {
  const today = new Date();
  return {
    label: "Last year",
    start: new Date(today.getFullYear() - 1, 0, 1),
    end: new Date(today.getFullYear(), 0, 1),
  };
}

function createSingleCategoryGraphItem(category, label, pure, invert, index) {
  return {
    label: label,
    categoryIds: [category.id],
    pure: pure,
    color: colors[modulo(index, colors.length)],
    invert: invert,
  };
}

function createMultiCategoryGraphItem(categories, label, pure, invert, index) {
  return {
    label: label,
    categoryIds: categories.map((cat) => cat.id),

    pure: pure,
    color: colors[modulo(index, colors.length)],
    invert: invert,
  };
}

function generateGraphItems(
  categories,
  categoryName,
  includeAllChildren,
  includeTotal,
  includeOthers,
  invert
) {
  const relevantCategories = categories.filter((exp) => exp.name == categoryName);

  if (relevantCategories.length > 0) {
    const expId = relevantCategories[0].id;
    var graphItems = [];

    if (includeAllChildren) {
      graphItems = getAllChildrenOf(categories, expId).map((category, index) => {
        return createSingleCategoryGraphItem(category, category.name, false, invert, index);
      });
    }
    if (includeTotal) {
      graphItems.push({
        label: "Total " + categoryName,
        categoryIds: [expId],
        pure: false,
        color: "#993344",
        invert: invert,
      });
    }

    if (includeOthers) {
      graphItems.push({
        label: "Non specified " + categoryName,
        categoryIds: [expId],
        pure: true,
        color: "#0064a9",
        invert: invert,
      });
    }

    return graphItems;
  } else {
    return [];
  }
}

export {
  generateGraphItems,
  createThisYearTimeRange,
  createLastYearTimeRange,
  createMultiCategoryGraphItem,
  createSingleCategoryGraphItem,
  getAllChildrenOfCategoryByName,
  getCategoryByName,
  getAllCategoriesByName,
  createAllTimeTimeRange,
};
