/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import React from "react";

function TitledCard({ title, children }) {
  return (
    <MDBox pt={6} pb={3} id="MDBoxForContext">
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={1}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              justifyContent="center"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                {title}
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>{children}</MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default TitledCard;
