import { Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { MonthlyTimeRanges } from "domain/graphconfiguration/TimeRangesDefinition";
import { WeeklyTimeRanges } from "domain/graphconfiguration/TimeRangesDefinition";
import { YearlyTimeRanges } from "domain/graphconfiguration/TimeRangesDefinition";
import { AllTimeTimeRange } from "domain/graphconfiguration/TimeRangesDefinition";
import { useState } from "react";
import Selector from "./Selector";
import SelectorV2 from "./SelectorV2";

export function TimeRangesSelectorV2({ categories, multi, selectionState, setSelectionState, selectedKey, onSelectedOptionKeyChange }) {
  const options = [
    {
      title: "Last Years",
      title_function: (state) => "Last " + state.numberOfYears + " years",
      key: "YearlyTimeRanges",
      getDefaultState: function () {
        return new YearlyTimeRanges(2, multi);
      },
      stateIsApplicable: (state) => state?.constructor?.name == "YearlyTimeRanges" && state.split == multi,
      generateContentForState: (state, changedStateCallback) => {
        return (
          <MDBox mt={1} mb={1}>
            <MDInput
              variant="standard"
              fullWidth
              type="number"
              label="Number of years"
              value={state.numberOfYears}
              onChange={(ev) => {
                changedStateCallback(new YearlyTimeRanges(ev.target.value, multi));
              }}
            />
          </MDBox>
        );
      },
    },
    {
      title: "Last Months",
      title_function: (state) => "Last " + state.numberOfMonths + " months",
      key: "MonthlyTimeRanges",
      getDefaultState: function () {
        return new MonthlyTimeRanges(6, multi);
      },
      stateIsApplicable: (state) => state?.constructor?.name == "MonthlyTimeRanges" && state.split == multi,
      generateContentForState: (state, changedStateCallback) => {
        return (
          <MDBox mt={1} mb={1}>
            <MDInput
              variant="standard"
              fullWidth
              type="number"
              label="Number of months"
              value={state.numberOfMonths}
              onChange={(ev) => {
                changedStateCallback(new MonthlyTimeRanges(ev.target.value, multi));
              }}
            />
          </MDBox>
        );
      },
    },
    {
      title: "Last Weeks",
      title_function: (state) => "Last " + state.numberOfWeeks + " weeks",
      key: "WeeklyTimeRanges",
      getDefaultState: function () {
        return new WeeklyTimeRanges(4, multi);
      },
      stateIsApplicable: (state) => state?.constructor?.name == "WeeklyTimeRanges" && state.split == multi,
      generateContentForState: (state, changedStateCallback) => {
        return (
          <MDBox mt={1} mb={1}>
            <MDInput
              variant="standard"
              fullWidth
              type="number"
              label="Number of weeks"
              value={state.numberOfWeeks}
              onChange={(ev) => {
                changedStateCallback(new WeeklyTimeRanges(ev.target.value, multi));
              }}
            />
          </MDBox>
        );
      },
    },
  ];

  if (!multi) {
    options.push({
      title: "All time",
      key: "AllTimeTimeRange",
      getDefaultState: function () {
        return new AllTimeTimeRange();
      },
      stateIsApplicable: (state) => state?.constructor?.name == "AllTimeTimeRange",
      generateContentForState: (state, changedStateCallback) => {
        return (
          <MDBox mt={1} mb={1}>
            <MDTypography variant="caption">Show all data</MDTypography>
          </MDBox>
        );
      },
    });
  }

  return (
    <Grid container>
      <Grid item xs={12} justifyContent={"center"}>
        <Grid container justifyContent={"center"}>
          <MDTypography>Choose categories to visualize</MDTypography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <SelectorV2
          options={options}
          selectionState={selectionState}
          onSelectionStateChange={setSelectionState}
          selectedOptionKey={selectedKey}
          onSelectedOptionKeyChange={onSelectedOptionKeyChange}
        />
      </Grid>
    </Grid>
  );
}
