import { Card, Grid, Icon, Dialog } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import DetailsCard from "../ruleanalysis/detailscard";
import GenericCard from "components/TitledCard/GenericCard";
import { CloseableCard } from "components/TitledCard/GenericCard";
import { useState } from "react";

function AccountDetailsPage({ applicationData, apiCalls }) {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  return (
    <MDBox m={1}>
      <Card>
        <MDBox m={1} mb={3}>
          <GenericCard
            topcenter={
              <MDBox ml={1}>
                <MDTypography variant="h6">Account Details</MDTypography>
              </MDBox>
            }
          >
            <DetailsCard
              keyValuePairs={{
                "E-Mail": applicationData.userDetails?.mail,
                "2FA Enabled": applicationData.twoFaEnabled ? "Yes" : "No",
                "# Transactions": applicationData.rawTransactions.length,
                "# Categories": applicationData.categories.length,
                "# Rules": applicationData.rules.length,
                "Account Type": applicationData.proAccount ? "Budgex Premium" : "Budgex Basic",
                "Premium Account Until": applicationData.proAccount ? applicationData?.proEndDate?.toDateString() : "/",
              }}
            />
          </GenericCard>
        </MDBox>
        <Grid container justifyContent={"center"}>
          <MDBox mb={3}>
            <MDButton color="error" startIcon={<Icon>delete</Icon>} onClick={() => setShowDeleteConfirmation(true)}>
              Delete my account and all data (requires confirmation)
            </MDButton>
          </MDBox>
        </Grid>

        <Dialog fullWidth={true} maxWidth={"xl"} open={showDeleteConfirmation}>
          <CloseableCard onClose={() => setShowDeleteConfirmation(false)}>
            <MDBox m={1}>
              <Grid container justifyContent={"center"}>
                <Grid item xs={12}>
                  <Grid container justifyContent={"center"}>
                    <MDBox m={2}>
                      <MDTypography variant="h4">Confirm</MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent={"center"}>
                    <MDTypography variant="button">All your data will be deleted from our servers. This operation can not be undone.</MDTypography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent={"center"}>
                    <MDTypography variant="button">This includes your transactions, banklinks, categories and also all your payment information.</MDTypography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <MDBox mt={4}>
                    <Grid container justifyContent={"space-around"}>
                      <Grid item>
                        <MDButton onClick={() => apiCalls.deleteMyAccount()}>Yes, delete all my data</MDButton>
                      </Grid>
                      <Grid item>
                        <MDButton onClick={() => setShowDeleteConfirmation(false)}>Cancel</MDButton>
                      </Grid>
                    </Grid>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </CloseableCard>
        </Dialog>
      </Card>
    </MDBox>
  );
}

export default AccountDetailsPage;
