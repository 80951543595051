/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";

import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/DSC_0062_DxO.jpeg";

import React from "react";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { Checkbox, Dialog, Divider, FormControl, FormControlLabel } from "@mui/material";

import { CloseableCard } from "components/TitledCard/GenericCard";
import Policies from "./Policies";
import { LoginCard } from "./LoginCard";
import { SignUpCard } from "./SignUpCard";

const FdcExpenses = require("fdc_expenses");

const LoginPage = function (props) {
  const [showServiceTerms, setShowServiceTerms] = useState(false);
  const [loginMode, setLoginMode] = useState(true);

  // const gl = useGoogleLogin({ flow: "auth-code", onSuccess: (response) => console.log("Success", response), onError: (response) => console.log("Error", response) });

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" mx={2} mt={-3} p={2} mb={1} textAlign="center">
          {loginMode && (
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Sign in
            </MDTypography>
          )}
          {!loginMode && (
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Sign up
            </MDTypography>
          )}
        </MDBox>
        {loginMode && <LoginCard apiCalls={props.apiCalls} apiInstance={props.apiInstance} onLogin={props.onLogin} />}
        {loginMode && (
          <MDBox mt={3} mb={1} textAlign="center">
            <MDTypography variant="button" color="text">
              Don&apos;t have an account?{" "}
              <MDButton color="light" onClick={() => setLoginMode(false)}>
                <MDTypography variant="button" color="info" fontWeight="medium" textGradient>
                  Sign up
                </MDTypography>
              </MDButton>
            </MDTypography>
          </MDBox>
        )}
        {!loginMode && <SignUpCard apiCalls={props.apiCalls} apiInstance={props.apiInstance} onLogin={props.onLogin} />}
        {!loginMode && (
          <MDBox mt={3} mb={1} textAlign="center">
            <MDTypography variant="button" color="text">
              Already have an account?{" "}
              <MDButton color="light" onClick={() => setLoginMode(true)}>
                <MDTypography variant="button" color="info" fontWeight="medium" textGradient>
                  Log in
                </MDTypography>
              </MDButton>
            </MDTypography>
          </MDBox>
        )}
        <MDBox mt={4} mb={1}>
          <Grid container justifyContent="center">
            <MDTypography variant="caption">
              By logging in to a user-account, you accept{" "}
              <MuiLink style={{ color: "#0000FF" }} href="#" underline="always" onClick={() => setShowServiceTerms(true)}>
                The Service Terms
              </MuiLink>
            </MDTypography>
            <MDTypography variant="caption"></MDTypography>
          </Grid>
        </MDBox>
        <Dialog open={showServiceTerms} fullWidth={true} maxWidth={"xl"}>
          <CloseableCard onClose={() => setShowServiceTerms(false)}>
            <MDBox ml={2} mr={2}>
              <Policies />
            </MDBox>
          </CloseableCard>
        </Dialog>
      </Card>
    </BasicLayout>
  );
};

export default LoginPage;
