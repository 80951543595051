import { Grid, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import MDTypography from "components/MDTypography";

export function ExpensesPageBottomBar({ onNewTransactionModifier, offsetStart, offsetEnd, total, onNext, onPrevious }) {
  return (
    <div>
      <MDBox m={3}>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <MDTypography variant="button" fontWeight="light" textTransform="capitalize">
              Showing {offsetStart + 1 || 0} - {offsetEnd || 0} of {total || 0} transactions
            </MDTypography>
          </Grid>
          <Grid item>
            <IconButton onClick={onPrevious}>
              <NavigateBeforeIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton onClick={onNext}>
              <NavigateNextIcon />
            </IconButton>
          </Grid>
        </Grid>
      </MDBox>
    </div>
  );
}
