import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import RichCategories from "domain/RichCategories";
import { useEffect, useMemo, useState } from "react";
import { TreePicker, CheckTreePicker } from "rsuite";

function SingleAutocompleteCategorySelector({ selectedCategory, selectedCategoryChanged, categories, doesSupportMultiple, renderingContainer, label }) {
  const categoryNames = useMemo(() => categories.map((cat) => cat.name), [categories]);

  const options = useMemo(() => {
    const richCats = new RichCategories(categories);

    const allCategoryNames = categories.map((cat) => cat.name);

    return categories.map((cat) => {
      const nameOccursMoreThanOnce = allCategoryNames.indexOf(cat.name) != allCategoryNames.lastIndexOf(cat.name);

      if (nameOccursMoreThanOnce) {
        const prefix = richCats.getCategoryById(cat.parent_id)?.name || "";

        return { label: prefix + "/" + cat.name, id: cat.id };
      } else {
        return { label: cat.name, id: cat.id };
      }
    });
  }, [categories]);

  function handleChange(event, value) {
    if (value) {
      const richCats = new RichCategories(categories);
      const selectedCategory = richCats.getCategoryById(value.id);

      if (selectedCategory) {
        const selectedCategoryName = value.label;
        selectedCategoryChanged(selectedCategory, selectedCategoryName);
      } else {
        selectedCategoryChanged(undefined, undefined);
      }
    } else {
      selectedCategoryChanged(undefined, undefined);
    }
  }

  const value = useMemo(() => {
    if (selectedCategory) {
      const relevantValues = options.filter((option) => selectedCategory.id == option.id);
      if (relevantValues.length == 1) {
        return relevantValues[0];
      }
    }
    return null;
  }, [selectedCategory, options]);

  const richCats = new RichCategories(categories);

  function toData(category) {
    const children = richCats.getAllChildrenOf(category).map((cat) => toData(cat));
    if (children.length > 0) {
      return {
        label: category.name,
        value: category.id,
        children: children,
      };
    } else {
      return {
        label: category.name,
        value: category.id,
      };
    }
  }

  const data = richCats.getRootCategories().map((cat) => toData(cat));

  // Works kinda nice, except for a problem with Z index in dialogs
  return (
    <TreePicker
      placement="auto"
      size="lg"
      container={renderingContainer}
      defaultExpandAll
      data={data}
      value={selectedCategory?.id}
      placeholder={label || "Select category"}
      menuStyle={{ zIndex: 999999 }}
      onChange={(value) => {
        selectedCategoryChanged(richCats.getCategoryById(value));
      }}
    />
  );

  // return (
  //   <Autocomplete
  //     multiple={false}
  //     value={value}
  //     onChange={handleChange}
  //     getOptionLabel={(option) => option.label}
  //     options={options || []}
  //     isOptionEqualToValue={(option, value) => option == value}
  //     renderInput={(params) => {
  //       return <MDInput {...params} variant="standard" />;
  //     }}
  //   />
  // );
}

export default SingleAutocompleteCategorySelector;
