import { Card, Grid, IconButton, Icon } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

export function GenericCard({ children, topleft, topright, topcenter, bgColor }) {
  return (
    <MDBox style={{ backgroundColor: bgColor || "#FFFFFF" }}>
      <Grid container justifyContent={"space-between"} alignItems="center">
        <Grid item>{topleft || ""}</Grid>
        <Grid item>{topcenter || ""}</Grid>
        <Grid item>{topright || ""}</Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </MDBox>
  );
}

export function CloseableCard({ children, onClose, onHelp, bgColor, title }) {
  return (
    <GenericCard
      bgColor={bgColor || "#f1f2f5"}
      topcenter={
        title && (
          <MDBox mt={1} ml={1}>
            <MDTypography fontWeight="medium" variant="h5">
              {title}
            </MDTypography>
          </MDBox>
        )
      }
      topright={
        <MDBox mt={0.5} mr={1}>
          {onHelp && (
            <IconButton onClick={onHelp}>
              <Icon>help</Icon>
            </IconButton>
          )}
          {onClose && (
            <IconButton onClick={onClose}>
              <Icon>close</Icon>
            </IconButton>
          )}
        </MDBox>
      }
      children={children}
    />
  );
}

export default GenericCard;
