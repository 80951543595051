import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import Switch from "@mui/material/Switch";
import { Icon, FormControlLabel, Stack } from "@mui/material";
import { IconButton } from "rsuite";
import MDTypography from "components/MDTypography";

export function ConditionTile({ condition, onConditionChanged, onDelete, title, children, index }) {
  const warnings = condition.getErrors(condition).map((warning, index) => (
    <Grid item xs={12} key={"Warning_" + index}>
      <MDTypography variant="caption" color="error">
        {warning}
      </MDTypography>
    </Grid>
  ));

  return (
    <Grid item xs={12}>
      <MDBox m={1}>
        <Card>
          <MDBox m={0.5}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container justifyContent={"space-between"}>
                  <Grid item>
                    <MDBox ml={1}>
                      <MDTypography variant="h6">{title}</MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item>
                    <Stack direction="row">
                      <FormControlLabel
                        className={"Negate_" + index}
                        control={
                          <Switch
                            checked={condition?.invert || false}
                            onChange={(ev) => {
                              const copiedCondition = Object.assign({}, condition);
                              copiedCondition.invert = ev.target.checked;
                              onConditionChanged(copiedCondition);
                            }}
                          />
                        }
                        label="Negate condition"
                      />
                      <IconButton onClick={onDelete} className={"Delete_" + index}>
                        <Icon>delete</Icon>
                      </IconButton>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
              {warnings.length > 0 && warnings}
              <Grid item xs={12}>
                <MDBox m={1}>{children}</MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </MDBox>
    </Grid>
  );
}
