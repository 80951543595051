import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ReactMarkdown from "react-markdown";

const serviceTermsText = `# Service Terms

## General

**FD Consult** ("we," "us," or "our") is the provider of **Budgex**, a website and accompanying service (the "Service"). 
By accessing or using the Service, you ("you" or "your") agree to be bound by these Service Terms (these "Terms"). 
If you do not agree to all of the terms and conditions contained in these Terms, you may not access or use the Service. 
Information on how we collect, use, and share personal data, can be found in our separate **Privacy Policy**

## Data collection: 

**FD Consult** collects and processes personal data in accordance with our **Privacy Policy**. Please review our **Privacy Policy** for more information on how we collect, use, and share personal data. **FD Consult** will not sell any data that is shared through the Service by you. All data shared through the Service is used solely for the purpose of providing the Service to you, as outlined in our **Privacy Policy**.

## User accounts: 
To access the Service, users must create a **Budgex** account and provide certain personal information, including but not limited to their name and email address. The Service is offered in a free tier, which provides limited access to the features and functionality of the Service. To access the full Service, users may purchase a subscription. **FD Consult** reserves the right to change the features and functionality available in the free tier at any time.
Users are responsible for maintaining the confidentiality of their account login information and are solely responsible for any activity that occurs under their account. **FD Consult** reserves the right to terminate a user's account at any time, for any reason, at our sole discretion.

## Termination of service
Either you or **FD Consult** may terminate your use of the Service at any time, for any reason, by deactivating your account or by providing notice to the other party. 
If you deactivate your account, you will no longer have access to the Service. 
**FD Consult** reserves the right to modify or discontinue the Service at any time, without notice. If **FD Consult** terminates your use of the Service, it will have no obligation to reimburse you for any fees or charges paid in advance. 
If you initiate the termination of your use of the Service, you will not be entitled to any reimbursement of fees or charges paid in advance. 
Upon termination, all provisions of these Terms which by their nature should survive termination will survive termination, including, without limitation, ownership provisions, warranty disclaimers, and limitations of liability.

## Intellectual property
The content and materials included on the Service, including but not limited to text, graphics, logos, images, and software, are the property of **FD Consult** or its licensors and are protected by copyright and other intellectual property laws. 
**FD Consult** owns all rights, title, and interest in and to the Service. You may not use any content or materials on the Service for any commercial purpose without the express written consent of **FD Consult**.
	
All data that you load into the Service remains your property. You are granted a limited, non-exclusive, revocable license to use the Service solely for your personal use. You may share insights and graphs generated using the Service with third parties as long as you properly attribute the source as **Budgex**. All trademarks and service marks included on the Service are the property of **FD Consult** or its licensors.

## Security
**FD Consult** takes reasonable steps to protect the security of the Service and the personal data of our users. All data transmitted between your device and our servers is encrypted to protect against unauthorized access. 
However, **FD Consult** cannot guarantee the security of the Service or any data transmitted through the Service, and you use the Service at your own risk.

You are responsible for maintaining the security of your account and any devices that you use to access the Service. 
This includes selecting a secure password and not sharing it with others, logging out of your account on shared devices, and immediately informing **FD Consult** if you suspect any unauthorized use of your account. 
**FD Consult** is not responsible for any unauthorized access to your account or any loss or damage resulting from such access.

## Liability
**FD Consult** makes no warranties or representations of any kind, express or implied, as to the accuracy, completeness, reliability, suitability, or availability of the Service or the information, content, materials, or products included on the Service. **FD Consult** will not be liable for any decisions or actions taken by you based on information provided through the Service, even if that information is incorrect or inconsistent.
**FD Consult** is not responsible for the consequence of any bugs or technical problems that may occur in the Service. 
**FD Consult** takes reasonable measures to secure the Service and protect against unauthorized access, but we cannot guarantee the security of the Service or any data transmitted through the Service. **FD Consult** will not be liable for any loss or damage resulting from a data breach or unauthorized access to your account or the Service.

**FD Consult** will not be liable for any damages of any kind arising from the use of the Service, including but not limited to direct, indirect, incidental, punitive, and consequential damages. The foregoing limitations of liability will apply to the fullest extent permitted by law in the applicable jurisdiction.

In no event shall the total aggregate liability of **FD Consult** to the user for all damages, losses, and causes of action (whether in contract, tort, or otherwise) exceed the total amount paid by you to **FD Consult** in the 12 months preceding the event giving rise to the claim. This limitation of claim will apply to the fullest extent permitted by law in the applicable jurisdiction.

## Modification of service terms

**FD Consult** reserves the right to modify these Service Terms at any time, and such modifications will be effective immediately upon posting of the modified terms on the Service. **FD Consult** will make a reasonable effort to communicate any material changes to these Service Terms to you through the Service or through other means, such as by email. You are responsible for regularly reviewing these Service Terms, and your continued use of the Service after any modification indicates your acceptance of the modified terms. If you do not agree to any modification of these Service Terms, you must immediately stop using the Service.

## Downtime and changes to the Service
**FD Consult** does not guarantee that the Service will be available at all times or that access to the Service will be uninterrupted. **FD Consult** reserves the right to modify or discontinue the Service or any features or functionality of the Service at any time, without notice. **FD Consult** will make a reasonable effort to inform you of any planned or unplanned downtime or changes to the Service, but is under no obligation to do so. **FD Consult** will also make a reasonable effort to resolve any downtime or issues with the Service as soon as possible, but is under no obligation to do so. You acknowledge and agree that **FD Consult** will not be liable for any modification, suspension, or discontinuation of the Service or any loss or damage resulting from such action.


## Communication: 
**FD Consult** reserves the right to communicate with you for important messages concerning the Service, such as planned or unplanned downtime or changes to these Service Terms. You may also receive commercial communications from **FD Consult**, such as notifications about new features or promotions. Whether or not you wish to receive these commercial communications is up to you and can be configured in your account settings. You may opt out of commercial communications at any time by modifying your account settings or by following the opt-out instructions provided in the communication.

`;

function ServiceTerms({}) {
  return (
    <ReactMarkdown
      children={serviceTermsText}
      components={{
        // Rewrite `em`s (`*like so*`) to `i` with a red foreground color.
        // p: ({ node, ...props }) => <i style={{ color: "red" }} {...props} />,
        h1: "h3",
        h2: "h4",
        p: ({ node, ...props }) => (
          <MDBox mt={2} mb={2}>
            <MDTypography {...props} variant="button" />
          </MDBox>
        ),
      }}
    />
  );
}

export default ServiceTerms;
