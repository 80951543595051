import { FormControlLabel, FormGroup, Grid, Switch } from "@mui/material";
import MDBox from "components/MDBox";
import { useState } from "react";
import Selector from "./Selector";
import SingleAutocompleteCategorySelector from "layouts/pages/classificationflow/singleautocompletecategoryselector";
import MDTypography from "components/MDTypography";
import RichCategories from "domain/RichCategories";
import { GraphItemsForCategories } from "domain/graphconfiguration/GraphItemsDefinition";
import MultiAutocompleteCategorySelector from "../classificationflow/multiautocompletecategoryselector";
import { DistributionOf } from "domain/graphconfiguration/GraphItemsDefinition";
import { ClassificationProgressOf } from "domain/graphconfiguration/GraphItemsDefinition";
import SelectorV2 from "./SelectorV2";
import { ContactPageOutlined } from "@mui/icons-material";
import SingleCategorySelector from "../classificationflow/singlecategoryselector";

export function GraphItemsSelectorV2({ categories, selectionState, setSelectionState, selectedKey, onSelectedOptionKeyChange }) {
  const richCats = new RichCategories(categories);

  const defaultOptionsForSelection = [];
  defaultOptionsForSelection.push(richCats.getCategoryByName("Expenses"));
  defaultOptionsForSelection.push(richCats.getCategoryByName("Income"));

  const options = [
    {
      title: "Custom Selection of categories",
      key: "GraphItemsForCategories",
      getDefaultState: function () {
        return new GraphItemsForCategories(defaultOptionsForSelection);
      },
      stateIsApplicable: (state) => state?.constructor?.name == "GraphItemsForCategories",
      generateContentForState: (state, changedStateCallback) => {
        return (
          <Grid container>
            <Grid item xs={12}>
              <MDTypography variant="caption">Make a selection of categories to visualize</MDTypography>
            </Grid>
            <Grid item xs={12}>
              <MDBox m={1}>
                <MultiAutocompleteCategorySelector
                  categories={categories}
                  selectedCategories={state.categories}
                  selectedCategoriesChanged={(newSelectedCats) => {
                    changedStateCallback(new GraphItemsForCategories(newSelectedCats));
                    onSelectedOptionKeyChange("GraphItemsForCategories");
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
        );
      },
    },
    {
      title: "Composition of category",
      key: "DistributionOf",
      getDefaultState: function () {
        return new DistributionOf(richCats.getCategoryByName("Expenses"));
      },
      stateIsApplicable: (state) => state?.constructor?.name == "DistributionOf",
      generateContentForState: (state, changedStateCallback) => {
        return (
          <Grid container>
            <Grid item xs={12}>
              <MDTypography variant="caption">Show all subcategories of {state.parentCategory?.name || ""}</MDTypography>
            </Grid>
            <Grid item xs={12}>
              <MDBox m={1}>
                <SingleAutocompleteCategorySelector
                  categories={categories}
                  selectedCategory={state.parentCategory}
                  selectedCategoryChanged={(newSelectedCat) => {
                    changedStateCallback(new DistributionOf(newSelectedCat));
                    onSelectedOptionKeyChange("DistributionOf");
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox m={1}>
                <MDTypography variant="caption">
                  {state.parentCategory
                    ? richCats
                        .getAllChildrenOf(state.parentCategory)
                        ?.map((cat) => cat.name)
                        .join(", ")
                    : ""}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        );
      },
    },
    {
      title: "Classification progress",
      key: "ClassificationProgressOf",
      getDefaultState: function () {
        return new ClassificationProgressOf(richCats.getCategoryByName("Expenses"), true, true, true);
      },
      stateIsApplicable: (state) => state?.constructor?.name == "DistributionOf",
      generateContentForState: (state, changedStateCallback) => {
        return (
          <Grid container>
            <Grid item xs={12}>
              <MDTypography variant="caption">Show classification progress of {state.parentCategory?.name || ""}</MDTypography>
            </Grid>
            <Grid item xs={12}>
              <MDBox m={1}>
                <SingleAutocompleteCategorySelector
                  categories={categories}
                  selectedCategory={state.parentCategory}
                  selectedCategoryChanged={(newSelectedCat) => {
                    changedStateCallback(new ClassificationProgressOf(newSelectedCat, state.includeTotal, state.includeNotCategorized, state.includeCategorized));
                    onSelectedOptionKeyChange("ClassificationProgressOf");
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  label={"Show total " + state.parentCategory?.name || ""}
                  control={
                    <Switch
                      checked={state.includeTotal}
                      onChange={(event) => {
                        changedStateCallback(new ClassificationProgressOf(state.parentCategory, event.target.checked, state.includeNotCategorized, state.includeCategorized));
                      }}
                    />
                  }
                />
                <FormControlLabel
                  label={"Show further categorized " + state.parentCategory?.name || ""}
                  control={
                    <Switch
                      checked={state.includeCategorized}
                      onChange={(event) => {
                        changedStateCallback(new ClassificationProgressOf(state.parentCategory, state.includeTotal, state.includeNotCategorized, event.target.checked));
                      }}
                    />
                  }
                />
                <FormControlLabel
                  label={"Show non-categorized " + state.parentCategory?.name || ""}
                  control={
                    <Switch
                      checked={state.includeNotCategorized}
                      onChange={(event) => {
                        changedStateCallback(new ClassificationProgressOf(state.parentCategory, state.includeTotal, event.target.checked, state.includeCategorized));
                      }}
                    />
                  }
                />
              </FormGroup>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12} justifyContent={"center"}>
        <Grid container justifyContent={"center"}>
          <MDTypography>Choose categories to visualize</MDTypography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <SelectorV2
          options={options}
          selectionState={selectionState}
          onSelectionStateChange={setSelectionState}
          selectedOptionKey={selectedKey}
          onSelectedOptionKeyChange={onSelectedOptionKeyChange}
        />
      </Grid>
    </Grid>
  );
}
