import { getColorByIndex, getColorFor } from "utils/color_util";
import { GraphItem } from "domain/graphconfiguration/GraphItem.js";
import RichCategories from "domain/RichCategories";

class GraphItemsDefinition {
  getGraphItems(richCats) {
    return [];
  }
}

export function parseGraphItemDefinition(serialized_object, richCats) {
  const parsedObject = JSON.parse(serialized_object);

  if (parsedObject.type == "GraphItemForSingleCategory") {
    return GraphItemForSingleCategory.fromJson(parsedObject, richCats);
  } else if (parsedObject.type == "GraphItemsForCategories") {
    return GraphItemsForCategories.fromJson(parsedObject, richCats);
  } else if (parsedObject.type == "ClassificationProgressOf") {
    return ClassificationProgressOf.fromJson(parsedObject, richCats);
  } else if (parsedObject.type == "DistributionOf") {
    return DistributionOf.fromJson(parsedObject, richCats);
  }
}

export function serializeGraphItemDefinition(giDefinition) {
  const typeObj = { type: giDefinition.getType() };
  const contentObj = giDefinition.toJson();

  return JSON.stringify(Object.assign({}, typeObj, contentObj));
}

function categoriesToGraphItems(categoriesToConvert, richCats) {
  return categoriesToConvert.map((cat, index) => new GraphItem([cat.id], "All " + cat.name, false, richCats.isAnExpense(cat.id), richCats.getColorForCategory(cat)));
}

export class GraphItemForSingleCategory {
  constructor(category, pure) {
    this.category = category;
    this.pure = pure;
  }

  getGraphItems(richCats) {
    if (!this.pure) {
      return categoriesToGraphItems([this.category], richCats);
    } else {
      return [new GraphItem([this.category.id], "Other " + this.category.name, true, richCats.isAnExpense(this.category.id), richCats.getColorForCategory(this.category))];
    }
  }

  toJson() {
    return { categoryId: this.category.id };
  }

  getType() {
    return "GraphItemForSingleCategory";
  }

  static fromJson(serialized, richCats) {
    return new GraphItemForSingleCategory(richCats.getCategoryById(serialized.categoryId));
  }
}

export class GraphItemsForCategories {
  constructor(categories) {
    this.categories = categories;
  }

  getGraphItems(richCats) {
    return categoriesToGraphItems(this.categories, richCats);
  }

  toJson() {
    return { categoryIds: this.categories.map((c) => c.id) };
  }

  static fromJson(serialized, richCats) {
    return new GraphItemsForCategories(serialized.categoryIds.map((catId) => richCats.getCategoryById(catId)));
  }

  getType() {
    return "GraphItemsForCategories";
  }
}

export class ClassificationProgressOf {
  constructor(parentCategory, includeTotal, includeNotCategorized, includeCategorized) {
    this.parentCategory = parentCategory;
    this.includeTotal = includeTotal;
    this.includeNotCategorized = includeNotCategorized;
    this.includeCategorized = includeCategorized;
  }

  getGraphItems(richCats) {
    const allGraphItems = [];

    if (this.parentCategory) {
      if (this.includeTotal) {
        allGraphItems.push(
          new GraphItem([this.parentCategory.id], "Total " + this.parentCategory.name, false, richCats.isAnExpense(this.parentCategory.id), richCats.getColorForCategory(this.parentCategory))
        );
      }

      if (this.includeNotCategorized) {
        allGraphItems.push(new GraphItem([this.parentCategory.id], "Not classified " + this.parentCategory.name, true, richCats.isAnExpense(this.parentCategory.id), getColorByIndex(1)));
      }

      if (this.includeCategorized) {
        allGraphItems.push(
          new GraphItem(
            richCats.getAllChildrenOf(this.parentCategory).map((cat) => cat.id),
            "Classified " + this.parentCategory.name,
            false,
            richCats.isAnExpense(this.parentCategory.id),
            getColorByIndex(2)
          )
        );
      }
    }

    return allGraphItems;
  }

  getType() {
    return "ClassificationProgressOf";
  }

  toJson() {
    return {
      parentCategoryId: this.parentCategory.id,
      includeTotal: this.includeTotal,
      includeCategorized: this.includeCategorized,
      includeNotCategorized: this.includeNotCategorized,
    };
  }

  static fromJson(serialized, richCats) {
    return new ClassificationProgressOf(richCats.getCategoryById(serialized.parentCategoryId), serialized.includeTotal, serialized.includeNotCategorized, serialized.includeCategorized);
  }
}

export class DistributionOf {
  constructor(parentCategory) {
    this.parentCategory = parentCategory;
  }

  getGraphItems(richCats) {
    if (this.parentCategory) {
      const allChildren = richCats.getAllChildrenOf(this.parentCategory);
      const isExpense = richCats.isAnExpense(this.parentCategory.id);
      const allGraphItems = allChildren.map((child, index) => {
        return new GraphItem([child.id], child.name, false, isExpense, richCats.getColorForCategory(child));
      });

      allGraphItems.push(new GraphItem([this.parentCategory.id], "Other " + this.parentCategory.name, true, isExpense, richCats.getColorForCategory(this.parentCategory)));

      return allGraphItems;
    } else {
      return [];
    }
  }

  toJson() {
    return {
      parentCategoryId: this.parentCategory.id,
    };
  }

  getType() {
    return "DistributionOf";
  }

  static fromJson(serialized, richCats) {
    return new DistributionOf(richCats.getCategoryById(serialized.parentCategoryId));
  }
}
