import { Grid, Icon, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMemo, useState } from "react";

export function MultiPageList({ items, itemsPerPage, itemRenderFunction, itemName }) {
  const [offset, setOffset] = useState(0);

  const offsetEnd = Math.min(offset + itemsPerPage, items.length);

  function onNext() {
    setOffset(offsetEnd);
  }

  function onPrevious() {
    setOffset(Math.max(0, offset - itemsPerPage));
  }

  const itemsToRender = useMemo(
    () =>
      items.slice(offset, offsetEnd).map((item, index) => (
        <Grid item xs={12} key={"list" + itemName + index}>
          {itemRenderFunction(item)}
        </Grid>
      )),
    [items, offset, offsetEnd]
  );

  return (
    <Grid container>
      {itemsToRender}
      <Grid item xs={12}>
        <MDBox m={3}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <MDTypography variant="button" fontWeight="light" textTransform="capitalize">
                Showing {offset || 1} - {offsetEnd || 1} of {items?.length || 0} {" " + itemName}
              </MDTypography>
            </Grid>
            <Grid item>
              <IconButton onClick={onPrevious}>
                {/* <NavigateBeforeIcon /> */}
                <Icon>navigate_before</Icon>
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton onClick={onNext}>
                {/* <NavigateNextIcon /> */}
                <Icon>navigate_next</Icon>
              </IconButton>
            </Grid>
          </Grid>
        </MDBox>
      </Grid>
    </Grid>
  );
}
