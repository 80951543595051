// GraphConfiguration contains a list of items, a start_date and an end_date
// {label, category_id, pure, color}

function getIndexForDate(dateToFind, timeSeriesData) {
  return timeSeriesData.times.filter((time) => time <= dateToFind).length;
}

function generatePiechartData(graphConfiguration, timeSeriesData) {
  if (timeSeriesData) {
    const data = graphConfiguration.items.map((item) => {
      const allRelevantTimeSeries = timeSeriesData.timeseries.filter((ts) => item.categoryIds.includes(ts.category.id));

      const dataForAllTimeseries = allRelevantTimeSeries
        .map((timeSeriesToConsider) => {
          const startIndex = getIndexForDate(graphConfiguration.start, timeSeriesData);
          const endIndex = getIndexForDate(graphConfiguration.end, timeSeriesData);

          if (item.pure) {
            return timeSeriesToConsider.pure_values.slice(startIndex, endIndex);
          } else {
            return timeSeriesToConsider.values.slice(startIndex, endIndex);
          }
        })
        .map((dataItems) => dataItems.reduce((a, b) => a + b, 0));

      return Math.abs(dataForAllTimeseries.reduce((a, b) => a + b, 0));
    });

    const labels = graphConfiguration.items.map((item) => item.label);
    const colors = graphConfiguration.items.map((item) => item.color);

    return {
      labels: labels,
      datasets: [
        {
          label: "",
          data: data,
          backgroundColor: colors,
        },
      ],
    };
  } else {
    return {
      labels: [],
      datasets: [],
    };
  }
}

export default generatePiechartData;
