export class TimeRange {
  constructor(start, end, label, color) {
    this.start = start;
    this.end = end;
    this.label = label;
    this.color = color;
  }

  getDurationInDays() {
    return this.end.diff(this.start, "days")["days"];
  }
}
