import { Card, CardActionArea, Dialog, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { CloseableCard } from "components/TitledCard/GenericCard";
import RichCategories from "domain/RichCategories";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ConflictDetails from "./conflictdetails";
import DetailsCard from "./detailscard";

function ConflictTile({ conflict, applicationData, onShowDetails }) {
  // return <div>Conflict</div>;
  const richCats = new RichCategories(applicationData.categories);

  const impactedCategories = applicationData.rules
    .filter((r) => conflict.matched_rules.includes(r.id))
    .map((r) => richCats.getCategoryById(r.categoryId)?.name)
    .join(", ");

  return (
    <MDBox m={0.5}>
      <Card>
        <CardActionArea onClick={(ev) => onShowDetails()}>
          <MDBox m={0.5} mb={1.5} mr={1.5}>
            <DetailsCard
              title={"Conflict for transaction " + conflict.transaction_id}
              keyValuePairs={{
                "Transaction ID": conflict.transaction_id,
                "Number of matching rules": conflict.matched_rules.length,
                "Impacted categories": impactedCategories,
                "Rule IDs": conflict.matched_rules.join(", "),
              }}
            />
          </MDBox>
        </CardActionArea>
      </Card>
    </MDBox>
  );
}

function ConflictsPage({ applicationData, apiCalls, callbacks, apiClient }) {
  const [selectedConflict, setSelectedConflict] = useState();
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => apiCalls.updateAnalysisV2IfNeeded());

  const location = useLocation();
  useEffect(() => {
    apiCalls.setHelpCallback(undefined); // TODO
  }, [location]);

  const conflictTiles = applicationData?.analysisV2?.classification_conflicts?.map((c, index) => (
    <Grid item xs={12} md={6} xl={4} key={"Conflict for " + c.transaction_id}>
      <ConflictTile
        conflict={c}
        applicationData={applicationData}
        onShowDetails={() => {
          setSelectedConflict(c);
          setShowDetails(true);
        }}
      />
    </Grid>
  ));

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Grid container justifyContent={"center"}>
            <MDBox m={1} mb={3}>
              <MDTypography>Total number of conflicts: {conflictTiles?.length}</MDTypography>
            </MDBox>
          </Grid>
        </Grid>
        {conflictTiles}
      </Grid>
      <Dialog open={showDetails} fullWidth={true} maxWidth={false}>
        <Card>
          <ConflictDetails
            conflict={selectedConflict}
            transactions={applicationData.rawTransactions}
            categories={applicationData.categories}
            rules={applicationData.rules}
            apiCalls={apiCalls}
            callbacks={callbacks}
            apiClient={apiClient}
            onClose={() => {
              setShowDetails(false);
            }}
          />
        </Card>
      </Dialog>
    </div>
  );
}

export default ConflictsPage;
