import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect } from "react";
import { OptionForSelector } from "./OptionForSelector";

function SelectorV2({ title, options, selectedOptionKey, onSelectedOptionKeyChange, selectionState, onSelectionStateChange }) {
  function setsAreIdentical(a, b) {
    return a.size === b.size && new Set([...a, ...b]).size === a.size;
  }

  // Check that the selectionState is consistent with the options that we have at our disposal
  function assertValidityOfSelectionState() {
    const optionKeys = new Set(options.map((o) => o.key));
    const selectionStateKeys = new Set(Object.keys(selectionState));

    if (!setsAreIdentical(optionKeys, selectionStateKeys)) {
      const newSelectionState = Object.fromEntries(
        new Map(
          options.map((option) => {
            if (option.key in selectionState) {
              return [option.key, selectionState[option.key]];
            } else {
              return [option.key, option.getDefaultState()];
            }
          })
        )
      );

      onSelectionStateChange(newSelectionState);
    }
  }

  useEffect(() => assertValidityOfSelectionState());

  function onStateChange(key, newState) {
    const newSelectionState = Object.assign({}, selectionState);
    newSelectionState[key] = newState;
    onSelectionStateChange(newSelectionState);
  }

  const xlSize = 12 / Math.min(4, options.length);
  const mdSize = 12 / Math.min(4, options.length);
  const sSize = 12 / Math.min(1, options.length);

  const optionElements = options
    ?.filter((option) => option.key in selectionState)
    .map((option) => {
      const stateForOption = selectionState[option.key];
      const optionIsSelected = option.key == selectedOptionKey;

      return (
        <Grid item xl={xlSize} md={mdSize} xs={sSize} key={option.key}>
          <div class={"Option_" + option.key}>
            <MDBox m={0.5}>
              <OptionForSelector
                title={option.title_function ? option.title_function(selectionState[option.key]) : option.title}
                isSelected={optionIsSelected}
                onSelect={() => {
                  onSelectedOptionKeyChange(option.key);
                }}
              >
                {option.generateContentForState(selectionState[option.key], (newState) => {
                  onStateChange(option.key, newState);
                })}
              </OptionForSelector>
            </MDBox>
          </div>
        </Grid>
      );
    });

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container justifyContent={"center"}>
          <MDTypography>{title}</MDTypography>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ overflow: "auto" }}>
        <Grid container alignItems={"stretch"} justifyContent={"stretch"}>
          {optionElements}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SelectorV2;
