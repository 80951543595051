import { Dialog, Grid, Stack } from "@mui/material";
import MDBox from "components/MDBox";

import { useEffect, useMemo, useState } from "react";

import { TransactionCard } from "./TransactionCard";
import TransactionDetailsForRule from "./transactiondetailsforrule";

import Classifier from "layouts/expenses/classifier";
import { ExpensesPageToolBar } from "./ExpensesPageToolBar";
import { ExpensesPageBottomBar } from "./ExpensesPageBottomBar";
import { useCookies } from "react-cookie";
import RichCategories from "domain/RichCategories";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { useLocation } from "react-router-dom";
import { demoCallbacks } from "utils/JoyRideUtils";
import { DateTime } from "luxon";
import MDTypography from "components/MDTypography";
import FDDemo from "utils/FDDemo";

const commonSteps = [
  {
    target: ".NavBar",
    title: "Transaction List",
    content: <MDTypography variant="button">On this page, you get an overview of your transactions. This page is also the starting point to assign categories to transactions</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".SearchTransactions",
    title: "Search",
    content: <MDTypography variant="button">Using this text field, you can search through your transactions</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".SortTransactions",
    title: "Sort",
    content: <MDTypography variant="button">Using this button, you can sort the transactions by date, amount or counterpartname. Either ascending or descending</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".SelectCategories",
    title: "Filter on categories",
    content: <MDTypography variant="button">You can show the transactions of one or more categories by selecting them in this dropdown</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".ShowAll",
    title: "Reset category filter",
    content: <MDTypography variant="button">This button resets the category filter and shows all transactions</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".ShowNonClassifiedOnly",
    title: "Set category filter",
    content: <MDTypography variant="button">By pressing this button, you get an overview of all expenses and income that have no specific category</MDTypography>,
    disableBeacon: true,
  },
];

const generalSteps = commonSteps.concat([
  {
    target: ".Transaction0",
    disableBeacon: true,
    content: (
      <MDTypography variant="button" fontWeight="medium">
        If you want to assign a category to a transaction, just click on it to open a classification pop-up.
      </MDTypography>
    ),
  },
]);

const specificSteps = commonSteps.concat([
  {
    target: ".Transaction0",
    content: (
      <Stack>
        <MDTypography variant="button">If you want to assign a category to a transaction, just click on it to open a classification pop-up. </MDTypography>
        <MDBox mt={2}>
          <MDTypography variant="button" fontWeight="medium">
            Click on the transaction card to continue the demo
          </MDTypography>
        </MDBox>
      </Stack>
    ),
    disableBeacon: true,
    spotlightClicks: true,
    hideCloseButton: true,
    disableOverlayClose: true,
    hideFooter: true,
  },
]);

function TransactionListPage({ categories, applicationData, apiClient, callbacks, apiCalls }) {
  const [showDemo, setShowDemo] = useState();

  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes("transactionlist")) {
      apiCalls.setHelpCallback(() => () => setShowDemo("General"));
      if (location.search.includes("showHelpRule")) {
        setShowDemo("Rule");
      }
      if (location.search.includes("showHelpSingle")) {
        setShowDemo("Single");
      }
    }
  }, [location]);

  return (
    <div className="TransactionListPage">
      <TransactionList
        categories={categories}
        categoryAssignment={applicationData.categoryAssignment}
        transactions={applicationData?.rawTransactions}
        apiClient={apiClient}
        callbacks={callbacks}
        useCookies={true}
        apiCalls={apiCalls}
        applicationData={applicationData}
        externalDemo={showDemo}
        onStopExternalDemo={() => setShowDemo(undefined)}
      />
    </div>
  );
}

export function TransactionList({ categories, categoryAssignment, transactions, apiClient, callbacks, shouldUseCookies, apiCalls, applicationData, externalDemo, onStopExternalDemo }) {
  const resultsPerPage = 25;

  const [cookies, setCookie, removeCookie] = useCookies(["selectedCategoryIds", "highestFirst", "sortValue"]);
  const [selectedTransaction, setSelectedTransaction] = useState();
  const [sortValue, setSortValue] = useState(getDefaultSortValue());
  const [highestFirst, setHighestFirst] = useState(getDefaultHighestFirst());
  const [showTransactionDetails, setShowTransactionDetails] = useState(false);
  const [showDemoForPopup, setShowDemoForPopup] = useState(externalDemo);
  const [selectedCategories, setSelectedCategories] = useState(getDefaultSelectedCategories());
  const [searchText, setSearchText] = useState("");

  const [offset, setOffset] = useState(0);

  function stopExternalDemoIfNeeded() {
    if (externalDemo?.length > 0) {
      if (onStopExternalDemo) {
        console.log("TranasctionListPage Will stop external demo");
        onStopExternalDemo();
      }
    }
  }

  function setAndStoreHighestFirst(value) {
    setHighestFirst(value);
    if (useCookies) {
      setCookie("highestFirst", value, { path: "/", maxAge: 3600 });
    }
  }

  function setAndStoreSortValue(value) {
    setSortValue(value);
    if (useCookies) {
      setCookie("sortValue", value, { path: "/", maxAge: 3600 });
    }
  }

  function getDefaultHighestFirst() {
    if (cookies.highestFirst && useCookies) {
      return cookies.highestFirst == "true";
    } else {
      return true;
    }
  }

  function getDefaultSortValue() {
    if (cookies.sortValue && useCookies) {
      return cookies.sortValue;
    } else {
      return "timestamp";
    }
  }

  function getDefaultSelectedCategories() {
    if (cookies.selectedCategoryIds && Array.isArray(cookies.selectedCategoryIds) && useCookies) {
      const richCats = new RichCategories(categories);
      return cookies.selectedCategoryIds.map((id) => richCats.getCategoryById(id)).filter((item) => item);
    } else {
      return [];
    }
  }

  function createItemForTransaction(trans, categoryId, index) {
    return (
      <Grid item xs={12} xl={12} key={trans.id}>
        <MDBox mb={1}>
          <div className={"Transaction" + index}>
            <TransactionCard
              index={index}
              categoryId={categoryId}
              categories={categories}
              transaction={trans}
              onClick={(ev) => {
                // We pass the information about the demo for the pop-up through, in case that it wanted to show it
                console.log("Setting demo for pop-up to", externalDemo);
                setShowDemoForPopup(externalDemo);

                // If we show the details for the transactions, the demo of this page ends here
                stopExternalDemoIfNeeded();
                setSelectedTransaction(trans);
                setShowTransactionDetails(true);
              }}
            />
          </div>
        </MDBox>
      </Grid>
    );
  }

  const relevantTransactions = useMemo(() => {
    if (transactions) {
      const copyOfTransactions = [...transactions]
        .filter((trans) => {
          const allAreFine = selectedCategories.length == 0;

          const transactionIsClassified = trans.id in (categoryAssignment || {});
          var correctCategoryName = false;

          if (transactionIsClassified) {
            correctCategoryName = selectedCategories.map((cat) => cat.id).includes(categoryAssignment[trans.id]);
          }

          return allAreFine || correctCategoryName;
        })
        .filter((trans) => {
          const noSearchText = searchText.length < 1;

          const propertiesList = [trans.comments, trans.description, trans.counter_part_name, trans.counter_part_account];

          const searchTextFound = propertiesList.map((property) => (property || "").toLowerCase().includes(searchText.toLowerCase())).reduce((a, b) => a || b, false);

          return noSearchText || searchTextFound;
        });

      copyOfTransactions.sort(function (t1, t2) {
        if (sortValue == "amount") {
          return Math.abs(t1[sortValue]) - Math.abs(t2[sortValue]);
        } else {
          return t1[sortValue] - t2[sortValue];
        }
      });

      if (highestFirst) {
        copyOfTransactions.reverse();
      }

      return copyOfTransactions;
    } else {
      return [];
    }
  }, [transactions, categoryAssignment, sortValue, highestFirst, selectedCategories, searchText]);

  const items = useMemo(
    () =>
      relevantTransactions
        .slice(offset, offset + resultsPerPage)
        .filter((t) => t.id in categoryAssignment)
        .map((t, index) => createItemForTransaction(t, categoryAssignment[t.id], index)),
    [relevantTransactions, offset, resultsPerPage, categoryAssignment, externalDemo, showDemoForPopup]
  );

  return (
    <div>
      <Grid container>
        <Grid item xs={12} mb={3}>
          <ExpensesPageToolBar
            id="toolbar"
            searchText={searchText}
            setSearchText={setSearchText}
            sortValue={sortValue}
            sortValueChange={setAndStoreSortValue}
            reversed={highestFirst}
            reversedChange={setAndStoreHighestFirst}
            categories={categories}
            selectedCategories={selectedCategories}
            selectedCategoriesChanged={(categories, categorieslabels) => {
              setSelectedCategories(categories);
              if (useCookies) {
                setCookie("selectedCategoryIds", categories, { path: "/", maxAge: 3600 });
              }

              setOffset(0);
            }}
          />
        </Grid>
        {items}
        <Grid item xs={12} mb={5} className="grid">
          <ExpensesPageBottomBar
            offsetStart={offset}
            offsetEnd={Math.min(offset + resultsPerPage, relevantTransactions.length)}
            total={relevantTransactions.length}
            onNext={(ev) => {
              setOffset(Math.min(offset + resultsPerPage, relevantTransactions.length));
              document.documentElement.scrollTop = 0;
              document.scrollingElement.scrollTop = 0;
            }}
            onPrevious={(ev) => {
              setOffset(Math.max(0, offset - resultsPerPage));
              document.documentElement.scrollTop = 0;
              document.scrollingElement.scrollTop = 0;
            }}
          />
        </Grid>
      </Grid>
      <Dialog open={showTransactionDetails} fullWidth={true} maxWidth={"xl"} scroll="paper">
        {selectedTransaction != undefined && (
          <TransactionDetailsForRule
            applicationData={applicationData}
            transaction={selectedTransaction}
            onClose={() => {
              setShowTransactionDetails(false);
              setSelectedTransaction(null);
              stopExternalDemoIfNeeded();
            }}
            apiCalls={apiCalls}
            externalDemo={showDemoForPopup}
          />
        )}
      </Dialog>

      <FDDemo
        run={externalDemo?.length > 0 && !applicationData.isLoadingTransactions}
        steps={externalDemo == "General" ? generalSteps : specificSteps}
        onFinished={() => {
          console.log("Consider the Page demo finished");
          // On Finished, we create a fake transaction and open the pop up

          if (externalDemo != "General") {
            const transactionForDemo = { amount: -15.0, account: "IBANXXXX", comments: "This is a demo transaction", counter_part_name: "John Doe", timestamp: DateTime.now().toJSDate(), id: -1 };
            setSelectedTransaction(transactionForDemo);
            setShowTransactionDetails(true);
          } else {
            stopExternalDemoIfNeeded();
          }
        }}
        onSkipped={() => {
          console.log("Consider the Page demo skipped, will stop the external demo");
          stopExternalDemoIfNeeded();
        }}
      />

      {/* <Joyride
        styles={{
          options: {
            arrowColor: "#e3ffeb",
            // backgroundColor: "#e3ffeb",
            // overlayColor: "rgba(79, 26, 0, 0.4)",
            primaryColor: "#000",
            textColor: "#004a14",
            width: 900,
            zIndex: 1000000,
          },
        }}
        callback={(d) =>
          demoCallbacks(
            d,
            () => {
              console.log("Consider the Page demo finished");
              // On Finished, we create a fake transaction and open the pop up

              if (externalDemo != "General") {
                const transactionForDemo = { amount: -15.0, account: "IBANXXXX", comments: "This is a demo transaction", counter_part_name: "John Doe", timestamp: DateTime.now().toJSDate(), id: -1 };
                setSelectedTransaction(transactionForDemo);
                setShowTransactionDetails(true);
              } else {
                stopExternalDemoIfNeeded();
              }
            },
            () => {
              console.log("Consider the Page demo skipped, will stop the external demo");
              stopExternalDemoIfNeeded();
            },
            () => {}
          )
        }
        disableBeacon={true}
        run={externalDemo?.length > 0 && !applicationData.isLoadingTransactions}
        debug={true}
        steps={externalDemo == "General" ? generalSteps : specificSteps}
        continuous={true}
        showSkipButton={true}
        disableScrolling={true}
      ></Joyride> */}
    </div>
  );
}

export default TransactionListPage;
