/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import MDInput from "components/MDInput";

// NewProduct page components
import { AppBar, Divider, MenuItem, Select, Tabs, Tab, Icon, Dialog, Menu, FormGroup, Card } from "@mui/material";
import MDButton from "components/MDButton";
import { TabPanelUnstyled } from "@mui/base";
import SingleAutocompleteCategorySelector from "layouts/pages/classificationflow/singleautocompletecategoryselector";
import { CloseableCard } from "components/TitledCard/GenericCard";
import CreateNewCategoryCard from "layouts/categorymanagement/CreateNewCategoryCard";
import { StringCondition } from "./StringCondition";
import { DateCondition } from "./DateCondition";
import { AmountCondition } from "./AmountCondition";
import { DateTime } from "luxon";
import ReactJoyride from "react-joyride";
import { stopJoyRideWhenFinished } from "utils/JoyRideUtils";
import FDDemo from "utils/FDDemo";

const FdcExpenses = require("fdc_expenses");

const steps = [
  {
    target: ".StringCondition_0",
    content: (
      <MDTypography variant="button">These cards describe the filters that make up your rule. This specific filter will match all your transactions against the text of this filter</MDTypography>
    ),
    disableBeacon: true,
  },
  {
    target: ".SearchText_0",
    content: (
      <MDTypography variant="button">
        This text will be used to search through your transactions. This rule will apply to all transactions that contain this text. You can change this text as you wish
      </MDTypography>
    ),
    disableBeacon: true,
  },
  {
    target: ".Negate_0",
    content: <MDTypography variant="button">Use this switch to define the opposite filter. Eg does NOT include the word 'transfer'</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".AddCondition",
    content: <MDTypography variant="button">Using this button, you can add more filters for this rule. You can add filters based on Text, the transaction Date or the Transaction Amount</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".SelectCategory",
    content: <MDTypography variant="button">Here you can select the category that must be assigned to all transactions that match this rule</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".SaveRule",
    content: <MDTypography variant="button">When you are ready defining your rule, you can save it, using this button</MDTypography>,
    disableBeacon: true,
  },
];

function createStringFilter(propertyName, filterValue) {
  const stringCondition = new FdcExpenses.StringCondition();
  stringCondition.field_name = propertyName;
  stringCondition.query = filterValue;
  stringCondition.exact_match = false;

  const condition = new FdcExpenses.Condition();
  condition.string_condition = stringCondition;
  return condition;
}

function createDefaultStringCondition(defaultSearchText, forAccount) {
  return {
    type: "string",
    text: defaultSearchText || "",
    counter_part: !forAccount,
    description: !forAccount,
    comments: !forAccount,
    account: forAccount,
    toBudgexCondition: (condition) => {
      const ruleConditions = [];

      if (condition.counter_part) {
        ruleConditions.push(createStringFilter("counter_part_name", condition.text));
      }
      if (condition.description) {
        ruleConditions.push(createStringFilter("description", condition.text));
      }
      if (condition.comments) {
        ruleConditions.push(createStringFilter("comments", condition.text));
      }
      if (condition.account) {
        ruleConditions.push(createStringFilter("account", condition.text));
      }

      const combinedStringCondition = new FdcExpenses.Condition();
      combinedStringCondition.or_condition = new FdcExpenses.OrCondition(ruleConditions);
      return combinedStringCondition;
    },
    getErrors: (condition) => {
      const errors = [];
      const somethingDefined = condition?.counter_part || condition?.description || condition?.comments || condition?.account;
      if (!somethingDefined) {
        errors.push("Filter should search in either Counterpart, description, comments or account");
      }
      if (!condition.text || condition.text.length < 3) {
        errors.push("Filter text should be at least 3 characters long");
      }

      return errors;
    },
  };
}

function createDefaultDateCondition() {
  return {
    type: "date",
    min_date: DateTime.now(),
    max_date: DateTime.now(),
    toBudgexCondition: (condition) => {
      const dateCondition = new FdcExpenses.Condition();
      dateCondition.date_condition = new FdcExpenses.DateCondition();
      dateCondition.date_condition.after = condition.min;
      dateCondition.date_condition.before = condition.max;
      return dateCondition;
    },
    getErrors: (condition) => {
      const errors = [];
      if (condition.min_date == undefined && condition.max_date == undefined) {
        errors.push("At least one of start or stop date must be defined");
      }
      if (condition.min_date != undefined && condition.max_date != undefined && condition.min_date >= condition.max_date) {
        errors.push("Start date should be before end date");
      }

      return errors;
    },
  };
}

function createDefaultAmountCondition() {
  return {
    type: "amount",
    min: -1000,
    max: 10000,
    toBudgexCondition: (condition) => {
      const onlyExpensesCondition = new FdcExpenses.Condition();
      onlyExpensesCondition.amount_condition = new FdcExpenses.AmountCondition();
      onlyExpensesCondition.amount_condition.lower_boundary = condition.min;
      onlyExpensesCondition.amount_condition.upper_boundary = condition.max;
      return onlyExpensesCondition;
    },
    getErrors: (condition) => {
      const errors = [];
      if (condition.min == undefined && condition.max == undefined) {
        errors.push("At least one of minimum or maximum");
      }
      if (condition.min != undefined && condition.max != undefined && condition.min >= condition.max) {
        errors.push("Minimum should be smaller than maximum");
      }

      return errors;
    },
  };
}

function RuleCreatorV4({ defaultCategory, proposedSearchText, forAccount, categories, apiCalls, onNewRuleCreated, showDemo, onDemoFinished }) {
  const [category, setCategory] = useState(defaultCategory);
  const [conditions, setConditions] = useState([createDefaultStringCondition(proposedSearchText, forAccount)]);
  const [ref, setRef] = useState();
  const [menuOpen, setMenuOpen] = useState(false);
  const [showCreateNewCategory, setShowCreateNewCategory] = useState(false);

  const renderedConditions = conditions.map((cond, index) => {
    if (cond.type == "string") {
      return (
        <div className={"StringCondition_" + index}>
          <StringCondition
            key={"Condition_" + index}
            condition={cond}
            index={index}
            onDelete={() => {
              const copiedConditions = conditions.slice();
              copiedConditions.splice(index, 1);
              setConditions(copiedConditions);
            }}
            onConditionChanged={(newCondition) => {
              const copiedConditions = conditions.slice();
              copiedConditions.splice(index, 1, newCondition);
              setConditions(copiedConditions);
            }}
          />
        </div>
      );
    } else if (cond.type == "date") {
      return (
        <div className={"DateCondition_" + index}>
          <DateCondition
            key={"Condition_" + index}
            condition={cond}
            index={index}
            onDelete={() => {
              const copiedConditions = conditions.slice();
              copiedConditions.splice(index, 1);
              setConditions(copiedConditions);
            }}
            onConditionChanged={(newCondition) => {
              const copiedConditions = conditions.slice();
              copiedConditions.splice(index, 1, newCondition);
              setConditions(copiedConditions);
            }}
          />
        </div>
      );
    } else if (cond.type == "amount") {
      return (
        <div className={"AmountCondition_" + index}>
          <AmountCondition
            key={"Condition_" + index}
            condition={cond}
            index={index}
            onDelete={() => {
              const copiedConditions = conditions.slice();
              copiedConditions.splice(index, 1);
              setConditions(copiedConditions);
            }}
            onConditionChanged={(newCondition) => {
              const copiedConditions = conditions.slice();
              copiedConditions.splice(index, 1, newCondition);
              setConditions(copiedConditions);
            }}
          />
        </div>
      );
    }
  });
  function closeMenu() {
    setRef(null);
    setMenuOpen(false);
  }

  const noCategorySelected = category == undefined;
  const noFilters = conditions.length == 0;
  const errorsInFilters = conditions.flatMap((c) => c.getErrors(c)).length > 0;

  const canCreateRule = !noCategorySelected && !noFilters && !errorsInFilters;

  function createRule() {
    const subConditions = conditions.map((c) => {
      const rawBudgexCondition = c.toBudgexCondition(c);
      if (c.invert) {
        const notCondition = new FdcExpenses.Condition();
        notCondition.not_condition = new FdcExpenses.NotCondition();
        notCondition.not_condition.underlying_condition = rawBudgexCondition;

        return notCondition;
      } else {
        return rawBudgexCondition;
      }
    });

    const totalCondition = new FdcExpenses.Condition();
    totalCondition.and_condition = new FdcExpenses.AndCondition();
    totalCondition.and_condition.underlying_conditions = subConditions;

    const rule = new FdcExpenses.Rule(category.id, totalCondition);
    apiCalls.createNewRule(rule);
    onNewRuleCreated();
  }

  return (
    <Grid container>
      {noCategorySelected && (
        <Grid item xs={12}>
          <MDTypography variant="caption" color="error">
            Select category for rule
          </MDTypography>
        </Grid>
      )}
      {noFilters && (
        <Grid item xs={12}>
          <MDTypography variant="caption" color="error">
            Add at least one filter
          </MDTypography>
        </Grid>
      )}
      {errorsInFilters && (
        <Grid item xs={12}>
          <MDTypography variant="caption" color="error">
            Solve problems in filter
          </MDTypography>
        </Grid>
      )}
      {renderedConditions}

      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <MDBox m={1} className={"AddCondition"}>
              <MDButton
                fullWidth
                onClick={(ev) => {
                  setRef(ev.currentTarget);
                  setMenuOpen(true);
                }}
                startIcon={<Icon>add</Icon>}
                endIcon={<Icon>keyboard_arrow_down</Icon>}
              >
                Add extra filter
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
        <Menu open={menuOpen} anchorEl={ref} onClose={closeMenu}>
          <MenuItem
            onClick={(ev) => {
              closeMenu();
              setConditions(conditions.concat([createDefaultStringCondition()]));
            }}
          >
            Text filter
          </MenuItem>
          <MenuItem
            onClick={(ev) => {
              closeMenu();
              setConditions(conditions.concat([createDefaultDateCondition()]));
            }}
          >
            Date filter
          </MenuItem>
          <MenuItem
            onClick={(ev) => {
              closeMenu();
              setConditions(conditions.concat([createDefaultAmountCondition()]));
            }}
          >
            Amount filter
          </MenuItem>
        </Menu>
      </Grid>
      <Grid item xs={12}>
        <Divider></Divider>
      </Grid>
      <Grid item xs={12}>
        <MDBox mt={1}>
          <Grid container spacing={2} display="flex" alignItems="stretch" justifyContent="space-evenly">
            <Grid item>
              <MDButton onClick={(ev) => setShowCreateNewCategory(true)} startIcon={<Icon>add</Icon>}>
                Create new category
              </MDButton>
            </Grid>
            <Grid item className={"SelectCategory"}>
              <SingleAutocompleteCategorySelector
                categories={categories}
                label={"Select Category For Rule"}
                selectedCategory={category || null}
                selectedCategoryChanged={(selectedCategory, selectedCategoryLabel) => {
                  if (selectedCategory) {
                    setCategory(selectedCategory);
                  } else {
                    setCategory(null);
                  }
                }}
              />
            </Grid>

            <Grid item className={"SaveRule"}>
              <MDButton color="success" disabled={!canCreateRule} onClick={(ev) => createRule()}>
                Save this rule
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </Grid>

      <Dialog open={showCreateNewCategory}>
        <CreateNewCategoryCard categories={categories} onClose={(ev) => setShowCreateNewCategory(false)} apiCalls={apiCalls} />
      </Dialog>
      <FDDemo run={showDemo} steps={steps} onStop={onDemoFinished} />
    </Grid>
  );
}

export default RuleCreatorV4;
