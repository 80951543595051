import { Card, Divider, Grid, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import TitledCard from "components/TitledCard/titled_card";
import RuleCreatorV3 from "layouts/expenses/rule_creator_v3";
import DetailsCard from "./detailscard";
import RuleDetails from "./ruledetails";
import TransactionDetails from "./transactiondetails";
import MDButton from "components/MDButton";
import CloseIcon from "@mui/icons-material/Close";
import SingleAutocompleteCategorySelector from "../classificationflow/singleautocompletecategoryselector";
import { useState } from "react";
import { CloseableCard } from "components/TitledCard/GenericCard";

function ConflictDetails({ conflict, transactions, categories, rules, apiClient, onClose, callbacks, apiCalls }) {
  const referencedTransactions = transactions?.filter((tr) => tr.id == conflict.transaction_id);

  function getRuleById(id) {
    const relevantRules = rules.filter((rule) => rule.id == id);
    if (relevantRules.length > 0) {
      return relevantRules[0];
    } else {
      return undefined;
    }
  }

  function getCategoriesForRules(matched_rule_ids) {
    const categoryIds = rules.filter((rule) => matched_rule_ids.includes(rule.id)).map((rule) => rule.categoryId);
    return categories.filter((cat) => categoryIds.includes(cat.id)).map((cat) => cat.name);
  }

  function getCategoryById(catId) {
    return categories.filter((cat) => cat.id == catId)[0];
  }

  function getMatchedCategoryKeyPairs() {
    const matchedCategories = getCategoriesForRules(conflict.matched_rules);
    const kvs = {};

    for (let i = 0; i < matchedCategories.length; i++) {
      kvs["Category " + (i + 1)] = matchedCategories[i];
    }

    return kvs;
  }

  function getItemForRule(rule) {
    return (
      <Grid key={"RuleDetailsFor_" + rule.id} item xs={12}>
        <Grid container display="flex" justifyContent={"center"} alignItems={"flex-end"}>
          <Grid item xs={9}>
            <RuleDetails rule={rule} category={getCategoryById(rule.categoryId)} />
          </Grid>
          <Grid item xs={3}>
            <MDButton
              color="error"
              onClick={(ev) => {
                apiClient.deleteRuleById(rule.id, () => callbacks.onRuleDeleted());

                onClose();
              }}
            >
              Delete Rule
            </MDButton>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function getRuleDetails() {
    const rulesToShow = conflict.matched_rules.map((ruleId) => getRuleById(ruleId)).filter((v) => v);

    return rulesToShow.map((rule) => {
      return getItemForRule(rule);
    });
  }

  const [selectedCategory, setSelectedCategory] = useState();

  return (
    <MDBox>
      <CloseableCard onClose={(ev) => onClose()}>
        <Grid container p={3}>
          <Grid item xs={9}>
            <TransactionDetails transaction={referencedTransactions[0]}></TransactionDetails>
          </Grid>
          <Grid item xs={3}>
            <DetailsCard title={"Matched categories"} keyValuePairs={getMatchedCategoryKeyPairs()} />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {getRuleDetails()}
          <Grid item xs={12}>
            <MDBox mt={2}>
              <Grid container justifyContent={"center"}>
                <Grid item>
                  <MDBox m={1}>
                    <SingleAutocompleteCategorySelector categories={categories} selectedCategory={selectedCategory} selectedCategoryChanged={setSelectedCategory} />
                  </MDBox>
                </Grid>
                <Grid item>
                  <MDBox m={1}>
                    <MDButton
                      color="info"
                      disabled={selectedCategory == undefined}
                      onClick={(ev) => {
                        apiCalls.createNewSpecificRuleForTransaction(conflict.transaction_id, selectedCategory.id);
                        onClose();
                      }}
                    >
                      Assign Category
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </CloseableCard>
    </MDBox>
  );
}

export default ConflictDetails;
