import Grid from "@mui/material/Grid";
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";
import { FormControlLabel, Checkbox, IconButton, Icon } from "@mui/material";
import { ConditionTile } from "./ConditionTile";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useState } from "react";

export function StringCondition({ condition, onConditionChanged, onDelete, index }) {
  const notModifier = condition.invert && (
    <MDTypography variant="caption" fontWeight="bold">
      NOT
    </MDTypography>
  );

  const [showAdvanced, setShowAdvanced] = useState(false);
  const matchingWord = condition.exact ? "equal to" : "present in";
  const searchLocations = [];
  if (condition.counter_part) {
    searchLocations.push("counterpart name");
  }
  if (condition.comments) {
    searchLocations.push("comments");
  }
  if (condition.description) {
    searchLocations.push("description");
  }
  if (condition.account) {
    searchLocations.push("account");
  }

  const query = condition?.text || "";

  var iconForButton = "";
  if (!showAdvanced) {
    iconForButton = "expand_less";
  } else {
    iconForButton = "expand_more";
  }

  return (
    <ConditionTile title="Text Filter" onDelete={onDelete} condition={condition} onConditionChanged={onConditionChanged} index={index}>
      <Grid container justifyContent={"space-evenly"}>
        <Grid item xs={12}>
          <MDBox mb={1}>
            <MDTypography variant="caption">
              Matches all transactions where the text "{query}" is {notModifier} {matchingWord} {searchLocations.join(" or ")}
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid item xs={12}>
          <div className={"SearchText_" + index}>
            <FormField
              type="text"
              label="Filter text"
              value={query}
              onChange={(ev) => {
                const copiedCondition = Object.assign({}, condition);
                copiedCondition.text = ev.target.value;
                onConditionChanged(copiedCondition);
              }}
            />
          </div>
        </Grid>

        <Grid item xs={12}>
          <MDButton startIcon={<Icon>{iconForButton}</Icon>} onClick={(ev) => setShowAdvanced(!showAdvanced)}>
            <MDTypography variant="caption">Advanced</MDTypography>
          </MDButton>
        </Grid>
        {showAdvanced && (
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={condition?.counter_part || false}
                  onChange={(ev) => {
                    const copiedCondition = Object.assign({}, condition);
                    copiedCondition.counter_part = ev.target.checked;
                    onConditionChanged(copiedCondition);
                  }}
                />
              }
              label="Counterpartname"
            />
          </Grid>
        )}

        {showAdvanced && (
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={condition?.description || false}
                  onChange={(ev) => {
                    const copiedCondition = Object.assign({}, condition);
                    copiedCondition.description = ev.target.checked;
                    onConditionChanged(copiedCondition);
                  }}
                />
              }
              label="Description"
            />
          </Grid>
        )}
        {showAdvanced && (
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={condition?.comments || false}
                  onChange={(ev) => {
                    const copiedCondition = Object.assign({}, condition);
                    copiedCondition.comments = ev.target.checked;
                    onConditionChanged(copiedCondition);
                  }}
                />
              }
              label="Comments"
            />
          </Grid>
        )}

        {showAdvanced && (
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={condition?.account || false}
                  onChange={(ev) => {
                    const copiedCondition = Object.assign({}, condition);
                    copiedCondition.account = ev.target.checked;
                    onConditionChanged(copiedCondition);
                  }}
                />
              }
              label="Account"
            />
          </Grid>
        )}

        {showAdvanced && (
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={condition?.exact || false}
                  onChange={(ev) => {
                    const copiedCondition = Object.assign({}, condition);
                    copiedCondition.exact = ev.target.checked;
                    onConditionChanged(copiedCondition);
                  }}
                />
              }
              label="Exact Match"
            />
          </Grid>
        )}
      </Grid>
    </ConditionTile>
  );
}
