import { Grid, Icon, Stack, Switch, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { CloseableCard } from "components/TitledCard/GenericCard";
import { useState } from "react";
import { DateTime } from "luxon";
import { Transaction } from "fdc_expenses";
import { DatePicker } from "@mui/x-date-pickers";
import ReactJoyride from "react-joyride";
import { stopJoyRideWhenFinished, demoCallbacks } from "utils/JoyRideUtils";
import FDDemo from "utils/FDDemo";

const steps = [
  {
    target: ".CreateNewManualTransaction",
    content: <MDTypography variant="button">This page allows you to manually define transactions that will be added to your Budgex account</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".RawAmount",
    content: <MDTypography variant="button">Here you must define the amount of the transaction in EUR</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".TransactionType",
    content: <MDTypography variant="button">Here you must select whether this was an expense or an income</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".SignedAmount",
    content: <MDTypography variant="button">This shows you how the amount in the Budgex typical sign convention</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".Date",
    content: <MDTypography variant="button">Set the transaction date here</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".CounterpartName",
    content: <MDTypography variant="button">Set the name of the counterpart here</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".CreateButton",
    content: <MDTypography variant="button">Click here to save the transaction to Budgex</MDTypography>,
    disableBeacon: true,
  },
];

function dateToStartOfUTCDay(date) {
  return DateTime.utc(date.year, date.month, date.day, 0, 0, 0);
}

export function CreateManualTransactionPopup({ apiCalls, onClose, externalDemo }) {
  const today = DateTime.now();
  const [amount, setAmount] = useState(undefined);
  const [isExpense, setIsExpense] = useState(true);

  const [counterpartName, setCounterpartName] = useState();
  const [counterpartAccount, setCounterpartAccount] = useState();
  const [comments, setComments] = useState();
  const [description, setDescription] = useState();
  const [date, setDate] = useState(today);
  const [showDemo, setShowDemo] = useState(externalDemo || false);

  const signedAmount = isExpense ? -1 * amount : amount;

  function createTransaction() {
    const t = new Transaction();
    t.timestamp = dateToStartOfUTCDay(date);
    t.amount = signedAmount;
    t.account = "Manual";
    t.description = description;
    t.comments = comments;
    t.counter_part_name = counterpartName;
    t.counter_part_account = counterpartAccount;
    t.transaction_id_from_bank = crypto.randomUUID();

    apiCalls.createNewManualTransaction(t);
    onClose();
  }

  const sufficientInformation = date != undefined && counterpartName != undefined && comments != undefined;

  return (
    <div class="CreateNewManualTransaction">
      <CloseableCard onClose={onClose} onHelp={() => setShowDemo(true)}>
        <MDBox m={2}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <MDBox m={0.5} mb={3}>
                  <MDTypography>Create a manual transaction</MDTypography>
                </MDBox>
              </Grid>
            </Grid>
            <Grid item xs={12} xl={4}>
              <div class="RawAmount">
                <MDBox m={0.5}>
                  <TextField fullWidth label="Amount" type="number" value={Math.abs(amount)} onChange={(ev) => setAmount(Math.abs(ev.target.value))} required />
                </MDBox>
              </div>
            </Grid>

            <Grid item xs={12} xl={4}>
              <div class="SignedAmount">
                <MDBox m={0.5}>
                  <TextField fullWidth disabled={true} label="Amount with Budgex sign convention" type="number" value={signedAmount} required />
                </MDBox>
              </div>
            </Grid>
            <Grid item xs={12} xl={4}>
              <Grid container justifyContent="center">
                <div class="TransactionType">
                  <MDBox m={0.5}>
                    <Stack direction="row" alignItems="center">
                      <MDTypography variant="caption" style={{ color: "red" }}>
                        Expense
                      </MDTypography>
                      <Switch checked={!isExpense} onChange={(ev) => setIsExpense(!ev.target.checked)} />
                      <MDTypography variant="caption" style={{ color: "green" }}>
                        Income
                      </MDTypography>
                    </Stack>
                  </MDBox>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12} xl={4}>
              <div class="Date">
                <MDBox m={0.5}>
                  <DatePicker value={date} onChange={setDate} label="Date" required renderInput={(params) => <TextField {...params} fullWidth />}></DatePicker>
                </MDBox>
              </div>
            </Grid>
            <Grid item xs={12} xl={4}>
              <div class="CounterpartName">
                <MDBox m={0.5}>
                  <TextField fullWidth label="Counterpart Name" value={counterpartName} onChange={(ev) => setCounterpartName(ev.target.value)} required />
                </MDBox>
              </div>
            </Grid>
            <Grid item xs={12} xl={4}>
              <div>
                <MDBox m={0.5}>
                  <TextField fullWidth label="Counterpart Account" value={counterpartAccount} onChange={(ev) => setCounterpartAccount(ev.target.value)} />
                </MDBox>
              </div>
            </Grid>
            <Grid item xs={12} xl={4}>
              <div class="Comments">
                <MDBox m={0.5}>
                  <TextField fullWidth label="Comments" value={comments} onChange={(ev) => setComments(ev.target.value)} required />
                </MDBox>
              </div>
            </Grid>
            <Grid item xs={12} xl={4}>
              <div>
                <MDBox m={0.5}>
                  <TextField fullWidth label="Description" value={description} onChange={(ev) => setDescription(ev.target.value)} />
                </MDBox>
              </div>
            </Grid>
            <Grid item xs={12}>
              <MDBox m={2}>
                <Grid container justifyContent={"center"}>
                  <div class="CreateButton">
                    <MDButton color="secondary" endIcon={<Icon>add</Icon>} disabled={!sufficientInformation} onClick={createTransaction}>
                      Create
                    </MDButton>
                  </div>
                </Grid>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

        <FDDemo
          run={showDemo}
          steps={steps}
          onStop={() => {
            setShowDemo(false);
            // If we are triggered, because of an external demo, close this screen
            if (externalDemo) {
              onClose();
            }
          }}
        />
      </CloseableCard>
    </div>
  );
}
