import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import React from "react";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function TitledCardWithCloseButton({ title, children, onClose }) {
  return (
    <Card>
      <MDBox width="100%" display="flex" flexDirection="column" lineHeight={1}>
        <MDBox mb={2} mr={1} ml={1}>
          <Grid container display="flex" justifyContent={"space-between"} alignItems="center">
            <Grid item>
              <MDBox mt={2} ml={2}>
                {title}
              </MDBox>
            </Grid>
            <Grid item>
              <MDBox mt={2}>
                <IconButton onClick={(ev) => onClose()}>
                  <CloseIcon />
                </IconButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        {children}
      </MDBox>
    </Card>
  );
}

export default TitledCardWithCloseButton;
