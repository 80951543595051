import { Card, Dialog, Grid, Icon, IconButton } from "@mui/material";
import TitledCard from "components/TitledCard/titled_card";
import { useEffect, useMemo, useState } from "react";
import SettingsIcon from "@mui/icons-material/Settings";

const FdcExpenses = require("fdc_expenses");
import { TimeseriesAggregation } from "fdc_expenses";
import MultiCategorySelector from "layouts/pages/classificationflow/multicategoryselector";

import { Line } from "react-chartjs-2";
import MDBox from "components/MDBox";
import CardWithIcon from "components/TitledCard/CardWithIcon";

import MDButton from "components/MDButton";
import { TrendGraphConfigurator } from "./TrendGraphConfigurator";
import TrendGraph from "./TrendGraph";

function generateGraphData() {}

function TrendPageV2({ apiClient, categories, dailyTimeseriesData }) {
  function generateDefaultGraphItems() {
    const expensesCategories = categories.filter((exp) => exp.name == "Expenses");
    if (expensesCategories.length > 0) {
      const expId = expensesCategories[0].id;
      return [
        {
          label: "All Expenses",
          categoryIds: [expId],
          pure: false,
          color: "#993344",
          invert: true,
        },
        {
          label: "Other Expenses",
          categoryIds: [expId],
          pure: true,
          color: "#111111",
          invert: true,
        },
      ];
    } else {
      return [];
    }
  }

  function generateDefaultConfiguration() {
    return { graphItems: generateDefaultGraphItems(), averagingDays: 180 };
  }

  const [settingsOpen, setSettingsOpen] = useState(false);

  const [configuration, setConfiguration] = useState(generateDefaultConfiguration());
  const [configurationForConfigurator, setConfigurationForConfigurator] = useState(
    generateDefaultConfiguration()
  );

  return (
    <MDBox>
      <CardWithIcon
        title="Trend"
        icon={{ component: "show_chart" }}
        description={"Description"}
        topright={
          <MDBox>
            <MDButton
              startIcon={<Icon>settings</Icon>}
              onClick={(ev) => {
                setConfigurationForConfigurator(configuration);
                setSettingsOpen(true);
              }}
            >
              Configure Graph
            </MDButton>
          </MDBox>
        }
      >
        <Grid container>
          <Grid item xs={12}>
            <MDBox sx={"19.25rem"}>
              <TrendGraph dailyTimeseriesData={dailyTimeseriesData} configuration={configuration} />
            </MDBox>
          </Grid>
        </Grid>
      </CardWithIcon>
      <Dialog open={settingsOpen} fullWidth={true} maxWidth={"xl"}>
        <TrendGraphConfigurator
          categories={categories}
          configuration={configurationForConfigurator}
          onConfigurationChange={setConfigurationForConfigurator}
          onOk={() => {
            setConfiguration(configurationForConfigurator);
            setSettingsOpen(false);
          }}
          onCancel={() => {
            setSettingsOpen(false);
          }}
        ></TrendGraphConfigurator>
      </Dialog>
    </MDBox>
  );
}

export default TrendPageV2;
