import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import MDBox from "components/MDBox";
import { useState } from "react";

function SortPopover({ sortValue, sortValueChange, reversed, reversedChange }) {
  return (
    <FormControl>
      <MDBox mb={3}>
        <FormLabel>Sort By</FormLabel>
      </MDBox>

      <RadioGroup value={sortValue} onChange={(ev) => sortValueChange(ev.target.value)}>
        <FormControlLabel value="timestamp" control={<Radio />} label="Date" />
        <FormControlLabel value="amount" control={<Radio />} label="Value" />
        <FormControlLabel value="counter_part_name" control={<Radio />} label="Counterpart" />
      </RadioGroup>
      <MDBox mt={5}>
        <FormControlLabel
          control={<Checkbox checked={reversed} onChange={(ev) => reversedChange(!reversed)} />}
          label="Highest first"
        />
      </MDBox>
    </FormControl>
  );
}

export default SortPopover;
