/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router components
import { useLocation, Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import { Dialog, Grid, LinearProgress, Tooltip } from "@mui/material";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDBadge from "components/MDBadge";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 PRO React examples
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

import LogoutIcon from "@mui/icons-material/Logout";

// Custom styles for DashboardNavbar
import { navbar, navbarContainer, navbarRow, navbarIconButton, navbarDesktopMenu, navbarMobileMenu } from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setTransparentNavbar, setMiniSidenav, setOpenConfigurator } from "context";

function DashboardNavbar({ absolute, light, isMini, onLogout, username, applicationData, helpCallback }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    setTransparentNavbar(dispatch, false);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => {
    setMiniSidenav(dispatch, !miniSidenav);
  };
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const isWorking =
    applicationData.isLoadingCatAssignment ||
    applicationData.isLoadingRules ||
    applicationData.isLoadingCategories ||
    applicationData.isRefreshingBankLink ||
    applicationData.isLoadingManualTransactions ||
    applicationData.isLoadingTransactions ||
    applicationData.isLoadingAnalysisV2;

  const navigate = useNavigate();

  const isDemoAccount = applicationData?.userDetails?.name == "Demo";

  return (
    <div class="NavBar">
      <AppBar position={absolute ? "absolute" : navbarType} color="inherit" sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}>
        <Toolbar sx={(theme) => navbarContainer(theme)}>
          <Grid container justifyContent={"space-between"} alignItems="center">
            <Grid item>
              <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
                <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
              </MDBox>
            </Grid>

            {isWorking && <CircularProgress color="inherit" size={20} />}

            {isMini ? null : (
              <Grid item>
                <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
                  <MDBox color={light ? "white" : "inherit"}>
                    {isDemoAccount && (
                      <Tooltip title="Demo Account (Read-Only)">
                        <MDBadge variant="gradient" color="success" size="md" badgeContent={"Demo Account (Read-Only)"} container />
                      </Tooltip>
                    )}
                    {!isDemoAccount && applicationData.proAccount && (
                      <Tooltip title={"Pro account until " + applicationData?.proEndDate?.toDateString() || ""}>
                        <MDBadge variant="gradient" size="md" badgeContent={"Premium"} container />
                      </Tooltip>
                    )}
                    {!isDemoAccount && !applicationData.proAccount && (
                      <Tooltip title="Click to upgrade to pro">
                        <MDBadge
                          variant="gradient"
                          color="success"
                          size="md"
                          badgeContent={"Upgrade to Budgex Premium"}
                          container
                          onClick={(ev) => navigate({ pathname: "/account/subscriptions?showProducts" })}
                        />
                      </Tooltip>
                    )}

                    {helpCallback != undefined && (
                      <IconButton size="small" disableRipple color="inherit" onClick={() => helpCallback()}>
                        <Icon sx={iconsStyle} fontSize="medium">
                          help
                        </Icon>
                      </IconButton>
                    )}

                    <IconButton size="small" disableRipple color="inherit" sx={navbarMobileMenu} onClick={handleMiniSidenav}>
                      <div class="MenuOpenButton">
                        <Icon sx={iconsStyle} fontSize="medium">
                          {miniSidenav ? "menu_open" : "menu"}
                        </Icon>
                      </div>
                    </IconButton>

                    <IconButton size="small" disableRipple color="inherit" sx={navbarIconButton} aria-controls="notification-menu" aria-haspopup="true" variant="contained" onClick={onLogout}>
                      <LogoutIcon />
                    </IconButton>
                  </MDBox>
                </MDBox>
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
