/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import React, { useEffect, useMemo, useState } from "react";

import CategoryTreeV3 from "./categorytree_v3";
import CategoryTreeV4 from "./categorytree_v4";
import RichCategories from "domain/RichCategories";

import { Dialog, Tab, Tabs, TabPanel, Stack, Icon } from "@mui/material";
import CreateNewCategoryCard from "./CreateNewCategoryCard";
import SelectNewParentCard from "./SelectNewParentCard";

import RenameCategoryCard, { RenameNewCategoryCard } from "./RenameCategoryCard";

import { useLocation } from "react-router-dom";
import FDDemo from "utils/FDDemo";
import CategoryTree from "./categorytree";
import { CloseableCard } from "components/TitledCard/GenericCard";
import MDButton from "components/MDButton";

// import CategoryTreeV7 from "./categorytree_v7";

const FdcExpenses = require("fdc_expenses");

const steps = [
  {
    target: ".NavBar",
    title: "Category management",
    content: (
      <Stack>
        <MDTypography variant="button">This page allows you to manage your categories.</MDTypography>
        <MDTypography variant="button">Each transaction will always have a category assigned to it, based on classification rules</MDTypography>
        <MDTypography variant="button">If no rule applies to a given transaction, it will get a default category (Expense or Income based on the sign of the transaction).</MDTypography>

        <MDTypography variant="button">If multiple rules apply and they are compatible, the most precise (deepest in the category tree) will be assigned.</MDTypography>
        <MDTypography variant="button">If multiple rules apply, and they are conflicting, it will show up in your conflicts for you to resolve</MDTypography>
      </Stack>
    ),
    disableBeacon: true,
  },
  {
    target: ".CategoryList_Expenses",
    content: <MDTypography variant="button">This card shows the categories for your expenses. You can create as many (sub)-categories as you like</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".Expenses_Add",
    content: <MDTypography variant="button">Using this button, you can create a new sub-category of "Expenses"</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".Groceries_Edit",
    content: <MDTypography variant="button">Using this button, you can modify the Groceries category. It allows you to either rename it, or put it at a different position in the tree</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".Tree",
    content: <MDTypography variant="button">This is a visual representation of your categories</MDTypography>,
    disableBeacon: true,
  },
];

function CategoryManagement({ categories, apiClient, callbacks, apiCalls, applicationData }) {
  const [showDemo, setShowDemo] = useState(false);
  const [selectedParent, setSelectedParent] = useState("ROOT");

  const [dialogContent, setDialogContent] = useState("");

  const [treeContainer, setTreeContainer] = useState();

  const [treeWidth, setTreeWidth] = useState(100);
  const [treeHeight, setTreeHeight] = useState(100);

  const [showTreeDialog, setShowTreeDialog] = useState(false);

  const richCats = new RichCategories(categories);

  const location = useLocation();
  useEffect(() => {
    apiCalls.setHelpCallback(() => () => setShowDemo(true));
    if (location.search.includes("showHelp")) {
      setShowDemo(true);
    }
  }, [location]);

  function getDialogContent() {
    if (dialogContent == "New") {
      return <CreateNewCategoryCard allowRoot={false} categories={categories} apiCalls={apiCalls} onClose={() => setDialogContent("")} defaultParent={selectedParent} />;
    } else if (dialogContent == "Move") {
      return <SelectNewParentCard categories={categories} apiCalls={apiCalls} onClose={() => setDialogContent("")} categoryToChange={selectedParent} />;
    }
  }

  function adaptTranslationOnTreeContainer() {
    if (treeContainer) {
      const boundRect = treeContainer.getBoundingClientRect();

      setTreeWidth(boundRect.width);
      setTreeHeight(boundRect.height);
    }
  }

  const renderedLists = useMemo(() => {
    return richCats.getRootCategories().map((rootCat, index) => {
      const allChildren = richCats.getAllRecursiveChildrenOf(rootCat);
      allChildren.push(rootCat);

      return (
        <Grid item xs={12} md={6} xl={4} key={"List_" + index}>
          <div class={"CategoryList_" + rootCat.name.replaceAll(" ", "_")}>
            <MDBox m={1}>
              <Card>
                <MDBox m={1}>
                  <Grid container>
                    <Grid item xs={12}>
                      <MDBox ml={1}>
                        <MDTypography variant="button" fontWeight="medium">
                          {rootCat.name}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12}>
                      <CategoryTreeV4
                        categories={allChildren}
                        apiCalls={apiCalls}
                        onCreateNewCategoryFor={(category) => {
                          setSelectedParent(category);
                          setDialogContent("New");
                        }}
                        onStartModifyingCategory={(category) => {
                          setSelectedParent(category);
                          setDialogContent("Move");
                        }}
                      />
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </MDBox>
          </div>
        </Grid>
      );
    });
  }, [categories]);

  useEffect(() => adaptTranslationOnTreeContainer(), [treeContainer]);

  return (
    <div class="CategoryManagementPage">
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} xl={12}>
              <MDBox mb={3} mt={3}>
                <Grid container justifyContent={"stretch"}>
                  <MDButton
                    variant={"contained"}
                    color="dark"
                    fullWidth
                    onClick={(ev) => {
                      setShowTreeDialog(true);
                    }}
                    endIcon={<Icon>account_tree</Icon>}
                  >
                    Visualise Category Tree
                  </MDButton>
                </Grid>
              </MDBox>
            </Grid>
            {renderedLists}
            {/* <Grid item xs={12} xl={12}>
              <MDBox m={2}>
                <div class="Tree">
                  <Card>
                    <MDBox m={2} ref={(tc) => setTreeContainer(tc)}>
                      <CategoryTreeV3 categories={categories} treeWidth={applicationData.windowWidth * 0.9} treeHeight={applicationData.windowHeight * 0.9}></CategoryTreeV3>
                    </MDBox>
                  </Card>
                </div>
              </MDBox>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
      <Dialog key={"showTreeDialog"} open={showTreeDialog} fullWidth maxWidth={"xl"}>
        <CloseableCard onClose={() => setShowTreeDialog(false)}>
          <MDBox m={2}>
            <Card sx={{ backgroundColor: "#F2F2F2" }}>
              <div style={{ height: applicationData.windowHeight - 50 }}>
                <CategoryTree categories={applicationData.categories} applicationData={applicationData} />
              </div>
            </Card>
          </MDBox>
        </CloseableCard>
      </Dialog>
      <Dialog open={dialogContent != ""}>{getDialogContent()}</Dialog>
      <FDDemo steps={steps} run={showDemo} onStop={() => setShowDemo(false)} />
    </div>
  );
}

export default CategoryManagement;
