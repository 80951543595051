import { Card, Grid, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDEditor from "components/MDEditor";
import MDTypography from "components/MDTypography";
import { useState } from "react";

export function ReportAProblem({ apiCalls, requisitionId, onProblemSubmit }) {
  const [title, setTitle] = useState("");
  const [editorValue, setEditorValue] = useState("<p>Describe your problem here<p>");

  return (
    <Card>
      <MDBox m={1}>
        <Grid container>
          <Grid item xs={12}>
            <MDBox m={1}>
              <TextField value={title} onChange={(ev) => setTitle(ev.target.value)} label={"Title"} fullWidth />
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <MDBox m={1}>
              <MDEditor value={editorValue} onChange={setEditorValue} />
            </MDBox>
          </Grid>
          <Grid item xs={12} justifyContent="center">
            <MDBox m={1} display="flex" justifyContent="center">
              <MDButton
                onClick={() => {
                  apiCalls.reportAProblem(title, editorValue, requisitionId);
                  if (onProblemSubmit) {
                    onProblemSubmit();
                  }
                }}
                variant="outlined"
                color="text"
              >
                Submit Problem
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}
