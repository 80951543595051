import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useMemo, useState } from "react";
import MDInput from "components/MDInput";

import TitledCardWithCloseButton from "./TitledCardWithCloseButton";
import MDButton from "components/MDButton";
import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Category } from "fdc_expenses";
import { FormControl, Grid, InputLabel } from "@mui/material";
import SingleAutocompleteCategorySelector from "layouts/pages/classificationflow/singleautocompletecategoryselector";

export function CreateNewCategoryCard({ categories, onClose, apiCalls, defaultParent, allowRoot }) {
  const [newCategoryName, setNewCategoryName] = useState("");
  const [selectedParent, setSelectedParent] = useState(defaultParent || null);

  function createCategory() {
    var parent_id = undefined;
    if (selectedParent != null) {
      parent_id = selectedParent.id;
    }

    const newCat = new Category();
    newCat.name = newCategoryName;
    newCat.parent_id = parent_id;

    apiCalls.createCategory(newCat, onClose);
  }

  return (
    <TitledCardWithCloseButton
      title={
        <MDTypography variant="h6" fontWeight="medium">
          Create new category
        </MDTypography>
      }
      onClose={() => onClose()}
    >
      <MDBox m={2}>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <MDBox display="flex" justifyContent="center" m={1} mb={2}>
              <FormControl fullWidth>
                <MDInput variant="standard" label={"New Category Name"} fullWidth value={newCategoryName} onChange={(ev) => setNewCategoryName(ev.target.value)} />
              </FormControl>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <MDBox mt={3} display="flex" justifyContent="center">
              <MDTypography variant="h6">Select Category Parent</MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <MDBox display="flex" justifyContent="center" m={1} mb={2}>
              <FormControl fullWidth>
                <SingleAutocompleteCategorySelector
                  categories={categories}
                  label={"Select Category Parent"}
                  selectedCategory={selectedParent}
                  selectedCategoryChanged={(selectedCategory, selectedLabel) => {
                    if (selectedCategory) {
                      setSelectedParent(selectedCategory);
                    } else {
                      setSelectedParent(null);
                    }
                  }}
                />
              </FormControl>
            </MDBox>
          </Grid>

          <Grid item xs={12}>
            <MDBox mt={3}>
              <Grid container justifyContent={"center"}>
                <Grid item>
                  <MDBox m={0}>
                    <MDButton onClick={(ev) => createCategory()} endIcon={<AddCircleIcon />} disabled={(!allowRoot && selectedParent == undefined) || newCategoryName.length < 3}>
                      Create
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </TitledCardWithCloseButton>
  );
}

export default CreateNewCategoryCard;
