import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import AddCardIcon from "@mui/icons-material/AddCard";
import { Grid, IconButton } from "@mui/material";

function DetailsCard({ title, keyValuePairs, classNameSuffix }) {
  function generateKeyValyeItems() {
    const componentsToAdd = [];

    for (var key in keyValuePairs) {
      componentsToAdd.push(
        <Grid container alignItems="center" gap={0.5} key={"MDBox_" + key}>
          <MDBox className={key.replaceAll(" ", "_") + "_" + classNameSuffix}>
            <MDTypography variant="caption" fontWeight="regular" color="text">
              {key}:&nbsp;&nbsp;&nbsp;
              <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                {keyValuePairs[key]}
              </MDTypography>
            </MDTypography>
          </MDBox>
        </Grid>
      );
    }

    return componentsToAdd;
  }

  return (
    <MDBox width="100%" display="flex" flexDirection="column" lineHeight={1} ml={2}>
      <MDBox mb={2} mt={2}>
        <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
          {title || ""}
        </MDTypography>
      </MDBox>
      {generateKeyValyeItems()}
    </MDBox>
  );
}

export default DetailsCard;
