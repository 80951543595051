import { getColorForCategoryName } from "utils/color_util";
import { getColorFor } from "utils/color_util";

class RichCategories {
  constructor(categories) {
    this.categories = categories;
  }

  isAnExpense(categoryId) {
    const cat = this.getCategoryById(categoryId);

    if (cat) {
      if (cat.name == "Expenses") {
        return true;
      } else {
        if (cat.parent_id == null) {
          return false;
        } else {
          return this.isAnExpense(cat.parent_id);
        }
      }
    } else {
      return false;
    }
  }

  getRootCategories() {
    return this.categories.filter((cat) => !cat.parent_id);
  }

  getAllChildrenOf(parentCategory) {
    return this.categories.filter((cat) => cat.parent_id == parentCategory.id);
  }

  getAllRecursiveChildrenOf(parentCategory) {
    return this.getAllChildrenOf(parentCategory).flatMap((cat) => {
      const allCats = [cat];
      const allChildren = this.getAllRecursiveChildrenOf(cat);

      const combined = allCats.concat(allChildren);
      return combined;
    });
  }

  getCategoryById(categoryId) {
    const relevant = this.categories.filter((cat) => cat.id == categoryId);

    if (relevant.length == 1) {
      return relevant[0];
    } else {
      return undefined;
    }
  }

  getCategoryByName(categoryName) {
    const relevant = this.categories.filter((cat) => cat.name == categoryName);
    if (relevant.length == 1) {
      return relevant[0];
    } else {
      return undefined;
    }
  }

  categoryHasChildren(category) {
    return this.categoryIdHasChildren(category.id);
  }

  categoryIdHasChildren(categoryId) {
    return this.categories.filter((cat) => cat.parent_id == categoryId).length > 0;
  }

  getColorForCategoryId(categoryId) {
    const categoryById = this.getCategoryById(categoryId);
    console.log(categoryId, categoryById);
    return this.getColorForCategory(categoryById);
  }
  getColorForCategory(category) {
    return getColorForCategoryName(category.name);
  }
}

export default RichCategories;
