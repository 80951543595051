import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import MDButton from "components/MDButton";

const { default: TitledCard } = require("components/TitledCard/titled_card");
import { ZoomIn } from "@mui/icons-material";

function ConflictTable(props) {
  function getCategoriesForRules(matched_rule_ids) {
    const categoryIds = props.rules
      .filter((rule) => matched_rule_ids.includes(rule.id))
      .map((rule) => rule.categoryId);
    return props.categories
      .filter((cat) => categoryIds.includes(cat.id))
      .map((cat) => cat.name)
      .join(", ");
  }

  function getRulesInTableForm() {
    const createRow = function (rs) {
      return {
        transaction_id: rs.transaction_id,
        rules: rs.matched_rules.join(", "),
        categories: getCategoriesForRules(rs.matched_rules),
        details: rs,
      };
    };

    var rows = [];
    if (props.analysisResult) {
      rows = props.analysisResult.classification_conflicts.map(createRow);
    }

    const tableData = {
      columns: [
        {
          Header: "See details",
          accessor: "details",
          Cell: ({ value }) => {
            return (
              <MDButton onClick={(ev) => props.onSelectedConflict(value)}>
                <ZoomIn />
              </MDButton>
            );
          },
        },
        { Header: "Transaction ID", accessor: "transaction_id" },
        { Header: "Conflicting Rules", accessor: "rules" },
        { Header: "Referenced Categories", accessor: "categories" },
      ],
      rows: rows,
    };

    return tableData;
  }

  return <DataTable table={getRulesInTableForm()} />;
}

export default ConflictTable;
