import { getColorByIndex } from "utils/color_util";

function getIndexForDate(dateToFind, timeSeriesData) {
  return timeSeriesData.times.filter((time) => time <= dateToFind).length;
}

function sortTwoCombinedLists(list1, list2) {
  const combinedList = [];

  for (var j = 0; j < list1.length; j++) {
    combinedList.push({ item1: list1[j], item2: list2[j] });
  }

  combinedList.sort(function (a, b) {
    return Math.abs(a.item1) < Math.abs(b.item1) ? 1 : -1;
  });

  const splittedList1 = combinedList.map((item) => item.item1);
  const splittedList2 = combinedList.map((item) => item.item2);

  return [splittedList1, splittedList2];
}

function generateBarChartData(graphConfiguration, timeSeriesData) {
  if (timeSeriesData) {
    const timeRangeLabels = graphConfiguration.graphItems.map((gi) => gi.label);
    const dataSets = graphConfiguration.timeRanges.map((timeRange, trIndex) => {
      const dataForTimeRange = graphConfiguration.graphItems

        .map((graphItem) => {
          const allRelevantTimeSeries = timeSeriesData.timeseries.filter((ts) => graphItem.categoryIds.includes(ts.category.id));

          if (allRelevantTimeSeries.length > 0) {
            const startIndex = getIndexForDate(timeRange.start, timeSeriesData);
            const endIndex = getIndexForDate(timeRange.end, timeSeriesData);

            const relevantTimeSeries = allRelevantTimeSeries
              .map((ts) => {
                if (graphItem.pure) {
                  return ts.pure_values;
                } else {
                  return ts.values;
                }
              })
              .map((ts) =>
                ts.slice(startIndex, endIndex).map((value) => {
                  if (graphItem.invert) {
                    return value * -1;
                  } else {
                    return value;
                  }
                })
              );

            const totalTs = [];
            for (var i = 0; i < relevantTimeSeries[0].length; i++) {
              const totalValue = relevantTimeSeries.map((ts) => ts[i]).reduce((a, b) => a + b, 0);
              totalTs.push(totalValue);
            }

            return totalTs;
          } else {
            // If no data is found, fill out with zero
            return [0];
          }
        })
        .map((dataItems) => dataItems.reduce((a, b) => a + b, 0));

      return {
        label: timeRange.label,
        data: dataForTimeRange,
        backgroundColor: getColorByIndex(trIndex * 7),
      };
    });
    const nonSortedData = {
      labels: timeRangeLabels,
      datasets: dataSets,
    };
    if (dataSets.length == 1) {
      const [sortedDatasets, sortedLabels] = sortTwoCombinedLists(dataSets[0].data, timeRangeLabels);
      const sortedData = {
        labels: sortedLabels,
        datasets: [{ data: sortedDatasets, label: dataSets[0].label, backgroundColor: dataSets[0].backgroundColor }],
      };
      // Data is correctly sorted, but the interactivity feature is broken with this
      return nonSortedData;
    } else {
      return nonSortedData;
    }
  } else {
    return {
      labels: [],
      datasets: [],
    };
  }
}

function generateBarChartDataV2(graphConfiguration, timeSeriesData) {
  if (timeSeriesData) {
    const timeRangeLabels = graphConfiguration.timeRanges.map((timeRange) => timeRange.label);
    const dataSets = graphConfiguration.graphItems
      .filter((graphItem) => timeSeriesData.timeseries.filter((ts) => graphItem.categoryIds.includes(ts.category.id)).length > 0)
      .map((graphItem) => {
        const relevantTimeSeries = timeSeriesData.timeseries.filter((ts) => graphItem.categoryIds.includes(ts.category.id));

        const dataForGraphItem = graphConfiguration.timeRanges
          .map((timeRange) => {
            // TODO: Here the correct indices must be found for this time range
            const startIndex = getIndexForDate(timeRange.start, timeSeriesData);
            const endIndex = getIndexForDate(timeRange.end, timeSeriesData);

            const selectPartsOfTimeseries = relevantTimeSeries.map((ts) => {
              if (graphItem.pure) {
                return ts.pure_values.slice(startIndex, endIndex);
              } else {
                return ts.values.slice(startIndex, endIndex);
              }
            });

            const totalTs = [];
            for (var i = 0; i < selectPartsOfTimeseries[0].length; i++) {
              const totalValue = selectPartsOfTimeseries.map((ts) => ts[i]).reduce((a, b) => a + b, 0);
              totalTs.push(totalValue);
            }

            return totalTs;
          })
          .map((dataItems) => dataItems.reduce((a, b) => a + b, 0))
          .map((v) => {
            if (graphItem.invert) {
              return v * -1;
            } else {
              return v;
            }
          });

        return {
          label: graphItem.label,
          data: dataForGraphItem,
          backgroundColor: graphItem.color,
        };
      });

    return {
      labels: timeRangeLabels,
      datasets: dataSets,
    };
  } else {
    return {
      labels: [],
      datasets: [],
    };
  }
}

export { generateBarChartData, generateBarChartDataV2 };
