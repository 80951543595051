import { ConstructionOutlined, KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Card, Dialog, Grid, MobileStepper, Step, StepButton, StepLabel, Stepper } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { CombinedTimeRangesDefintion } from "domain/graphconfiguration/TimeRangesDefinition";
import { useMemo, useState } from "react";
import FormField from "../account/components/FormField";
import { GraphTypeSelector } from "./GraphTypeSelector";
import { MultiTimeRangeSelector, SingleTimeRangeSelector } from "./TimeRangeSelectors";

import { generateGraph } from "domain/graphs/GraphGenerator";
import { GraphItemsSelector } from "./GraphItemsSelector";
import { GraphItemsSelectorV2 } from "./GraphItemsSelectorV2";
import { TimeRangesSelectorV2 } from "./TimeRangeSelectorV2";
import { serializeGraphTypeDefinition, parseGraphTypeDefinition } from "domain/graphconfiguration/GraphTypeDefinition";
import { serializeGraphItemDefinition } from "domain/graphconfiguration/GraphItemsDefinition";
import { parseGraphItemDefinition } from "domain/graphconfiguration/GraphItemsDefinition";
import RichCategories from "domain/RichCategories";
import { serializeTimeRangesDefinition } from "domain/graphconfiguration/TimeRangesDefinition";
import { parseTimeRangesDefinition } from "domain/graphconfiguration/TimeRangesDefinition";
import MDInput from "components/MDInput";
import { GraphDefinition } from "fdc_expenses";
import { TransactionList } from "../expensestable/TransactionListPage";
import { CloseableCard } from "components/TitledCard/GenericCard";
import FDDemo from "utils/FDDemo";

function createDemoSteps(progressSetter, setGraphTypeSelectedKey, setTimeRangesSelectedKey, setGraphItemSelectedKey) {
  const steps = [
    {
      target: ".GraphBuilderContent",
      title: "Select graph type",
      content: "First, you need to select the graph type that you want to build. ",
      disableBeacon: true,
    },
    {
      target: ".Option_BarTrendGraphDefinition",
      content: "Lets start with creating a plot showing our expenses composition on a monthly basis",
      disableBeacon: true,
      afterAction: () => setGraphTypeSelectedKey("BarTrendGraphDefinition"),
    },
    {
      target: ".NextButton",
      title: "Click Next",
      content: "When we have made our selection, we click next to continue the Graph Building Wizard",
      disableBeacon: true,
      afterAction: () => {
        progressSetter(1);
      },
    },
    {
      target: ".GraphBuilderContent",
      title: "Select time range",
      content: "Then we can select which timerange we want to visualise in our Graph",
      disableBeacon: true,
      afterAction: () => setTimeRangesSelectedKey("MonthlyTimeRanges"),
    },
    {
      target: ".Option_MonthlyTimeRanges",
      title: "Last 6 months only",
      content: "Lets select only the last 6 months",
      disableBeacon: true,
      afterAction: () => progressSetter(2),
    },
    {
      target: ".GraphBuilderContent",
      title: "Select categories",
      content: "Finally, we can select which categories we want to visualise in our graph.",
      disableBeacon: true,
    },
    {
      target: ".Option_GraphItemsForCategories",
      title: "Custom Selection Progress",
      content: "Using this option, you can make a custom selection of categories for your graph",
      disableBeacon: true,
    },
    {
      target: ".Option_DistributionOf",
      title: "Composition of category",
      content: "Using this option, you can visualise all the sub-categories of a specific category. Especially useful to create PieCharts",
      disableBeacon: true,
    },
    {
      target: ".Option_ClassificationProgressOf",
      title: "Classification Progress",
      content:
        "Lets select the classification progress for the 'Expenses' category. This shows us how much of our 'Expenses' have been assigned a more specific category and how much of our 'Expenses' are still to be categorised further",
      disableBeacon: true,
      afterAction: () => {
        setGraphItemSelectedKey("ClassificationProgressOf");
      },
    },
    {
      target: ".NextButton",
      title: "Click Next",
      content: "When we have made our selection, we click next to continue the Graph Building Wizard",
      disableBeacon: true,
      afterAction: () => {
        progressSetter(3);
      },
    },
    {
      target: ".GraphBuilderContent",
      title: "Review and save",
      content: "When you have configured your graph, you get a preview of what it looks like. If you like it, you can give it a name and save it to your personal dashboard",
      disableBeacon: true,
    },
  ];

  return steps;
}

function GraphBuilder({ analysisResult, categories, dailyTimeseriesData, apiCalls, callbacks, apiClient, onSave, applicationData, externalDemo, graphToEdit }) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [showDemo, setShowDemo] = useState(externalDemo);

  function showTransactionsCallback(graphItem, timeRange) {
    const richCats = new RichCategories(categories);

    const allRelevantCategoryIds = new Set();
    graphItem.categoryIds.forEach((catId) => {
      allRelevantCategoryIds.add(catId);
      if (!graphItem.pure) {
        richCats.getAllRecursiveChildrenOf(richCats.getCategoryById(catId)).forEach((cat) => allRelevantCategoryIds.add(cat.id));
      }
    });

    const relevantTransactions = (analysisResult?.classified_transactions || [])
      .filter((t) => allRelevantCategoryIds.has(t.category_id))
      .filter((t) => t.timestamp >= timeRange.start)
      .filter((t) => t.timestamp < timeRange.end);

    setSelectedTransactions(relevantTransactions);
    setDialogOpen(true);
  }

  const [activeStep, setActiveStep] = useState(0);

  const defaultGiState = {};
  var defaultGiKey = undefined;

  const defaultGtState = {};
  var defaultGtKey = undefined;

  const defaultTrState = {};
  var defaultTrKey = undefined;

  var defaultGraphName = "";

  if (graphToEdit != undefined) {
    const gt = graphToEdit.graphType;
    const gi = graphToEdit.graphItemsDefinition;
    const tr = graphToEdit.timeRangesDefinition;

    defaultGiState[gi.getType()] = gi;
    defaultGiKey = gi.getType();

    defaultGtState[gt.getType()] = gt;
    defaultGtKey = gt.getType();

    defaultTrState[tr.getType()] = tr;
    defaultTrKey = tr.getType();

    defaultGraphName = graphToEdit.title;
  }

  const [graphItemSelectorState, setGraphItemSelectorState] = useState(defaultGiState);
  const [graphItemSelectedKey, setGraphItemSelectedKey] = useState(defaultGiKey);

  const [timeRangesSelectorState, setTimeRangesSelectorState] = useState(defaultTrState);
  const [timeRangesSelectedKey, setTimeRangesSelectedKey] = useState(defaultTrKey);

  const [graphTypeSelectionState, setGraphTypeSelectionState] = useState(defaultGtState);
  const [graphTypeSelectedKey, setGraphTypeSelectedKey] = useState(defaultGtKey);

  const [graphName, setGraphName] = useState(defaultGraphName);

  const richCats = new RichCategories(categories);

  const renderedGraph = useMemo(() => {
    if (activeStep == 3) {
      return generateGraph(
        categories,
        dailyTimeseriesData,
        graphTypeSelectionState[graphTypeSelectedKey],
        graphItemSelectorState[graphItemSelectedKey],
        timeRangesSelectorState[timeRangesSelectedKey],
        showTransactionsCallback,
        applicationData?.windowRatio
      );
    } else {
      return <div>Not shown anyway</div>;
    }
  }, [
    activeStep,
    categories,
    dailyTimeseriesData,
    graphTypeSelectionState[graphTypeSelectedKey],
    graphItemSelectorState[graphItemSelectedKey],
    timeRangesSelectorState[timeRangesSelectedKey],
    applicationData?.windowRatio,
  ]);

  function handleNext() {
    setActiveStep(activeStep + 1);
  }

  function handleBack() {
    setActiveStep(activeStep - 1);
  }

  function getGraphBuilderContent() {
    if (activeStep == 0) {
      return (
        <GraphTypeSelector
          selectedOptionKey={graphTypeSelectedKey}
          onSelectedOptionKeyChange={setGraphTypeSelectedKey}
          selectionState={graphTypeSelectionState}
          onSelectionStateChange={setGraphTypeSelectionState}
        />
      );
    } else if (activeStep == 1) {
      return (
        <TimeRangesSelectorV2
          multi={graphTypeSelectedKey == "bar_trend"}
          categories={categories}
          selectionState={timeRangesSelectorState}
          setSelectionState={setTimeRangesSelectorState}
          selectedKey={timeRangesSelectedKey}
          onSelectedOptionKeyChange={setTimeRangesSelectedKey}
        />
      );
    } else if (activeStep == 2) {
      return (
        <GraphItemsSelectorV2
          categories={categories}
          selectionState={graphItemSelectorState}
          setSelectionState={setGraphItemSelectorState}
          selectedKey={graphItemSelectedKey}
          onSelectedOptionKeyChange={setGraphItemSelectedKey}
        />
      );
    } else {
      return (
        <Grid container>
          <Grid item xs={4}>
            <Grid container>
              <Grid item xs={12}>
                <MDBox m={1}>
                  <MDInput variant="standard" label="Graph Name" fullWidth value={graphName} onChange={(ev) => setGraphName(ev.target.value)} />
                </MDBox>
              </Grid>
              {graphToEdit != undefined && (
                <Grid item xs={12}>
                  <Grid container justifyContent={"center"}>
                    <MDBox m={1}>
                      <MDButton
                        disabled={graphName?.length == 0}
                        variant="outlined"
                        color="info"
                        onClick={(ev) => {
                          const serializedGi = serializeGraphItemDefinition(graphItemSelectorState[graphItemSelectedKey]);
                          const serializedTr = serializeTimeRangesDefinition(timeRangesSelectorState[timeRangesSelectedKey]);
                          const serializedGt = serializeGraphTypeDefinition(graphTypeSelectionState[graphTypeSelectedKey]);

                          const gd = new GraphDefinition(graphName, serializedGt, serializedGi, serializedTr);
                          apiCalls.updateGraphDefinition(graphToEdit.id, gd);
                          if (onSave) {
                            onSave();
                          }
                        }}
                      >
                        Update Graph
                      </MDButton>
                    </MDBox>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12}>
                <Grid container justifyContent={"center"}>
                  <MDBox m={1}>
                    <MDButton
                      disabled={graphName?.length == 0}
                      variant="outlined"
                      color="info"
                      onClick={(ev) => {
                        const serializedGi = serializeGraphItemDefinition(graphItemSelectorState[graphItemSelectedKey]);
                        const serializedTr = serializeTimeRangesDefinition(timeRangesSelectorState[timeRangesSelectedKey]);
                        const serializedGt = serializeGraphTypeDefinition(graphTypeSelectionState[graphTypeSelectedKey]);

                        const gd = new GraphDefinition(graphName, serializedGt, serializedGi, serializedTr);
                        apiCalls.saveNewGraphDefinition(gd);
                        if (onSave) {
                          onSave();
                        }
                      }}
                    >
                      {graphToEdit == undefined && "Save Graph"}
                      {graphToEdit != undefined && "Save As A New Graph"}
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            {renderedGraph}
          </Grid>
        </Grid>
      );
    }
  }

  var optionIsSelected = false;
  if (activeStep == 0) {
    optionIsSelected = graphTypeSelectedKey != undefined && graphTypeSelectionState[graphTypeSelectedKey] != undefined;
  }
  if (activeStep == 1) {
    optionIsSelected = timeRangesSelectedKey != undefined && timeRangesSelectorState[timeRangesSelectedKey] != undefined;
  }
  if (activeStep == 2) {
    optionIsSelected = graphItemSelectedKey != undefined && graphItemSelectorState[graphItemSelectedKey] != undefined;
  }

  return (
    <div>
      <MDBox mt={2} sx={{ overflow: "auto" }}>
        <Card sx={{ overflow: "auto" }}>
          <MDBox ml={3} mr={3} mt={-2}>
            <Stepper alternativeLabel activeStep={activeStep}>
              <Step key="Step 1" completed={true}>
                <StepButton onClick={(ev) => setActiveStep(0)}>Graph Type</StepButton>
              </Step>

              <Step key="Step 2">
                <StepButton onClick={(ev) => setActiveStep(1)}>Time range</StepButton>
              </Step>

              <Step key="Step 3">
                <StepButton onClick={(ev) => setActiveStep(2)}>Categories</StepButton>
              </Step>

              <Step key="Step 4">
                <StepButton onClick={(ev) => setActiveStep(3)}>Finalize</StepButton>
              </Step>
            </Stepper>
          </MDBox>

          <Grid container>
            <Grid item xs={12}>
              <MDBox m={2}>
                <div class="GraphBuilderContent">{getGraphBuilderContent()}</div>
              </MDBox>
            </Grid>
            <Grid item xs={12}>
              <MDBox m={2}>
                <Grid container justifyContent={"space-between"}>
                  <Grid item>
                    {activeStep > 0 && (
                      <MDButton onClick={(ev) => handleBack()} variant="outlined" color="text">
                        Back
                      </MDButton>
                    )}
                  </Grid>
                  <Grid item>
                    {activeStep < 3 && (
                      <div class="NextButton">
                        <MDButton onClick={(ev) => handleNext()} variant="outlined" color="text" disabled={!optionIsSelected}>
                          Next
                        </MDButton>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
        <FDDemo steps={createDemoSteps(setActiveStep, setGraphTypeSelectedKey, setTimeRangesSelectedKey, setGraphItemSelectedKey)} run={externalDemo} />
        <Dialog open={dialogOpen} fullWidth={true} maxWidth={"xl"}>
          <CloseableCard onClose={() => setDialogOpen(false)}>
            <TransactionList
              categories={categories}
              transactions={selectedTransactions}
              apiClient={apiClient}
              callbacks={callbacks}
              shouldUseCookies={false}
              apiCalls={apiCalls}
              applicationData={applicationData}
            />
          </CloseableCard>
        </Dialog>
      </MDBox>
    </div>
  );
}

export default GraphBuilder;
