import DetailsCard from "./detailscard";

function TransactionDetails({ transaction }) {
  const detailsToShow = {
    Time: transaction.timestamp.toLocaleDateString(),
    "Counterpart name": transaction.counter_part_name,
    "Counterpart account": transaction.counter_part_account,
    Account: transaction.account,
    Amount: transaction.amount,
    Description: transaction.description,
    Comments: transaction.comments,
  };

  return <DetailsCard title="Transaction Details" keyValuePairs={detailsToShow} />;
}

export default TransactionDetails;
