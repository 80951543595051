import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { generateRuleText } from "utils/RuleUtils";

function RuleDetails({ rule, category }) {
  return (
    <MDBox width="100%" display="flex" flexDirection="column" lineHeight={1}>
      <MDBox mb={2}>
        <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
          Rule for {category?.name}
        </MDTypography>
      </MDBox>
      <MDTypography variant="caption" fontWeight="regular" color="text">
        {generateRuleText(rule)}
      </MDTypography>
    </MDBox>
  );
}

export default RuleDetails;
