import { Button, IconButton } from "@mui/material";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import UploadFileIcon from "@mui/icons-material/UploadFile";

export function DropZone({ onFileAdded }) {
  const onDrop = useCallback((acceptedFiles) => {
    onFileAdded(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "text/csv": [".csv"],
    },
  });

  return (
    <section className="container">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <Button startIcon={<UploadFileIcon fontSize="large"></UploadFileIcon>}>
          Upload New File
        </Button>
        <IconButton></IconButton>
      </div>
    </section>
  );
}
