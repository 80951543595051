import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useMemo, useState } from "react";
import MDInput from "components/MDInput";

import TitledCardWithCloseButton from "./TitledCardWithCloseButton";
import MDButton from "components/MDButton";
import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Category } from "fdc_expenses";
import { Grid, Icon } from "@mui/material";
import { ThreeSixty } from "@mui/icons-material";
import SingleAutocompleteCategorySelector from "layouts/pages/classificationflow/singleautocompletecategoryselector";

export function SelectNewParentCard({ categories, onClose, apiCalls, defaultParent, categoryToChange }) {
  const [newCategoryName, setNewCategoryName] = useState(categoryToChange.name);
  const [selectedParent, setSelectedParent] = useState(defaultParent || null);

  function changeParent() {
    apiCalls.modifyCategoryParent(categoryToChange.id, selectedParent.id);
    onClose();
  }

  function rename() {
    apiCalls.modifyCategoryName(categoryToChange.id, newCategoryName);
    onClose();
  }

  function getAllIndirectChildren(category) {
    const allDirectChildren = getAllChildren(category);
    const allIndirectChildren = allDirectChildren.flatMap((child) => getAllChildren(child));

    return allDirectChildren.concat(allIndirectChildren);
  }

  function getAllChildren(category) {
    return categories.filter((cat) => cat.parent_id == category.id);
  }

  const applicabableCategories = useMemo(() => {
    const allIndirectChildren = getAllIndirectChildren(categoryToChange);

    return categories.filter((cat) => cat != categoryToChange).filter((cat) => !allIndirectChildren.includes(cat));
  }, [categories, categoryToChange]);

  return (
    <TitledCardWithCloseButton
      title={
        <MDTypography variant="h6" fontWeight="medium">
          Edit {categoryToChange.name}
        </MDTypography>
      }
      onClose={() => onClose()}
    >
      <MDBox m={2}>
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={5}>
            <Grid container alignItems="center" justifyContent="center">
              <Grid item xs={12}>
                <Grid container alignItems="center" justifyContent="center">
                  <MDTypography component="label" variant="h5" fontWeight="bold" color="text">
                    Change Category Parent
                  </MDTypography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems="center" justifyContent="center">
                  <MDBox mt={3} mb={2}>
                    <SingleAutocompleteCategorySelector
                      categories={applicabableCategories}
                      selectedCategory={selectedParent}
                      selectedCategoryChanged={(selectedCategory, selectedLabel) => {
                        if (selectedCategory) {
                          setSelectedParent(selectedCategory);
                        } else {
                          setSelectedParent(null);
                        }
                      }}
                    />
                  </MDBox>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems="center" justifyContent="center">
                  <MDBox m={3}>
                    <MDButton onClick={(ev) => changeParent()} disabled={selectedParent == undefined} endIcon={<ThreeSixty />}>
                      Move
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5}>
            <Grid container alignItems="center" justifyContent="center">
              <Grid item xs={12}>
                <Grid container alignItems="center" justifyContent="center">
                  <MDTypography component="label" variant="h5" fontWeight="bold" color="text">
                    Change Category Name
                  </MDTypography>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <MDBox mt={3} mb={2}>
                  <Grid container alignItems="center" justifyContent="center">
                    <Grid container alignItems="center" justifyContent="center">
                      <MDTypography variant="h6" fontWeight="medium">
                        <MDInput value={newCategoryName} onChange={(ev) => setNewCategoryName(ev.target.value)} fullWidth />
                      </MDTypography>
                    </Grid>
                  </Grid>
                </MDBox>
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems="center" justifyContent="center">
                  <MDBox m={3}>
                    <MDButton onClick={(ev) => rename()} disabled={newCategoryName.length == 0} endIcon={<Icon>edit</Icon>}>
                      Change name
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </TitledCardWithCloseButton>
  );
}

export default SelectNewParentCard;
