import { TimeRange } from "./TimeRange";

// const { DateTime } = require("luxon");
import { DateTime } from "luxon";

class TimeRangesDefintion {
  getTimeRange() {
    return [];
  }

  containsMultiple() {
    return false;
  }
}

export function parseTimeRangesDefinition(serialized_object, richCats) {
  const parsedObject = JSON.parse(serialized_object);

  if (parsedObject.type == "WeeklyTimeRanges") {
    return WeeklyTimeRanges.fromJson(parsedObject, richCats);
  } else if (parsedObject.type == "MonthlyTimeRanges") {
    return MonthlyTimeRanges.fromJson(parsedObject, richCats);
  } else if (parsedObject.type == "YearlyTimeRanges") {
    return YearlyTimeRanges.fromJson(parsedObject, richCats);
  } else if (parsedObject.type == "AllTimeTimeRange") {
    return AllTimeTimeRange.fromJson(parsedObject, richCats);
  }
}

export function serializeTimeRangesDefinition(trDefinition) {
  const typeObj = { type: trDefinition.getType() };
  const contentObj = trDefinition.toJson();

  return JSON.stringify(Object.assign({}, typeObj, contentObj));
}

function splitWeekly(timeRange) {
  var temp = timeRange.start.startOf("week");
  const allTimeRanges = [];
  while (temp < timeRange.end.endOf("week")) {
    const start = temp;
    const end = temp.plus({ days: 7 });

    const label = "Week " + start.weekNumber + " " + start.year;
    allTimeRanges.push(new TimeRange(start, end, label));
    temp = end;
  }

  return allTimeRanges;
}

function splitMonthly(timeRange) {
  var temp = timeRange.start.startOf("month");
  const allTimeRanges = [];
  while (temp < timeRange.end.endOf("month")) {
    const start = temp;
    const end = temp.plus({ months: 1 });

    const label = start.monthLong + " " + start.year;
    allTimeRanges.push(new TimeRange(start, end, label));
    temp = end;
  }

  return allTimeRanges;
}

function splitYearly(timeRange) {
  var temp = timeRange.start.startOf("year");
  const allTimeRanges = [];
  while (temp < timeRange.end.endOf("year")) {
    const start = temp;
    const end = temp.plus({ years: 1 });

    const label = "Year " + start.year;
    allTimeRanges.push(new TimeRange(start, end, label));
    temp = end;
  }

  return allTimeRanges;
}

function splitQuarterly(timeRange) {
  var temp = timeRange.start.startOf("quarter");
  const allTimeRanges = [];
  while (temp < timeRange.end.endOf("quarter")) {
    const start = temp;
    const end = temp.plus({ quarters: 1 });

    const label = "Quarter " + start.quarter + " " + start.year;
    allTimeRanges.push(new TimeRange(start, end, label));
    temp = end;
  }

  return allTimeRanges;
}

function splitBiMonthly(timeRange) {
  console.log("Bi monthly is not supported yet");
}

export function splitTimeRange(timeRange, splitPer) {
  if (splitPer == "Monthly") {
    return splitMonthly(timeRange);
  }
  if (splitPer == "Yearly") {
    return splitYearly(timeRange);
  }
  if (splitPer == "Weekly") {
    return splitWeekly(timeRange);
  }
  if (splitPer == "Quarterly") {
    return splitQuarterly(timeRange);
  }
  if (splitPer == "Bi-Monthly") {
    return splitBiMonthly(timeRange);
  }
}

class WeeklyTimeRanges {
  constructor(numberOfWeeks) {
    this.numberOfWeeks = numberOfWeeks;
  }

  getTimeRange() {
    const today = DateTime.now();
    return new TimeRange(today.minus({ days: this.numberOfWeeks * 7 }), today, "Last " + this.numberOfWeeks + " weeks");
  }

  getType() {
    return "WeeklyTimeRanges";
  }

  static fromJson(serialized) {
    return new WeeklyTimeRanges(serialized.numberOfWeeks);
  }

  toJson() {
    return { numberOfWeeks: this.numberOfWeeks };
  }
}

class MonthlyTimeRanges {
  constructor(numberOfMonths) {
    this.numberOfMonths = numberOfMonths;
  }

  getTimeRange() {
    const today = DateTime.now();
    return new TimeRange(today.minus({ months: this.numberOfMonths }), today, "Last " + this.numberOfMonths + " months");
  }

  getType() {
    return "MonthlyTimeRanges";
  }

  containsMultiple() {
    return this.numberOfMonths > 1;
  }

  static fromJson(serialized) {
    return new MonthlyTimeRanges(serialized.numberOfMonths);
  }

  toJson() {
    return { numberOfMonths: this.numberOfMonths };
  }
}

class YearlyTimeRanges {
  constructor(numberOfYears) {
    this.numberOfYears = numberOfYears;
  }

  getTimeRange() {
    const today = DateTime.now();
    return new TimeRange(today.minus({ years: this.numberOfYears }), today, "Last " + this.numberOfYears + " year");
  }

  containsMultiple() {
    return this.numberOfYears > 1;
  }

  getType() {
    return "YearlyTimeRanges";
  }

  static fromJson(serialized) {
    return new YearlyTimeRanges(serialized.numberOfYears, serialized.split);
  }

  toJson() {
    return { numberOfYears: this.numberOfYears, split: this.split };
  }
}

class AllTimeTimeRange {
  getTimeRange(timeSeriesData) {
    const today = DateTime.now();
    if (timeSeriesData != undefined) {
      const oldestTime = DateTime.fromJSDate(timeSeriesData.times[0]);
      return new TimeRange(oldestTime, today, "All time");
    } else {
      const agesAgo = today.minus({ years: 100 });
      return new TimeRange(agesAgo, today, "All time");
    }
  }

  static fromJson(serialized) {
    return new AllTimeTimeRange();
  }

  getType() {
    return "AllTimeTimeRange";
  }

  toJson() {
    return {};
  }
}

class SingleTimeRangeWrapper {
  constructor(wrappedTimeRange) {
    this.wrappedTimeRange = wrappedTimeRange;
  }

  getTimeRange() {
    return this.wrappedTimeRange;
  }
}

export { TimeRangesDefintion, YearlyTimeRanges, MonthlyTimeRanges, WeeklyTimeRanges, AllTimeTimeRange, SingleTimeRangeWrapper };
