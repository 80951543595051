import { FormControlLabel, Grid, RadioGroup, FormControl, Radio, FormLabel, Select, MenuItem, Autocomplete, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { BarTrendGraphDefinition, TrendGraphDefinition, BarGraphDefinition, PieGraphDefinition } from "domain/graphconfiguration/GraphTypeDefinition";
import { useMemo } from "react";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import { OptionForSelector } from "./OptionForSelector";
import SelectorV2 from "./SelectorV2";

function ExampleTrendLine({}) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Example trend",
      },
    },
    aspectRatio: 1,
  };

  const labels = ["January", "February", "March", "April", "May", "June", "July"];

  const data = {
    labels,
    datasets: [
      {
        label: "Category 1",
        data: [15, 19, 18, 5, 6, 7],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Category 2",
        data: [5, 29, -13, -5, 6, 8],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return <Line options={options} data={data} />;
}

function ExampleBarTrend({}) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Example Trend",
      },
    },
    aspectRatio: 1,
  };

  const labels = ["January", "February", "March", "April", "May", "June", "July"];

  const data = {
    labels,
    datasets: [
      {
        label: "Category 1",
        data: [15, 19, 18, 5, 6, 7],
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Category 2",
        data: [5, 15, 2, 7, 6, 8],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return <Bar options={options} data={data} />;
}

function ExampleBarComparison({}) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Example Trend",
      },
    },
    aspectRatio: 1,
  };

  const labels = ["Category 1", "Category 2", "Category 3"];

  const data = {
    labels,
    datasets: [
      {
        label: "2022",
        data: [15, 19, 5],
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  return <Bar options={options} data={data} />;
}

function ExamplePie({}) {
  const data = {
    labels: ["Category 1", "Category 2", "Category 3", "Subcategory", "Subcategory 2", "Subcategory 3"],
    datasets: [
      {
        label: "# of Votes",
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)", "rgba(255, 206, 86, 0.2)", "rgba(75, 192, 192, 0.2)", "rgba(153, 102, 255, 0.2)", "rgba(255, 159, 64, 0.2)"],
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)", "rgba(75, 192, 192, 1)", "rgba(153, 102, 255, 1)", "rgba(255, 159, 64, 1)"],
        borderWidth: 1,
      },
    ],
  };

  return <Doughnut data={data} />;
}

export function GraphTypeSelector({ selectedOptionKey, onSelectedOptionKeyChange, selectionState, onSelectionStateChange }) {
  const exampleTrendLine = useMemo(() => <ExampleTrendLine />, []);
  const exampleBarTrend = useMemo(() => <ExampleBarTrend />, []);
  const exampleBarComparison = useMemo(() => <ExampleBarComparison />, []);
  const examplePie = useMemo(() => <ExamplePie />, []);

  return (
    <Grid container>
      <SelectorV2
        title="Select Graph Type"
        options={[
          {
            title: "Bar Trend Chart",
            key: "BarTrendGraphDefinition",
            getDefaultState: function () {
              return new BarTrendGraphDefinition("Monthly");
            },
            stateIsApplicable: (state) => state?.constructor?.name == "BarTrendGraphDefinition",
            generateContentForState: (state, changedStateCallback) => {
              return (
                <Grid container>
                  <Grid item xs={12}>
                    <MDTypography variant="caption">Show trend with a bar graph</MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox m={1}>{exampleBarTrend}</MDBox>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <MDBox mt={2}>
                          <FormControl fullWidth>
                            <Autocomplete
                              fullWidth
                              value={state.splitPeriod}
                              onChange={(event, selectedValue) => {
                                changedStateCallback(new BarTrendGraphDefinition(selectedValue));
                              }}
                              disableClearable={true}
                              options={["Weekly", "Monthly", "Quarterly", "Yearly"]}
                              renderInput={(params) => <TextField fullWidth {...params} label="Split bars per" />}
                            />
                          </FormControl>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            },
          },
          {
            title: "Bar Chart",
            key: "BarGraphDefinition",
            getDefaultState: function () {
              return new BarGraphDefinition();
            },
            stateIsApplicable: (state) => state?.constructor?.name == "BarGraphDefinition",
            generateContentForState: (state, changedStateCallback) => {
              return (
                <Grid container>
                  <Grid item xs={12}>
                    <MDTypography variant="caption">Compare categories with a bar graph</MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    {exampleBarComparison}
                  </Grid>
                </Grid>
              );
            },
          },
          {
            title: "Pie Chart",
            key: "PieGraphDefinition",
            getDefaultState: function () {
              return new PieGraphDefinition();
            },
            stateIsApplicable: (state) => state?.constructor?.name == "PieGraphDefinition",
            generateContentForState: (state, changedStateCallback) => {
              return (
                <Grid container>
                  <Grid item xs={12}>
                    <MDTypography variant="caption">Compare categories with a pie chart</MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    {examplePie}
                  </Grid>
                </Grid>
              );
            },
          },
          {
            title: "Trend",
            key: "TrendGraphDefinition",
            getDefaultState: function () {
              return new TrendGraphDefinition(31, 1);
            },
            stateIsApplicable: (state) => state?.constructor?.name == "TrendGraphDefinition",
            generateContentForState: (state, changedStateCallback) => {
              const options = [
                { label: "Weekly", id: 7 },
                { label: "Monthly", id: 31 },
                { label: "Quarterly", id: 91 },
                { label: "Yearly", id: 365 },
              ];

              return (
                <Grid container>
                  <Grid item xs={12}>
                    <MDTypography variant="caption">Show trend with a continuous line. Mind you, this trend uses rolling averages</MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox m={1}>{exampleTrendLine}</MDBox>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <MDBox mt={2}>
                          <FormControl fullWidth>
                            <Autocomplete
                              fullWidth
                              value={options.filter((option) => option.id == state.averagingDays)[0]}
                              getOptionLabel={(option) => option.label}
                              onChange={(event, selectedValue) => {
                                changedStateCallback(new TrendGraphDefinition(selectedValue.id, 1));
                              }}
                              disableClearable={true}
                              options={options}
                              renderInput={(params) => <TextField fullWidth {...params} label="Smoothing" />}
                            />
                          </FormControl>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            },
          },
        ]}
        selectedOptionKey={selectedOptionKey}
        onSelectedOptionKeyChange={onSelectedOptionKeyChange}
        selectionState={selectionState}
        onSelectionStateChange={onSelectionStateChange}
      />
    </Grid>
  );
}
