import { parseGraphItemDefinition } from "domain/graphconfiguration/GraphItemsDefinition";
import { parseGraphTypeDefinition } from "domain/graphconfiguration/GraphTypeDefinition";
import { parseTimeRangesDefinition } from "domain/graphconfiguration/TimeRangesDefinition";
import { generateGraph } from "domain/graphs/GraphGenerator";
import { useEffect, useMemo, useState } from "react";
import RichCategories from "domain/RichCategories";
import { Card, CardActionArea, Dialog, DialogContent, Grid, Icon, IconButton, Stack, Tooltip } from "@mui/material";
import TitledCard from "components/TitledCard/titled_card";
import MDBox from "components/MDBox";
import GenericCard from "components/TitledCard/GenericCard";
import MDTypography from "components/MDTypography";
import { TransactionList } from "../expensestable/TransactionListPage";
import { CloseableCard } from "components/TitledCard/GenericCard";
import MDButton from "components/MDButton";
import GraphBuilder from "./GraphBuilder";
import { useLocation, useNavigate } from "react-router-dom";
import { AccountSetup } from "./AccountSetup";
import FDDemo from "utils/FDDemo";
import MDBadge from "components/MDBadge";
import PersonalGraph from "./PersonalGraph";
import ParsedGraphDefinition from "domain/graphconfiguration/ParsedGraphDefinition";
import FullscreenIcon from "@mui/icons-material/Fullscreen";

const steps = [
  {
    target: ".NavBar",
    title: "Dashboard",

    content: (
      <Stack>
        <MDTypography variant="button">
          This page is your Budgex Landing page. You can tweak it to your liking by adding the visualisations that provide you with the insight in your expenses that you prefer.
        </MDTypography>
        <MDTypography variant="button">There are some default graphs provided, but you can also define the visualisations that you want to see.</MDTypography>
        <MDTypography variant="button">All graphs on this page are interactive. If you want to see which Transactions are reflected by a specific graph item, just click on it !</MDTypography>
      </Stack>
    ),
    disableBeacon: true,
  },
  {
    target: ".ExpensesComposition",
    title: "Composition of Expenses",
    content: (
      <Stack>
        <MDTypography variant="button">
          This is your first default Graph. It shows the composition of your Expenses. Initially most of the transactions will be shown as 'Other Expenses', because you have not classified them
        </MDTypography>
      </Stack>
    ),
    disableBeacon: true,
  },
  {
    target: ".ClassificationProgressExpenses",
    title: "Classification Progress Expenses",
    content: (
      <Stack>
        <MDTypography variant="button">This PieChart allows you to see how much of your transactions are already classified, and which part is not classified yet.</MDTypography>
      </Stack>
    ),
    disableBeacon: true,
  },
  {
    target: ".MonthlyClassificationProgress",
    title: "Monthly Classification Progress Expenses",
    content: (
      <Stack>
        <MDTypography variant="button">
          This graph shows your expenses (splitted between classified and not classified) over time. It allows you to pinpoint easily where a lot of non classified transactions occur and zoom in on
          them
        </MDTypography>
      </Stack>
    ),
    disableBeacon: true,
  },
  {
    target: ".CreateNew",
    title: "Create new Graph",
    content: (
      <Stack>
        <MDTypography variant="button">Create a new graph and add it to your dashboard</MDTypography>
        <MDBox mt={2}>
          <MDTypography variant="button" fontWeight="medium">
            Click on the button to continue the demo
          </MDTypography>
        </MDBox>
      </Stack>
    ),

    disableBeacon: true,
    spotlightClicks: true,
    hideCloseButton: true,
    disableOverlayClose: true,
    hideFooter: true,
  },
];

function SetupDataCard({}) {
  const navigate = useNavigate();

  return (
    <Grid item xs={12}>
      <MDBox m={1}>
        <Card>
          <CloseableCard title="Getting started: Import data" bgColor="#F5F5F5">
            <Grid container>
              <Grid item xs={12}>
                <MDBox ml={2} mb={3} mt={3}>
                  <MDTypography variant="text">
                    To get started, we need to import your data into budgex. Either by linking your bank account(s), importing a CSV or enterring the data manually
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12} sx={{ overflow: "auto" }}>
                  <Grid container alignItems={"stretch"} justifyContent={"stretch"}>
                    <Grid item xs={4}>
                      <MDBox m={1}>
                        <Card>
                          <CardActionArea
                            onClick={(ev) => {
                              navigate({ pathname: "/data/banklink", search: "?showPopup=true" });
                            }}
                          >
                            <MDBox m={1}>
                              <GenericCard topcenter={<MDTypography>Link Bank Account</MDTypography>}>
                                <Grid container justifyContent="center">
                                  <MDBox mt={2}>
                                    <Icon fontSize="large">add_card</Icon>
                                  </MDBox>
                                </Grid>
                              </GenericCard>
                            </MDBox>
                          </CardActionArea>
                        </Card>
                      </MDBox>
                    </Grid>

                    <Grid item xs={4}>
                      <MDBox m={1}>
                        <Card>
                          <CardActionArea
                            onClick={(ev) => {
                              navigate({ pathname: "/data/transactionsfiles" });
                            }}
                          >
                            <MDBox m={1}>
                              <GenericCard topcenter={<MDTypography>Import Data</MDTypography>}>
                                <Grid container justifyContent="center">
                                  <MDBox mt={2}>
                                    <Icon fontSize="large">upload_file</Icon>
                                  </MDBox>
                                </Grid>
                              </GenericCard>
                            </MDBox>
                          </CardActionArea>
                        </Card>
                      </MDBox>
                    </Grid>

                    <Grid item xs={4}>
                      <MDBox m={1}>
                        <Card>
                          <CardActionArea
                            onClick={(ev) => {
                              navigate({ pathname: "/data/manual_transactions" });
                            }}
                          >
                            <MDBox m={1}>
                              <GenericCard topcenter={<MDTypography>Manually Enter Data</MDTypography>}>
                                <Grid container justifyContent="center">
                                  <MDBox mt={2}>
                                    <Icon fontSize="large">keyboard</Icon>
                                  </MDBox>
                                </Grid>
                              </GenericCard>
                            </MDBox>
                          </CardActionArea>
                        </Card>
                      </MDBox>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid item xs={12}>
                <Grid container justifyContent={"center"}>
                  <Grid item>
                    <MDBox m={3}>
                      <Card>
                        <CloseableCard title="Link Bank Account" bgColor="#FFFFFF">
                          <MDBox mt={3} mb={3}>
                            <Grid container justifyContent={"center"}>
                              <Icon fontSize="large">add_card</Icon>
                            </Grid>
                          </MDBox>
                        </CloseableCard>
                      </Card>
                    </MDBox>
                  </Grid>
                  <Grid item>
                    <MDBox m={3}>
                      <Card>
                        <CloseableCard title="Import CSV" bgColor="#FDFDFD">
                          <MDBox mt={3} mb={3}>
                            <Grid container justifyContent={"center"}>
                              <Icon fontSize="large">upload</Icon>
                            </Grid>
                          </MDBox>
                        </CloseableCard>
                      </Card>
                    </MDBox>
                  </Grid>
                </Grid>
              </Grid> */}
            </Grid>
          </CloseableCard>
        </Card>
      </MDBox>
    </Grid>
  );
}

function PersonalGraphsPage({ categories, dailyTimeseriesData, applicationData, apiCalls, apiClient, callbacks }) {
  function needsHelpSettingUpAccount() {
    const finishedSetup =
      applicationData?.setupStatus?.rules_created > 0 &&
      applicationData?.setupStatus?.specific_assignments_created > 0 &&
      applicationData?.setupStatus?.categories_created > 0 &&
      applicationData?.setupStatus?.graphs_created > 0;

    return !finishedSetup;
  }

  const canSetupBeClosed = useMemo(() => {
    // At least some transactions and a rule
    return applicationData?.rawTransactions?.length > 0 && applicationData?.setupStatus?.rules_created > 0;
  }, [applicationData?.setupStatus, applicationData?.rawTransactions?.length]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [creationDialogOpen, setCreationDialogOpen] = useState(false);

  const [filterCategoryIds, setFilterCategoryIds] = useState([]);
  const [filterTimeRange, setFilterTimeRange] = useState();

  const [showSetupAccount, setShowSetupAccount] = useState(needsHelpSettingUpAccount());
  const [showDemo, setShowDemo] = useState(false);
  const [showDemoForPopup, setShowDemoForPopup] = useState(false);
  const [graphToEdit, setGraphToEdit] = useState();

  const [graphDefinitionToShowFullScreen, setGraphDefinitionToShowFullScreen] = useState(undefined);

  const thisUserHasTransactions = applicationData?.rawTransactions?.length > 0;

  const location = useLocation();
  useEffect(() => {
    apiCalls.setHelpCallback(() => () => setShowDemo(true));
    if (location.search.includes("showHelp")) {
      setShowDemo(true);
    }
  }, [location]);

  useEffect(() => apiCalls.updateAnalysisV2IfNeeded());
  useEffect(() => apiCalls.updateTimeseriesIfNeeded());
  const richCats = new RichCategories(categories);

  const relevantTransactions = useMemo(() => {
    if (filterTimeRange) {
      return (applicationData?.rawTransactions || [])
        .filter((t) => t.id in applicationData.categoryAssignment)
        .filter((t) => filterCategoryIds.has(applicationData.categoryAssignment[t.id]))
        .filter((t) => t.timestamp >= filterTimeRange.start)
        .filter((t) => t.timestamp < filterTimeRange.end);
    } else {
      return [];
    }
  }, [filterCategoryIds, filterTimeRange, applicationData.categoryAssignment, applicationData.rawTransactions]);

  function showTransactionsCallback(graphItem, timeRange) {
    const allRelevantCategoryIds = new Set();
    graphItem.categoryIds.forEach((catId) => {
      allRelevantCategoryIds.add(catId);
      if (!graphItem.pure) {
        richCats.getAllRecursiveChildrenOf(richCats.getCategoryById(catId)).forEach((cat) => allRelevantCategoryIds.add(cat.id));
      }
    });

    setFilterTimeRange(timeRange);
    setFilterCategoryIds(allRelevantCategoryIds);

    setDialogOpen(true);
  }

  function renderGraphDefinition(parsedGraphDefinition, key) {
    return (
      <Grid item xs={12} lg={6} key={key + "_" + parsedGraphDefinition.id}>
        <div class={key}>
          <MDBox m={1} mb={2}>
            {/* <Card> */}
            <MDBox mt={-1}>
              <PersonalGraph
                apiCalls={apiCalls}
                graphDefinition={parsedGraphDefinition}
                applicationData={applicationData}
                onShowTransactions={showTransactionsCallback}
                onEditCallback={(graphDefinitionToEdit) => {
                  setGraphToEdit(graphDefinitionToEdit);
                  setCreationDialogOpen(true);
                }}
              />
            </MDBox>
            {/* </Card> */}
          </MDBox>
        </div>
      </Grid>
    );
  }

  const userGraphs = useMemo(() => {
    const allGraphs = applicationData.graphDefinitions.map((gd, index) => {
      const gt = parseGraphTypeDefinition(gd.graph_type, richCats);
      const gi = parseGraphItemDefinition(gd.graph_items, richCats);
      const tr = parseTimeRangesDefinition(gd.time_ranges, richCats);

      const parsedGraphDefinition = new ParsedGraphDefinition(gd.name, gt, gi, tr, gd.id);

      return renderGraphDefinition(parsedGraphDefinition, "UserGraph_" + index);
    });

    return allGraphs;
  }, [applicationData.graphDefinitions, applicationData.categories, applicationData.dailyTimeseriesData, applicationData?.windowRatio]);

  return (
    <div class="DashboardPage">
      <Grid container alignItems={"center"}>
        {!thisUserHasTransactions && <SetupDataCard />}
        {thisUserHasTransactions && showSetupAccount && (
          <Grid item xs={12}>
            <MDBox m={1}>
              <Card>
                <CloseableCard title="Setup your account" onClose={canSetupBeClosed ? () => setShowSetupAccount(false) : undefined} bgColor="#FFFFFF">
                  <AccountSetup applicationData={applicationData} apiCalls={apiCalls} />
                </CloseableCard>
              </Card>
            </MDBox>
          </Grid>
        )}
        <div class="StartDashboardPage"></div>
        {thisUserHasTransactions && (
          <Grid item xs={12}>
            <MDBox mb={3} mt={3}>
              <div class="CreateNew">
                <Grid container justifyContent={"stretch"}>
                  <MDButton
                    variant={"contained"}
                    color="dark"
                    fullWidth
                    onClick={(ev) => {
                      setCreationDialogOpen(true);
                      if (showDemo) {
                        // If we clicked the button as part of the demo, lets continue with it
                        setShowDemoForPopup(true);
                        setShowDemo(false);
                      }
                    }}
                    startIcon={<Icon>add</Icon>}
                    endIcon={<Icon>show_chart</Icon>}
                  >
                    Add new graph
                  </MDButton>
                </Grid>
              </div>
            </MDBox>
          </Grid>
        )}

        {thisUserHasTransactions && userGraphs}
      </Grid>
      <Dialog open={creationDialogOpen} fullWidth={true} maxWidth={"xl"}>
        <CloseableCard
          onHelp={() => setShowDemoForPopup(true)}
          onClose={() => {
            // It could be the end of a demo
            setShowDemoForPopup(false);
            setCreationDialogOpen(false);
            setGraphToEdit(undefined);
          }}
        >
          <MDBox m={1}>
            <GraphBuilder
              externalDemo={showDemoForPopup}
              categories={categories}
              dailyTimeseriesData={applicationData.dailyTimeseriesData}
              graphDefinitions={applicationData.graphDefinitions}
              apiCalls={apiCalls}
              analysisResult={applicationData.analysisResult}
              callbacks={callbacks}
              apiClient={apiClient}
              onSave={() => {
                setGraphToEdit(undefined);
                setCreationDialogOpen(false);
              }}
              graphToEdit={graphToEdit}
            />
          </MDBox>
        </CloseableCard>
      </Dialog>
      <Dialog fullWidth={true} maxWidth={"xl"} open={graphDefinitionToShowFullScreen != undefined}>
        <CloseableCard onClose={() => setGraphDefinitionToShowFullScreen(undefined)}>
          <PersonalGraph
            graphDefinition={graphDefinitionToShowFullScreen}
            applicationData={applicationData}
            apiCalls={apiCalls}
            onShowTransactions={showTransactionsCallback}
            onEditCallback={(graphDefinitionToEdit) => {
              setGraphToEdit(graphDefinitionToEdit);
              setCreationDialogOpen(true);
            }}
          />
        </CloseableCard>
      </Dialog>
      <Dialog open={dialogOpen} fullWidth={true} maxWidth={"xl"}>
        <CloseableCard
          onClose={() => {
            setDialogOpen(false);
          }}
        >
          <MDBox ml={1} mr={1}>
            <TransactionList
              categories={categories}
              categoryAssignment={applicationData.categoryAssignment}
              transactions={relevantTransactions}
              apiClient={apiClient}
              callbacks={callbacks}
              shouldUseCookies={false}
              apiCalls={apiCalls}
              applicationData={applicationData}
            />
          </MDBox>
        </CloseableCard>
      </Dialog>
      <FDDemo
        steps={steps}
        run={showDemo}
        onStop={() => {
          setShowDemo(false);
        }}
      />
    </div>
  );
}

export default PersonalGraphsPage;
