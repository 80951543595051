import { Dialog, FormControlLabel, FormLabel, Grid, Stack } from "@mui/material";
import TransactionDetails from "layouts/pages/ruleanalysis/transactiondetails";
import { useEffect, useState } from "react";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { TransactionList } from "../expensestable/TransactionListPage";
import { useLocation } from "react-router-dom";
import { CreateManualTransactionPopup } from "./CreateManualTransactionPopup";
import { ManualTransactionsToolBar } from "./ManualTransactionsToolBar";
import { DateTime } from "luxon";
import RichCategories from "domain/RichCategories";
import FDDemo from "utils/FDDemo";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

const steps = [
  {
    target: ".NavBar",
    title: "Manual transcations page",
    content: <MDTypography variant="button">On this page, you get an overview of your transactions. This page is also the starting point to assign categories to transactions</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".Transaction0",
    content: <MDTypography variant="button">These cards show all your current manual transactions.</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".ManualBadge_0",
    content: (
      <MDTypography variant="button">Those manual transactions will also show up in your normal transaction list. You can recognise them because they have this red "Manual" Badge</MDTypography>
    ),
    disableBeacon: true,
  },
  {
    target: ".AddManualTransaction",
    content: (
      <Stack>
        <MDTypography variant="button">Click on this button to create a new manual Transaction</MDTypography>
        <MDBox mt={2}>
          <MDTypography variant="button" fontWeight="medium">
            Click on the button to continue the demo
          </MDTypography>
        </MDBox>
      </Stack>
    ),
    disableBeacon: true,
    spotlightClicks: true,
    hideCloseButton: true,
    disableOverlayClose: true,
    hideFooter: true,
  },
];

function ManualTransactionsPage({ apiCalls, applicationData, apiClient, callbacks }) {
  const [showPopup, setShowPopup] = useState(false);
  const [showDemo, setShowDemo] = useState(false);
  const [showDemoInPopup, setShowDemoInPopup] = useState(false);

  var manualTransactions = applicationData.manualTransactions || [];
  var categoryAssignment = applicationData.categoryAssignment;

  const location = useLocation();
  useEffect(() => {
    apiCalls.setHelpCallback(() => () => setShowDemo(true));
    if (location.search.includes("showHelp")) {
      setShowDemo("Rule");
    }
  }, [location]);

  if (showDemo) {
    const richCats = new RichCategories(applicationData.categories);
    manualTransactions = [{ account: "DEMO", amount: "-15.0", comments: "This is a demo transaction", counter_part_name: "John Doe", timestamp: DateTime.now().toJSDate(), id: -1, manual: true }];
    categoryAssignment = { "-1": applicationData.categories[0].id.toString() };
  }

  return (
    <div class="ManualTransactionsPage">
      <Grid container>
        <Grid item xs={12}>
          <ManualTransactionsToolBar
            onOpen={() => {
              // It is possible that the opening of this popup is the end of our current demo and the start of the demo in the create popup
              setShowDemoInPopup(showDemo);
              setShowDemo(false);
              setShowPopup(true);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          {manualTransactions?.length > 0 && (
            <TransactionList
              categories={applicationData.categories}
              categoryAssignment={categoryAssignment}
              callbacks={callbacks}
              transactions={manualTransactions}
              apiCalls={apiCalls}
              useCookies={false}
              apiClient={apiClient}
              applicationData={applicationData}
            />
          )}
        </Grid>
        <Dialog fullWidth maxWidth="xl" open={showPopup}>
          <CreateManualTransactionPopup
            apiCalls={apiCalls}
            externalDemo={showDemoInPopup}
            onClose={() => {
              setShowPopup(false);
              // In case that a demo was ongoing, stop it now
              setShowDemoInPopup(false);
            }}
          />
        </Dialog>
        <FDDemo run={showDemo} onStop={() => setShowDemo(false)} steps={steps} />
      </Grid>
    </div>
  );
}

export default ManualTransactionsPage;
