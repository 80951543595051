import Grid from "@mui/material/Grid";
import { TextField } from "@mui/material";
import { DatePicker, MobileDatePicker } from "@mui/x-date-pickers";
import { ConditionTile } from "./ConditionTile";
import MDTypography from "components/MDTypography";
import { DateTime } from "luxon";
import MDBox from "components/MDBox";

function dateToStartOfUTCDay(date) {
  return DateTime.utc(date.year, date.month, date.day, 0, 0, 0);
}

export function DateCondition({ condition, onConditionChanged, onDelete }) {
  const today = DateTime.now();

  const notModifier = condition.invert && (
    <MDTypography variant="caption" fontWeight="bold">
      NOT
    </MDTypography>
  );

  const afterText = condition.min_date != undefined && " after " + condition.min_date?.toFormat("yyyy LLL dd");
  const beforeText = condition.max_date != undefined && " before " + condition.max_date?.toFormat("yyyy LLL dd");
  const bindText = condition.min_date != undefined && condition.max_date != undefined && " and ";

  return (
    <ConditionTile title="Date Filter" onDelete={onDelete} condition={condition} onConditionChanged={onConditionChanged}>
      <Grid container justifyContent={"space-evenly"}>
        <Grid item xs={12} key="date_description">
          <MDBox mb={1}>
            <MDTypography variant="caption">
              Matches all transactions that are {notModifier} {afterText} {bindText} {beforeText}
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid item key="min_date_filter">
          <DatePicker
            value={condition.min_date}
            onChange={(newDate) => {
              console.log("Date changes", newDate);
              const copiedCondition = Object.assign({}, condition);
              copiedCondition.min_date = dateToStartOfUTCDay(newDate);
              onConditionChanged(copiedCondition);
            }}
            label="Minimum date"
            renderInput={(params) => <TextField {...params} fullWidth />}
          ></DatePicker>
        </Grid>
        <Grid item key="max_date_filter">
          <DatePicker
            onChange={(newDate) => {
              console.log("Changing to", newDate);
              const copiedCondition = Object.assign({}, condition);
              copiedCondition.max_date = dateToStartOfUTCDay(newDate);
              onConditionChanged(copiedCondition);
            }}
            value={condition.max_date}
            label="Maximum date"
            renderInput={(params) => <TextField {...params} fullWidth />}
          ></DatePicker>
        </Grid>
      </Grid>
    </ConditionTile>
  );
}
