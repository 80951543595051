import { Grid } from "@mui/material";
import MDButton from "components/MDButton";
import ReactJoyride from "react-joyride";

import { demoCallbacks } from "utils/JoyRideUtils";

function FDTooltip({ continuous, index, step, backProps, closeProps, primaryProps, tooltipProps }) {
  console.log("Tyring to render");
  const a = (
    <Grid container>
      {step.title && (
        <Grid item xs={12}>
          {step.title}
        </Grid>
      )}
      {step.content && (
        <Grid item xs={12}>
          {step.content}
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Grid item>{<MDButton>Skip</MDButton>}</Grid>
          <Grid item>{index > 0 && <MDButton>Back</MDButton>}</Grid>
          <Grid item>{continuous && <MDButton>Next</MDButton>}</Grid>
        </Grid>
      </Grid>
    </Grid>
  );
  console.log(a);
  return a;
}

function FDDemo({ steps, run, onFinished, onSkipped, onStop, notTheEnd }) {
  return (
    <div>
      {run == true && (
        <ReactJoyride
          styles={{
            options: {
              arrowColor: "#e3ffeb",
              primaryColor: "#000",
              textColor: "#004a14",
              width: 900,
              zIndex: 1000000,
            },
          }}
          callback={(d) => demoCallbacks(d, onFinished, onSkipped, onStop)}
          disableBeacon={true}
          run={run}
          debug={false}
          steps={steps}
          continuous={true}
          showSkipButton={true}
          disableScrolling={false}
          locale={{ back: "Back", close: "Close", last: notTheEnd ? "Next" : "Finish demo", next: "Next", open: "Open the dialog", skip: "Exit demo" }}
        />
      )}
    </div>
  );
}

export default FDDemo;
