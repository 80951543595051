import { Autocomplete, Card, CardActionArea, Dialog, Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { number } from "bfj/src/events";
import MDAvatar from "components/MDAvatar";
import { CloseableCard } from "components/TitledCard/GenericCard";
import { useEffect, useState } from "react";
import FDDemo from "utils/FDDemo";

const { default: MDBox } = require("components/MDBox");
const { default: MDButton } = require("components/MDButton");
const { default: MDTypography } = require("components/MDTypography");

const countries = [
  { name: "Belgium", code: "BE" },
  { name: "France", code: "FR" },
  { name: "Germany", code: "DE" },
  { name: "Denmark", code: "DK" },
  { name: "Sweden", code: "SE" },
  { name: "Netherlands", code: "NL" },
];

const steps = [
  {
    target: ".SelectCountry",
    content: <MDTypography variant="button">Select the country of the bank you want to sync your transactions with</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".SearchBank",
    content: <MDTypography variant="button">Use the search bar to find your bank</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".Bank_0",
    content: (
      <MDTypography variant="button">
        Click on the tile for the relevant bank. You will be redirected to your bank page, where you will be asked whether Budgex.eu can access your transactions. The permission that you give, is only
        valid for 90 days, can be revoked at any point in time and only provides access to the history of your transactions
      </MDTypography>
    ),
    disableBeacon: true,
  },
];

function DeleteMePage({ apiClient, externalDemo }) {
  const [open, setOpen] = useState(false);
  const [numberOf, setNumberOf] = useState(5);

  const [banks, setBanks] = useState([]);
  const [country, setCountry] = useState(countries[0]);
  const [bankSearch, setBankSearch] = useState("");
  const [showDemo, setShowDemo] = useState(externalDemo || false);

  const [initialBankDownloadSuccess, setInitialBankDownloadSuccess] = useState(false);

  const items = new Array(numberOf).fill(0).map((item) => {
    return (
      <Grid item xs={12}>
        <MDTypography>Item</MDTypography>
      </Grid>
    );
  });

  console.log(items);

  function increase() {
    if (numberOf > 0) {
      const newNumberOf = numberOf + 5;
      setNumberOf(0);
      setTimeout(() => setNumberOf(newNumberOf), 500);
    }
  }

  function updateBanks(country) {
    const storeBanks = function (error, data, response) {
      if (!error) {
        setInitialBankDownloadSuccess(true);
        setBanks(data);
      }
    };

    if (country != undefined) {
      apiClient.getAllSupportedBanksForCountry(country.code, storeBanks);
    }
  }

  useEffect(() => updateBanks(country), [country]);

  const renderedBanks = banks
    .filter((bank) => bankSearch.length < 1 || bank.name.toLowerCase().includes(bankSearch.toLowerCase()))
    .map((bank, index) => {
      return (
        <Grid item xl={4} xs={6} key={"Bank_" + bank.code}>
          <div class={"Bank_" + index}>
            <MDBox m={1}>
              <Card>
                <CardActionArea
                  onClick={(ev) => {
                    apiCalls.linkBankWithCode(bank.code);
                    onClose();
                  }}
                >
                  <MDBox m={1}>
                    <Grid container alignContent={"center"}>
                      <Grid item xs={6}>
                        <MDAvatar src={bank.logo_link}></MDAvatar>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid container alignContent={"center"}>
                          <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                            {bank.name}
                          </MDTypography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </MDBox>
                </CardActionArea>
              </Card>
            </MDBox>
          </div>
        </Grid>
      );
    });

  return (
    <div class="BankLinkPage">
      <MDBox>
        <MDButton onClick={(ev) => setOpen(true)}>Open</MDButton>
        <Dialog open={open} fullWidth={true} maxWidth={"xl"}>
          <div>
            <CloseableCard onClose={(ev) => setOpen(false)}>
              <Grid container>
                <Grid item xs={12}>
                  <MDBox lineHeight={1} ml={2} mb={3}>
                    <MDTypography variant="h5" fontWeight="medium">
                      Link new bank account
                    </MDTypography>
                  </MDBox>
                </Grid>

                <Grid item xs={12}>
                  <MDBox mb={3} ml={5} mr={5}>
                    <div class={"SelectCountry"}>
                      <Autocomplete
                        options={countries}
                        renderOption={(props, option) => (
                          <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
                            <img
                              loading="lazy"
                              width="20"
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              alt=""
                            />
                            {option.name} ({option.code})
                          </Box>
                        )}
                        getOptionLabel={(option) => option.name}
                        value={country}
                        onChange={(event, selectedOption) => {
                          setCountry(selectedOption);
                          setBanks([]);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Choose a country"
                            inputProps={{
                              ...params.inputProps,
                            }}
                          />
                        )}
                      />
                    </div>
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox mb={3} ml={5} mr={5}>
                    <div class={"SearchBank"}>
                      <TextField label="Search Bank" value={bankSearch} onChange={(ev) => setBankSearch(ev.target.value)} fullWidth />
                    </div>
                  </MDBox>
                </Grid>
                <Grid item xs={12}>
                  <MDBox lineHeight={1} mt={2} ml={2} mr={2} mb={2}>
                    <Grid container>{renderedBanks}</Grid>
                  </MDBox>
                </Grid>
              </Grid>
            </CloseableCard>
          </div>
          {showDemo && initialBankDownloadSuccess && (
            <FDDemo
              run={showDemo && initialBankDownloadSuccess}
              steps={steps}
              onStop={() => {
                // No matter the reason for stopping, we stop the demo
                // If this was triggered due to an external demo, we close it as well
                setShowDemo(false);
                if (externalDemo) {
                  onClose();
                }
              }}
            />
          )}
        </Dialog>
      </MDBox>
    </div>
  );
}

export default DeleteMePage;
