import { useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import React from "react";
import { GoogleLogin } from "@react-oauth/google";

export function LoginCard({ apiInstance, apiCalls, onLogin }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [lastLoginError, setLastLoginError] = useState(false);
  const [lastGoogleCredential, setLastGoogleCredential] = useState(false);

  const [showTwoFa, setShowTwoFa] = useState(false);
  const [twoFaToken, setTwoFaToken] = useState();

  function googleSuccess(r) {
    setLastLoginError(false);
    setLastGoogleCredential(r);
    apiInstance.loginWithGoogleToken(r.credential, loginCallback);
  }
  function googleError(r) {
    console.log("Authentication by Google Failed", r);
  }

  function loginCallback(error, data, response) {
    if (error) {
      if (response.statusCode == 412) {
        setLastLoginError(false);
        setShowTwoFa(true);
      } else {
        apiCalls.addErrorAlert("Problem: " + error);
        setLastLoginError(true);
      }
    } else {
      setLastLoginError(false);
      apiCalls.addInfoAlert("Login succesful", 3, "Login");
      onLogin(data.access_token);
    }
  }

  function tryAgainWithTwoFa() {
    if (lastGoogleCredential) {
      apiInstance.loginWithGoogleTokenAndTwoFa(lastGoogleCredential.credential, twoFaToken, loginCallback);
    } else {
      apiInstance.loginWithTwoFa(username, password, { optionalTwoFaToken: twoFaToken }, loginCallback);
    }
  }

  const tryToLogin = function (event) {
    setLastGoogleCredential();
    setLastLoginError(false);
    apiInstance.login(username, password, {}, loginCallback);
  };

  if (showTwoFa) {
    return (
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <MDBox mt={2}>
                <MDInput type="normal" label="2FA Token" fullWidth value={twoFaToken} onChange={(ev) => setTwoFaToken(ev.target.value)} />
              </MDBox>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <MDBox mt={2}>
                <MDButton variant="gradient" color="info" fullWidth onClick={(ev) => tryAgainWithTwoFa()} disabled={!(twoFaToken?.length > 3)}>
                  continue
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <MDBox pt={4} pb={3} px={3}>
        <MDBox mb={3} mt={-2}>
          <Grid container justifyContent="center">
            <MDTypography variant="h6">Login with Google</MDTypography>
          </Grid>
        </MDBox>
        <MDBox mb={3}>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <GoogleLogin onSuccess={googleSuccess} onError={googleError} shape="pill"></GoogleLogin>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mb={3}>
          <Grid container justifyContent="center">
            <MDTypography variant="h6">OR</MDTypography>
          </Grid>
        </MDBox>

        <MDBox component="form" role="form">
          <div>
            {lastLoginError && (
              <MDBox mb={2}>
                <MDTypography variant="caption" color="error">
                  Login failed, check username or password
                </MDTypography>
              </MDBox>
            )}

            <MDBox mb={2}>
              <MDInput type="email" label="Username" fullWidth value={username} onChange={(ev) => setUsername(ev.target.value)} />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Password" fullWidth value={password} onChange={(ev) => setPassword(ev.target.value)} />
            </MDBox>
          </div>
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton variant="gradient" color="info" fullWidth onClick={(ev) => tryToLogin(ev)} disabled={!username || !password}>
            sign in
          </MDButton>
        </MDBox>
      </MDBox>
    );
  }
}
