import { CircularProgress, Grid } from "@mui/material";
import { generateBarChartDataV2 } from "layouts/pages/barpagev2/BarChartDataGenerator";
import { generateBarChartData } from "layouts/pages/barpagev2/BarChartDataGenerator";
import generatePiechartData from "layouts/pages/piechart/PiechartDataGenerator";
import { useRef } from "react";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import { splitTimeRange } from "domain/graphconfiguration/TimeRangesDefinition";

const { default: RichCategories } = require("domain/RichCategories");
const { default: generateChartData } = require("layouts/dashboards/trendv2/ChartDataGenerator");

const lineConfig = {
  responsive: true,
  plugins: {
    title: {
      display: false,
      text: (ctx) => "Chart.js Line Chart - stacked=" + ctx.chart.options.scales.y.stacked,
    },
    tooltip: {
      mode: "index",
    },
  },
  interaction: {
    mode: "nearest",
    axis: "x",
    intersect: false,
  },
  scales: {
    x: {
      title: {
        display: true,
        text: "Date",
      },
    },
    y: {
      stacked: false,
      title: {
        display: true,
        text: "EUR/Month",
      },
    },
  },
  aspectRatio: 1.5,
};

const barOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
  },
  aspectRatio: 1.5,
};

function createGeneralOnClickFunction(graphItems, timeRanges, onCallback, reversed) {
  function onEvent(element, event) {
    var index;
    var datasetIndex;

    if (!reversed) {
      index = element[0].index;
      datasetIndex = element[0].datasetIndex;
    } else {
      index = element[0].datasetIndex;
      datasetIndex = element[0].index;
    }

    const relevantGraphItem = graphItems[index];
    const relevantTimeRange = timeRanges[datasetIndex];
    if (onCallback) {
      onCallback(relevantGraphItem, relevantTimeRange);
    }
  }

  return onEvent;
}

function createOnClickFunction(graphItems, timeRanges, onCallback) {
  return createGeneralOnClickFunction(graphItems, timeRanges, onCallback, false);
}

function createReversedOnClickFunction(graphItems, timeRanges, onCallback) {
  return createGeneralOnClickFunction(graphItems, timeRanges, onCallback, true);
}

function onlyKeepGraphItemsThatHaveData(graphItems, dailyTimeseriesData) {
  return graphItems.filter((graphItem) => dailyTimeseriesData?.timeseries.filter((ts) => graphItem.categoryIds.includes(ts.category.id)).length > 0);
}

export function generateGraph(categories, dailyTimeseriesData, graphType, graphItemDefinition, timeRangesDefinitions, onCallback, renderRatio) {
  const richCategories = new RichCategories(categories);

  if (dailyTimeseriesData && graphType != undefined && graphItemDefinition != undefined && timeRangesDefinitions != undefined) {
    const graphTypeKey = graphType?.getType();

    if (graphTypeKey == "TrendGraphDefinition") {
      const configuration = {
        averagingDays: graphType.averagingDays || 365,
        graphItems: graphItemDefinition.getGraphItems(richCategories),
      };

      const data = generateChartData(dailyTimeseriesData, configuration);

      return <Line data={data} options={Object.assign(lineConfig, { aspectRatio: renderRatio })} />;
    } else if (graphTypeKey == "BarTrendGraphDefinition") {
      const graphConfiguration = {
        graphItems: onlyKeepGraphItemsThatHaveData(graphItemDefinition.getGraphItems(richCategories), dailyTimeseriesData),
        timeRanges: splitTimeRange(timeRangesDefinitions.getTimeRange(dailyTimeseriesData), graphType.splitPeriod || "Monthly"),
      };

      const data = generateBarChartDataV2(graphConfiguration, dailyTimeseriesData);

      return (
        <Bar
          data={data}
          options={Object.assign(barOptions, { aspectRatio: renderRatio })}
          key="ExpensesStackBarChart"
          getElementAtEvent={createReversedOnClickFunction(graphConfiguration.graphItems, graphConfiguration.timeRanges, onCallback)}
        />
      );
    } else if (graphTypeKey == "BarGraphDefinition") {
      const graphConfiguration = {
        graphItems: graphItemDefinition.getGraphItems(richCategories),
        timeRanges: [timeRangesDefinitions.getTimeRange(dailyTimeseriesData)],
      };

      const data = generateBarChartData(graphConfiguration, dailyTimeseriesData);

      return (
        <Bar
          data={data}
          options={Object.assign(barOptions, { aspectRatio: renderRatio })}
          key="ExpensesStackBarChart"
          getElementAtEvent={createOnClickFunction(graphConfiguration.graphItems, graphConfiguration.timeRanges, onCallback)}
        />
      );
    } else if (graphTypeKey == "PieGraphDefinition") {
      const timeRange = timeRangesDefinitions.getTimeRange(dailyTimeseriesData);

      // TODO: Cope better with multiple time ranges

      const graphConfiguration = {
        items: graphItemDefinition.getGraphItems(richCategories),
        start: timeRange.start,
        end: timeRange.end,
      };

      const data = generatePiechartData(graphConfiguration, dailyTimeseriesData);
      return <Doughnut data={data} options={{ aspectRatio: renderRatio }} getElementAtEvent={createOnClickFunction(graphConfiguration.items, [timeRange], onCallback)} />;
    }
  } else {
    return (
      <Grid container justifyContent="center">
        <CircularProgress size={20} />
      </Grid>
    );
  }
}
