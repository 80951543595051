import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import AddCardIcon from "@mui/icons-material/AddCard";
import { CircularProgress, Dialog, Divider, Grid, Icon, IconButton, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MDButton from "components/MDButton";
import { Card, CardActionArea } from "@mui/material";
import { CloseableCard } from "components/TitledCard/GenericCard";
import { useEffect, useState } from "react";
import { RuleTile } from "../ruleanalysis/RuleStatisticsPage";
import RichCategories from "domain/RichCategories";
import ReactJoyride from "react-joyride";
import { demoCallbacks } from "utils/JoyRideUtils";
import Classifier from "layouts/expenses/classifier";
import { DateTime } from "luxon";
import FDDemo from "utils/FDDemo";
import MDBadge from "components/MDBadge";

const ruleSteps = [
  {
    target: ".TransactionDetailsForRule",
    content: <MDTypography variant="button">This page allows you to assign a category to this transaction or just see its details</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".CreateRuleButtonCounterpart_Name",
    content: (
      <Stack>
        <MDTypography variant="button">
          By clicking this button, you can create a 'classification-rule' based on the name of the counterpart. This classification rule will then be applied to all of your transactions
        </MDTypography>
        <MDBox mt={2}>
          <MDTypography variant="button" fontWeight="medium">
            Click on the button to continue the demo
          </MDTypography>
        </MDBox>
      </Stack>
    ),
    disableBeacon: true,
    spotlightClicks: true,
    hideCloseButton: true,
    disableOverlayClose: true,
    hideFooter: true,
  },
];

const singleSteps = [
  {
    target: ".TransactionDetailsForRule",
    content: <MDTypography variant="button">This page allows you to assign a category to this transaction or just see its details</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".AssignCategoryButton",
    content: (
      <Stack>
        <MDTypography variant="button">This button allows you to assign a category for this transaction only, without defining a general rule</MDTypography>
        <MDBox mt={2}>
          <MDTypography variant="button" fontWeight="medium">
            Click on the button to continue the demo
          </MDTypography>
        </MDBox>
      </Stack>
    ),
    disableBeacon: true,
    spotlightClicks: true,
    hideCloseButton: true,
    disableOverlayClose: true,
    hideFooter: true,
  },
];

const generalSteps = [
  {
    target: ".TransactionDetailsForRule",
    content: <MDTypography variant="button">This page allows you to assign a category to this transaction or just see its details</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".CreateRuleButtonCounterpart_Name",
    title: "Create Rule",
    content: (
      <MDTypography variant="button">
        By clicking this button, you can create a 'classification-rule' based on the name of the counterpart. This classification rule will then be applied to all of your transactions
      </MDTypography>
    ),
    disableBeacon: true,
  },
  {
    target: ".CreateRuleButtonComments",
    title: "Create Rule",
    content: <MDTypography variant="button">Click this button if you prefer to use the 'Comments' field in your rule</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".AssignCategoryButton",
    title: "Assign category",
    content: <MDTypography variant="button">This button allows you to assign a category for this transaction only, without defining a general rule</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".IgnoreTransactionButton",
    title: "Ignore transaction",
    content: <MDTypography variant="button">This button assigns this transaction to the ignore category. As a result it will not be shown in your graphs</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".ShowMatchingRulesButton",
    title: "Show matching rules",
    content: <MDTypography variant="button">Using this button, you get a list of all the existing rules that already apply to this transaction</MDTypography>,
    disableBeacon: true,
  },
];

function TransactionProperty({ name, content, buttonCallback }) {
  return (
    <Card sx={{ height: "100%" }}>
      <Grid container sx={{ height: "100%" }}>
        <Grid item xs={12} key="TPBadge">
          <Grid container justifyContent={"center"} alignItems="space-between">
            <MDBox mt={-1.5} ml={-0.5}>
              <MDBadge variant="gradient" color="success" size="lg" badgeContent={name} container />
            </MDBox>
          </Grid>
        </Grid>
        <Grid item xs={12} key="TPContent">
          <MDBox mt={1}>
            <Grid container justifyContent={"center"}>
              <Grid item xs={12}>
                <Grid container justifyContent={"center"}>
                  <MDBox mb={0.5} ml={1} mr={1}>
                    <MDTypography variant="caption" fontWeight="regular" color="text" fontSize="small">
                      {content}
                    </MDTypography>
                  </MDBox>
                </Grid>
              </Grid>
            </Grid>
          </MDBox>
        </Grid>
        <Grid item xs={12} key="TPButtons">
          <Grid container alignItems="flex-end" sx={{ height: "100%" }}>
            <Grid item xs={12}>
              <div className={"CreateRuleButton" + name.replace(" ", "_")}>
                {buttonCallback != undefined && (
                  <MDButton variant={"contained"} color="dark" fullWidth onClick={buttonCallback}>
                    Create rule
                  </MDButton>
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

function TransactionDetailsForRule({ transaction, onClose, apiCalls, applicationData, externalDemo }) {
  const [showClassifier, setShowClassifier] = useState(false);
  const [showRules, setShowRules] = useState(false);
  const [showDemo, setShowDemo] = useState(externalDemo);
  const [showDemoForPopup, setShowDemoForPopup] = useState(externalDemo);

  const [forAccount, setForAccount] = useState(false);
  const [proposedSearchText, setProposedSearchText] = useState("");
  const [showSpecificRule, setShowSpecificRule] = useState(externalDemo == "Single");

  function getRelevantSteps() {
    if (externalDemo == "Rule") {
      return ruleSteps;
    } else if (externalDemo == "Single") {
      return singleSteps;
    } else {
      return generalSteps;
    }
  }

  useEffect(() => {
    if (showRules) {
      console.log("Updating Analysis V2, because the rule details will be shown");
      apiCalls.updateAnalysisV2IfNeeded();
    }
  });

  var rules = undefined;
  const richCats = new RichCategories(applicationData.categories);
  if (transaction.id in (applicationData?.analysisV2?.transaction_statistics || {})) {
    rules = Object.entries(applicationData?.analysisV2?.transaction_statistics[transaction.id]?.level_with_matching_rules)
      .flatMap((entry) => {
        return entry[1].matching_rule_ids;
      })
      .filter((ruleId) => {
        const isPresent = ruleId in applicationData.analysisV2.rule_statistics;
        if (!isPresent) {
          console.log("Did not find stats for rule", ruleId);
        }
        return isPresent;
      })
      .filter((ruleId) => {
        const ruleFound = applicationData.rules.map((r) => r.id).includes(ruleId);
        if (!ruleFound) {
          console.log("Did not find actual rule", ruleId);
        }
        return ruleFound;
      })
      .map((ruleId) => {
        const ruleStats = applicationData.analysisV2.rule_statistics[ruleId];
        const rule = applicationData.rules.filter((r) => r.id == ruleId)[0];

        return (
          <MDBox m={1}>
            <Card>
              <MDBox m={1}>
                <RuleTile statistics={ruleStats} rule={rule} richCats={richCats} apiCalls={apiCalls} onShowTransactions={() => console.log("Showing transactions")} />
              </MDBox>
            </Card>
          </MDBox>
        );
      });
  }

  function generateItem(name, content, withButton, forAccount) {
    var callbackFunction = () => {
      setForAccount(forAccount);
      setProposedSearchText(content);
      setShowClassifier(true);
      setShowSpecificRule(false);

      // It is possible that the button is clicked for the purpose of an externalDemo
      // In that case the external demo info is forwarded into the demo for the pop-up and the demo for this page is stopped
      setShowDemoForPopup(externalDemo);
      setShowDemo(false);
    };

    if (!withButton) {
      callbackFunction = undefined;
    }

    return (
      <Grid item xl={4} md={6} xs={12} key={"unqiue_item_for" + name} className={"RowFor" + name}>
        <MDBox sx={{ height: "100%" }}>
          <TransactionProperty name={name} content={content} buttonCallback={callbackFunction} />
        </MDBox>
      </Grid>
    );
  }

  var deleteButton = "";
  if (transaction.manual) {
    deleteButton = (
      <MDBox ml={0.2}>
        <MDButton
          variant="outlined"
          color="error"
          endIcon={<Icon fontSize="small">delete</Icon>}
          onClick={(ev) => {
            apiCalls.deleteManualTransactionById(transaction.id);
            onClose();
          }}
        >
          Delete manual transaction
        </MDButton>
      </MDBox>
    );
  }

  return (
    <CloseableCard onClose={onClose} onHelp={() => setShowDemo("General")}>
      <Grid container className="TransactionDetailsForRule">
        <Grid item xs={12}>
          <MDBox mb={2} mr={1} ml={2} mt={3}>
            <Grid container justifyContent={"space-between"} alignItems="stretch" spacing={3}>
              {generateItem("Amount", transaction.amount + " EUR")}
              {generateItem("Time", transaction.timestamp.toLocaleDateString(), false, false)}
              {transaction.account != undefined && generateItem("Account", transaction.account, true, true)}
              {(transaction.counter_part_name != undefined || (showDemo != undefined && showDemo)) && generateItem("Counterpart Name", transaction.counter_part_name, true, false)}
              {transaction.counter_part_account != undefined && generateItem("Counterpart Account", transaction.counter_part_account, false, false)}
              {transaction.comments != undefined && generateItem("Comments", transaction.comments, true, false)}
              {transaction.description != undefined && generateItem("Description", transaction.description, true, false)}
            </Grid>
            <MDBox mt={4}>
              <Grid container justifyContent="space-evenly">
                <Grid item>
                  <MDBox m={1}>
                    <MDButton
                      variant="outlined"
                      color="info"
                      endIcon={<AddCardIcon />}
                      fullWidth
                      onClick={(ev) => {
                        setProposedSearchText("");
                        setShowClassifier(true);
                        setShowSpecificRule(true);

                        // It is possible that the button is clicked for the purpose of an externalDemo
                        // In that case the external demo info is forwarded into the demo for the pop-up and the demo for this page is stopped
                        setShowDemoForPopup(externalDemo);
                        setShowDemo(false);
                      }}
                      className="AssignCategoryButton"
                    >
                      Assign category
                    </MDButton>
                  </MDBox>
                </Grid>

                {deleteButton != "" && <Grid item>{deleteButton}</Grid>}
                <Grid item>
                  <MDBox m={1}>
                    <MDButton fullWidth variant="outlined" color="info" endIcon={<Icon>view_list</Icon>} onClick={(ev) => setShowRules(true)} className="ShowMatchingRulesButton">
                      Show matching rules
                    </MDButton>
                  </MDBox>
                </Grid>
                <Grid item>
                  <MDBox m={1}>
                    <MDButton
                      variant="outlined"
                      color="info"
                      fullWidth
                      endIcon={<Icon>not_interested</Icon>}
                      onClick={(ev) => {
                        const richCats = new RichCategories(applicationData.categories);
                        var ignoreCategory = richCats.getCategoryByName("Ignore");
                        if (ignoreCategory == undefined) {
                          ignoreCategory = richCats.getCategoryByName("Transfers");
                        }

                        apiCalls.createNewSpecificRuleForTransaction(transaction.id, ignoreCategory.id);
                        onClose();
                      }}
                      className="IgnoreTransactionButton"
                    >
                      Ignore transaction
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
      <Dialog fullWidth maxWidth="xl" open={showRules}>
        <CloseableCard onClose={() => setShowRules(false)}>
          {applicationData.isLoadingAnalysisV2 && (
            <MDBox ml={2}>
              <CircularProgress />
            </MDBox>
          )}
          {!applicationData.isLoadingAnalysisV2 && <MDBox ml={2}>{rules}</MDBox>}
        </CloseableCard>
      </Dialog>
      <Dialog fullWidth maxWidth="xl" open={showClassifier}>
        <Classifier
          apiCalls={apiCalls}
          categories={applicationData.categories}
          onNewRuleCreated={() => {
            setShowClassifier(false);
            onClose();
          }}
          proposeSingle={showSpecificRule || externalDemo == "Single"}
          proposedSearchText={proposedSearchText}
          transaction={transaction}
          forAccount={forAccount}
          onCancel={() => {
            setShowClassifier(false);
            // If the classifier gets closed and we were doing a demo, lets close this popup then
            if (externalDemo?.length > 0) {
              onClose();
            }
          }}
          externalDemo={showDemoForPopup?.length > 0}
        />
      </Dialog>

      <FDDemo
        run={showDemo?.length > 0}
        steps={getRelevantSteps()}
        onFinished={() => {
          // On Finished, we create a fake transaction and open the pop up
          if (externalDemo) {
            setProposedSearchText("John Doe");
            setShowClassifier(true);
          } else {
            // Else, we just stop the demo of this page
            setShowDemo(false);
          }
        }}
        onSkipped={() => {
          // If the skip button is clicked, stop showing the demo. If it was triggered due to an external demo, close this popup
          setShowDemo(false);
          if (externalDemo?.length > 0) {
            onClose();
          }
        }}
      />
    </CloseableCard>
  );
}

export default TransactionDetailsForRule;
