import { Button, ButtonBase, Card, Dialog, Grid, Modal } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useCSVReader } from "react-papaparse";
import { CSSProperties } from "react";
import Papa from "papaparse";
import { ApiClient } from "fdc_expenses";
import MDBox from "components/MDBox";
import DetailsCard from "../ruleanalysis/detailscard";
import MemoryIcon from "@mui/icons-material/Memory";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import { CsvFileParser } from "./CsvFileParser";
import { TransactionsFilesToolbar } from "./TransactionsFilesToolbar";
import { useLocation } from "react-router-dom";
import ReactJoyride from "react-joyride";
import { stopJoyRideWhenFinished, demoCallbacks } from "utils/JoyRideUtils";
import MDTypography from "components/MDTypography";
import FDDemo from "utils/FDDemo";

const steps = [
  {
    target: ".NavBar",
    title: "CSV Files",
    content: (
      <MDTypography variant="button">
        This page can be used to upload and parse CSV files. Mind you, two steps (uploading and parsing) are required to properly import transactions in Budgex
      </MDTypography>
    ),
    disableBeacon: true,
  },
  {
    target: ".UploadButton",
    content: <MDTypography variant="button">Using this button, you can upload a new CSV File</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".CSVFile_0",
    content: <MDTypography variant="button">These cards represent the currently uploaded CSV Files</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".Number_Of_Parsed_Transactions_File_0",
    content: <MDTypography variant="button">This indicates how many transactions are currently succesfully imported in Budgex</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".CSVFileParse_0",
    content: <MDTypography variant="button">By clicking this button, a pop-up is opened that allows you to import the transactions from the CSV file into Budgex</MDTypography>,
    disableBeacon: true,
    locale: { last: "Lets parse that CSV" },
  },
];

function TransactionsFilesPage({ csvFiles, manualAccountNames, callbacks, apiCalls, apiClient }) {
  const [csvDetails, setCsvDetails] = useState(null);
  const [showDemo, setShowDemo] = useState(false);

  useEffect(() => apiCalls.updateAnalysisV2IfNeeded());

  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes("transactionsfiles")) {
      apiCalls.setHelpCallback(() => () => setShowDemo(true));
      if (location.search.includes("showHelp")) {
        setShowDemo(true);
      }
    }
  }, [location]);

  const csvFilesToShow = csvFiles.slice();
  if (showDemo) {
    csvFilesToShow.push({ id: "Demo", number_of_transactions: 10, file_name: "Fake File Name for Demo.csv" });
  }

  function showExistingFiles() {
    return csvFilesToShow.map((csvFile, index) => {
      return (
        <Grid item xs={12} xl={6} key={"TranactionsFile" + csvFile.id} className={"CSVFile_" + index}>
          <MDBox m={1}>
            <Card>
              <MDBox mb={2}>
                <DetailsCard
                  classNameSuffix={"File_" + index}
                  title={csvFile.file_name}
                  keyValuePairs={{
                    id: csvFile.id,
                    "Number Of Parsed Transactions": csvFile.number_of_transactions,
                  }}
                ></DetailsCard>
                <MDBox mt={3}>
                  <Grid container display="flex" justifyContent={"center"}>
                    <Grid item className={"CSVFileParse_" + index}>
                      <Button
                        startIcon={<MemoryIcon />}
                        onClick={(ev) => {
                          function handleInfo(error, data, response) {
                            console.log(data);
                            setCsvDetails(data);
                          }
                          apiClient.getTransactionFileById(csvFile.id, handleInfo);
                        }}
                      >
                        Parse CSV
                      </Button>
                    </Grid>
                    <Grid>
                      <Button startIcon={<ClearIcon />} onClick={(ev) => apiCalls.resetTransactionsForTransactionFileById(csvFile.id)}>
                        Clear Transactions
                      </Button>
                    </Grid>

                    <Grid>
                      <Button startIcon={<DeleteIcon />} onClick={(ev) => apiCalls.deleteCsvFile(csvFile.id)}>
                        Delete
                      </Button>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          </MDBox>
        </Grid>
      );
    });
  }

  return (
    <div class="TransactionFilesPage">
      <MDBox mb={3}>
        <TransactionsFilesToolbar apiCalls={apiCalls} />
      </MDBox>

      <Grid spacing={1} container>
        {showExistingFiles()}
      </Grid>
      <Dialog open={csvDetails !== null} fullWidth={true} maxWidth={"lg"}>
        <CsvFileParser
          apiCalls={apiCalls}
          csvFile={csvDetails}
          manualAccountNames={manualAccountNames}
          apiClient={apiClient}
          onClose={() => {
            setCsvDetails(null);
            setShowDemo(false);
          }}
          externalDemo={showDemo}
        />
      </Dialog>

      <FDDemo
        run={showDemo}
        steps={steps}
        onFinished={() => {
          // On Finished, we open the parsing demo
          setCsvDetails(csvFilesToShow[0]);
        }}
        onSkipped={() => {
          // On skipped, we stop demoing
          setShowDemo(false);
        }}
      />
      {/* 
      <ReactJoyride
        styles={{
          options: {
            arrowColor: "#e3ffeb",
            primaryColor: "#000",
            textColor: "#004a14",
            width: 900,
            zIndex: 1000000,
          },
        }}
        callback={(d) =>
          demoCallbacks(
            d,
            () => {
              // On Finished, we open the parsing demo
              setCsvDetails(csvFilesToShow[0]);
            },
            () => {
              // On skipped, we stop demoing
              setShowDemo(false);
            },
            () => {}
          )
        }
        disableBeacon={true}
        run={showDemo}
        debug={true}
        steps={steps}
        continuous={true}
        showSkipButton={true}
        disableScrolling={true}
      /> */}
    </div>
  );
}

export default TransactionsFilesPage;
