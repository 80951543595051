import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

export function Product({ product, apiClient, callbacks, onClose, validatedPromo }) {
  return (
    <Card>
      <MDBox m={1}>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <MDTypography variant="h6">{product.name}</MDTypography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <MDBox m={1}>
              <Grid container justifyContent="center">
                {validatedPromo == undefined && <MDTypography variant="caption">{product.price} EUR</MDTypography>}
                {validatedPromo != undefined && (
                  <MDTypography variant="caption">
                    {product.price} - {validatedPromo.price_reduction_for_receiver} = {product.price - validatedPromo.price_reduction_for_receiver} EUR
                  </MDTypography>
                )}
              </Grid>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <MDButton
              color="dark"
              onClick={() => {
                const options = {};
                if (validatedPromo != undefined) {
                  options["optionalPromoId"] = validatedPromo.uuid;
                }
                apiClient.buyAProduct(product.id, options, (error, data, response) => {
                  window.location.replace(data);
                  callbacks.onProductBought();
                  onClose();
                });
              }}
              fullWidth
            >
              Buy
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}
