import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Badge, Chip } from "@mui/material";

const { default: TitledCard } = require("components/TitledCard/titled_card");

function RuleTable(props) {
  function getRuleById(ruleId) {
    if (props.rules) {
      const relevantRules = props.rules.filter((rule) => rule.id == ruleId);
      if (relevantRules.length > 0) {
        return relevantRules[0];
      } else {
        return undefined;
      }
    }
  }

  function getKeywordsForCondition(condition) {
    if (condition.amount_condition) {
      return getKeywordsForAmountCondition(condition.amount_condition);
    } else if (condition.date_condition) {
      return getKeywordsForDateCondition(condition.date_condition);
    } else if (condition.string_condition) {
      return getKeywordsForStringCondition(condition.string_condition);
    } else if (condition.and_condition) {
      return getKeywordsForAndCondition(condition.and_condition);
    } else if (condition.or_condition) {
      return getKeywordsForOrCondition(condition.or_condition);
    } else {
      return [];
    }
  }

  function getKeywordsForAmountCondition(condition) {
    const keyWords = [];
    console.log(condition);
    if (condition.lower_boundary != undefined) {
      keyWords.push("Amount >= " + condition.lower_boundary);
    }
    if (condition.upper_boundary != undefined) {
      keyWords.push("Amount <= " + condition.upper_boundary);
    }
    console.log(keyWords);
    return keyWords;
  }

  function getKeywordsForDateCondition(condition) {
    return ["Date Condition"];
  }

  function getKeywordsForStringCondition(condition) {
    return [condition.query];
  }

  function getKeywordsForAndCondition(condition) {
    const keywords = [];

    for (let i = 0; i < condition.underlying_conditions.length; i++) {
      const keyWordsForUnderlyingCondition = getKeywordsForCondition(
        condition.underlying_conditions[i]
      );
      for (let j = 0; j < keyWordsForUnderlyingCondition.length; j++) {
        keywords.push(keyWordsForUnderlyingCondition[j]);
      }
    }
    return keywords;
  }

  function getKeywordsForOrCondition(condition) {
    const keywords = [];

    for (let i = 0; i < condition.underlying_conditions.length; i++) {
      const keyWordsForUnderlyingCondition = getKeywordsForCondition(
        condition.underlying_conditions[i]
      );
      for (let j = 0; j < keyWordsForUnderlyingCondition.length; j++) {
        keywords.push(keyWordsForUnderlyingCondition[j]);
      }
    }
    return keywords;
  }

  function getKeywords(rule) {
    if (rule) {
      return getKeywordsForCondition(rule.condition).join(", ");
    } else {
      return "";
    }
  }

  function getCategoryById(catId) {
    const relevantCats = props.categories.filter((cat) => cat.id == catId);
    if (relevantCats.length > 0) {
      return relevantCats[0];
    }
  }

  function getRulesInTableForm() {
    const createRow = function (rs) {
      const rule = getRuleById(rs.rule_id);
      const category = getCategoryById(rule.categoryId);
      return {
        rule_id_for_button: rs.rule_id,
        rule_id: rs.rule_id,
        matches: rs.matched_transaction_ids.length,
        conflicts: rs.conflicted_transaction_ids.length,
        category: category?.name,
        keywords: getKeywords(rule),
      };
    };

    var rows = [];
    if (props.analysisResult) {
      rows = props.analysisResult.rule_statistics
        .filter((rs) => getRuleById(rs.rule_id))
        .map(createRow);
    }

    function showKeywords(keyWords) {
      const separateKeyWords = Array.from(new Set(keyWords.split(",")));

      const chips = separateKeyWords.map((kw) => {
        return <Chip key={kw} label={kw} />;
      });
      return <div key={keyWords}>{chips}</div>;
    }

    function deleteRule(ruleId) {
      props.apiClient.deleteRuleById(ruleId, () => props.callbacks.onRuleDeleted());
    }

    const tableData = {
      columns: [
        {
          Header: "Delete Rule",
          accessor: "rule_id_for_button",
          Cell: ({ value, row }) => {
            return (
              <MDButton onClick={(event) => deleteRule(value)}>
                <DeleteIcon />
              </MDButton>
            );
          },
        },
        { Header: "Category", accessor: "category" },
        { Header: "Rule ID", accessor: "rule_id" },
        { Header: "Number of matches", accessor: "matches" },
        { Header: "Number of conflicts", accessor: "conflicts" },
        {
          Header: "Rule Keywords",
          accessor: "keywords",
          Cell: ({ value, row }) => {
            return showKeywords(value);
          },
        },
      ],
      rows: rows,
    };

    return tableData;
  }

  return <DataTable table={getRulesInTableForm()} entriesPerPage={false} />;
}

export default RuleTable;
