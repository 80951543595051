/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DetailsCard from "../ruleanalysis/detailscard";
import React from "react";
import { useEffect, useMemo, useState } from "react";

// @mui material components

import { Button, Divider, Icon, MenuItem, Select, Dialog, IconButton, Stack, DialogContent } from "@mui/material";

const FdcExpenses = require("fdc_expenses");
import MDAvatar from "components/MDAvatar";
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";
import BankLinkCreatorV2 from "./BankLinkCreatorV2";
import { useLocation } from "react-router-dom";
import GenericCard from "components/TitledCard/GenericCard";
import { DateTime } from "luxon";
import FDDemo from "utils/FDDemo";
import { ReportAProblem } from "../reportproblems/ReportAProblem";
import { CloseableCard } from "components/TitledCard/GenericCard";

const steps = [
  {
    target: ".NavBar",
    title: "Bank link",
    content: (
      <MDTypography variant="button">This page allows you to sync your transactions from your bank account or manage these automatic. It is possible to link more than one bank account</MDTypography>
    ),
    disableBeacon: true,
  },
  {
    target: ".BankLink_0",
    content: <MDTypography variant="button">These cards represent the automatic link with your different bank accounts</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".StatusButton_0",
    content: <MDTypography variant="button">This indicates the status of the link with your bank account. The status can be: Active, Expired or 'Waiting to be confirmed'</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".Last_Refresh_BankLink_0",
    content: <MDTypography variant="button">Transactions are automatically refreshed on a daily basis. This field shows when the last succesful sync happened</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".Delete_0",
    content: <MDTypography variant="button">You can at each point in time delete a bank link. This does not delete all transactions that are already imported in Budgex</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".AddBankLinkButton",
    title: "Create new banklink",
    content: (
      <Stack>
        <MDTypography variant="button">Click on this button to create a new link with your bank account</MDTypography>
        <MDBox mt={2}>
          <MDTypography variant="button" fontWeight="medium">
            Click on the button to continue the demo
          </MDTypography>
        </MDBox>
      </Stack>
    ),
    disableBeacon: true,
    spotlightClicks: true,
    hideCloseButton: true,
    disableOverlayClose: true,
    hideFooter: true,
  },
];

function BankLink({ bankLink, apiCalls, isUpdating, index }) {
  const [showErrorReport, setShowErrorReport] = useState(false);

  function getStatus() {
    if (bankLink.confirmed && bankLink.status == "LN") {
      return <StatusCell icon="done" color="success" status="Active" />;
    }
    if (bankLink.status == "RJ") {
      return <StatusCell icon="update_disabled" color="warning" status="Rejected" />;
    }
    if (bankLink.confirmed && bankLink.status == "EX") {
      return <StatusCell icon="update_disabled" color="warning" status="Expired" />;
    } else if (!bankLink.confirmed) {
      return <StatusCell icon="replay" color="warning" status="Waiting to be confirmed" />;
    }
  }

  return (
    <Card>
      <GenericCard
        topleft={
          <MDBox ml={1}>
            <MDAvatar size="xl" src={bankLink.logo}></MDAvatar>
          </MDBox>
        }
        topright={
          <MDBox mr={1} mt={1}>
            <div class={"StatusButton_" + index}>{getStatus()}</div>
          </MDBox>
        }
      >
        <Grid container>
          <Grid item xs={12}>
            <DetailsCard
              classNameSuffix={"BankLink_" + index}
              keyValuePairs={{
                "Bank ID": bankLink.bank_id,
                "Last Refresh": bankLink.last_successful_usage?.toLocaleDateString() || "/",
                "Creation Time": bankLink.created_at?.toLocaleDateString(),
              }}
            />
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}>
            <MDBox mt={3}>
              <Grid container justifyContent={"space-around"}>
                <div class={"Delete_" + index}>
                  <Button
                    endIcon={<Icon>delete</Icon>}
                    onClick={(ev) => {
                      apiCalls.deleteBankLink(bankLink.id);
                    }}
                  >
                    Stop sync and delete
                  </Button>
                </div>

                <Button
                  endIcon={<Icon>report</Icon>}
                  onClick={(ev) => {
                    setShowErrorReport(true);
                  }}
                >
                  Report a problem
                </Button>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </GenericCard>

      <Dialog open={showErrorReport} fullWidth={true} maxWidth={"xl"}>
        <CloseableCard onClose={() => setShowErrorReport(false)} bgColor="#FFFFFF" title="Report a Problem">
          <ReportAProblem onProblemSubmit={() => setShowErrorReport(false)} apiCalls={apiCalls} requisitionId={bankLink.requisition_id} />
        </CloseableCard>
      </Dialog>
    </Card>
  );
}

function BankLinkPageV2({ applicationData, apiCalls, apiClient }) {
  const location = useLocation();
  const [showDemo, setShowDemo] = useState(false);
  const [showDemoForPopup, setShowDemoForPopup] = useState(false);

  useEffect(() => {
    if (location.pathname.includes("banklink")) {
      apiCalls.setHelpCallback(() => () => setShowDemo(true));
      if (location.search.includes("showHelp")) {
        setShowDemo(true);
      }
    }
  }, [location]);

  const bankLinksToRender = applicationData.bankLinks.slice();
  if (showDemo) {
    bankLinksToRender.push({
      bank_id: "Fake bank for demo purposes",
      confirmed: true,
      last_successful_usage: DateTime.now().toJSDate(),
      created_at: DateTime.now().toJSDate(),
      logo: "https://cdn.nordigen.com/ais/BELFIUS_GKCCBEBB.png",
      status: "LN",
    });
  }

  const renederdBankLinks = bankLinksToRender.map((bl, index) => (
    <Grid xs={12} xl={6} item key={"BankLink_" + index}>
      <MDBox m={1}>
        <div class={"BankLink_" + index}>
          <BankLink bankLink={bl} apiCalls={apiCalls} isUpdating={applicationData.isRefreshingBankLink} index={index} />
        </div>
      </MDBox>
    </Grid>
  ));
  const [addNewOpen, setAddNewOpen] = useState(location.search.includes("showPopup"));

  return (
    <div class="BankLinkPage">
      <Grid container>
        <Grid xs={12} item key="RenderedBankLinks">
          <MDBox>
            <Grid container>{renederdBankLinks}</Grid>
          </MDBox>
        </Grid>
        <Grid xs={12} item key="AddButton">
          <MDBox mt={3}>
            <div class="AddBankLinkButton">
              <Card>
                <Grid container justifyContent={"space-around"}>
                  <Button
                    endIcon={<Icon>add</Icon>}
                    onClick={(ev) => {
                      setShowDemoForPopup(showDemo);
                      setShowDemo(false);
                      setAddNewOpen(true);
                    }}
                  >
                    Add new BankLink
                  </Button>
                </Grid>
              </Card>
            </div>
          </MDBox>
        </Grid>

        <Dialog open={addNewOpen} fullWidth={true} maxWidth={"xl"}>
          <BankLinkCreatorV2
            apiCalls={apiCalls}
            apiClient={apiClient}
            externalDemo={showDemoForPopup}
            onClose={() => {
              setShowDemo(false);
              setShowDemoForPopup(false);
              setAddNewOpen(false);
            }}
          />
        </Dialog>

        <FDDemo
          run={showDemo}
          steps={steps}
          onFinished={() => {
            // On Finished, we open the parsing demo. Finishing the demo will happen upon closing of the popup
            setAddNewOpen(true);
          }}
          onSkipped={() => {
            // On skipped, we stop demoing
            setShowDemo(false);
          }}
        />
      </Grid>
    </div>
  );
}

export default BankLinkPageV2;
