export function parseGraphTypeDefinition(serialized_object, richCats) {
  const parsedObject = JSON.parse(serialized_object);

  if (parsedObject.type == "TrendGraphDefinition") {
    return TrendGraphDefinition.fromJson(parsedObject, richCats);
  } else if (parsedObject.type == "BarTrendGraphDefinition") {
    return BarTrendGraphDefinition.fromJson(parsedObject, richCats);
  } else if (parsedObject.type == "BarGraphDefinition") {
    return BarGraphDefinition.fromJson(parsedObject, richCats);
  } else if (parsedObject.type == "PieGraphDefinition") {
    return PieGraphDefinition.fromJson(parsedObject, richCats);
  }
}

export function serializeGraphTypeDefinition(gtDefinition) {
  const typeObj = { type: gtDefinition.getType() };
  const contentObj = gtDefinition.toJson();

  return JSON.stringify(Object.assign({}, typeObj, contentObj));
}

export class TrendGraphDefinition {
  constructor(averagingDays, scalar) {
    this.averagingDays = averagingDays;
    this.scalar = scalar;
  }

  getType() {
    return "TrendGraphDefinition";
  }

  toJson() {
    return { averagingDays: this.averagingDays, scalar: this.scalar };
  }

  static fromJson(serialized, richCats) {
    return new TrendGraphDefinition(serialized.averagingDays, serialized.scalar);
  }
}

export class BarTrendGraphDefinition {
  constructor(splitPeriod) {
    this.splitPeriod = splitPeriod;
  }

  toJson() {
    return { splitPeriod: this.splitPeriod };
  }

  getType() {
    return "BarTrendGraphDefinition";
  }

  static fromJson(serialized, richCats) {
    return new BarTrendGraphDefinition(serialized.splitPeriod || "Monthly");
  }
}

export class BarGraphDefinition {
  constructor() {}

  toJson() {
    return {};
  }

  getType() {
    return "BarGraphDefinition";
  }

  static fromJson(serialized, richCats) {
    return new BarGraphDefinition();
  }
}

export class PieGraphDefinition {
  constructor() {}

  toJson() {
    return {};
  }

  getType() {
    return "PieGraphDefinition";
  }

  static fromJson(serialized, richCats) {
    return new PieGraphDefinition();
  }
}
