import { Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import RichCategories from "domain/RichCategories";
import { useEffect, useRef, useState } from "react";

import { Canvas, Label, Node, Edge, Port, MarkerArrow } from "reaflow";
// You can import the default tree with dnd context

function CategoryTreeV3({ categories, treeWidth, treeHeight }) {
  const ref = useRef(null);

  const [rootNode, setRootNode] = useState();

  const richCats = new RichCategories(categories);

  const relevantCategories = rootNode == undefined ? categories : [richCats.getCategoryById(rootNode)].concat(richCats.getAllRecursiveChildrenOf(richCats.getCategoryById(rootNode)));
  const relevantCategoryIds = relevantCategories.map((cat) => cat.id);

  const nodes = relevantCategories.map((cat) => {
    return { id: cat.id, text: cat.name };
  });
  const edges = relevantCategories
    .filter((cat) => cat.parent_id && relevantCategoryIds.includes(cat.parent_id))
    .map((cat) => {
      return { id: cat.id + "=>" + cat.parent_id, from: cat.parent_id, to: cat.id };
    });

  useEffect(() => {
    console.log("Fitting to canvas", treeWidth, treeHeight);
    ref.current.fitCanvas();
    setTimeout(() => {
      ref.current.fitCanvas();
    }, 400);
  }, [treeWidth, treeHeight, rootNode]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container justifyContent="flex-end">
          {rootNode != undefined && (
            <MDButton
              endIcon={<Icon>clear</Icon>}
              color="dark"
              onClick={(ev) => {
                setRootNode(undefined);
              }}
            >
              Reset Tree
            </MDButton>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Canvas
          edge={<Edge className="edge" selectable={false} upsertable={false} />}
          node={
            <Node
              draggable={false}
              linkable={false}
              onClick={(ev, data) => {
                setRootNode(data.id);
              }}
              selectable={true}
              style={{ stroke: "#1a192b", fill: "#f1f2f9", strokeWidth: 3 }}
              label={<Label style={{ fill: "black" }} />}
              port={<Port style={{ fill: "blue", stroke: "white" }} rx={2} ry={2} />}
            />
          }
          disabled={false}
          ref={ref}
          direction="right"
          fit={true}
          maxWidth={treeWidth}
          maxHeight={treeHeight}
          nodes={nodes}
          edges={edges}
        />
      </Grid>
    </Grid>
  );
}

export default CategoryTreeV3;
