var sma = require("sma");
// DatasetsConfig consists of a list of {label: , category_id, pure: boolean}

function generateChartData(timeseriesData, configuration) {
  // TODO: Add subsampling for performance reasons.
  // Eg. At most 500 datapoints per TS ?
  if (timeseriesData && timeseriesData.times) {
    const chartData = {
      labels: timeseriesData.times.slice(configuration.averagingDays || 365).map((time) => time.toLocaleDateString()),

      datasets: configuration.graphItems
        .filter((configItem) => {
          const timeseriesForConfigItem = timeseriesData.timeseries.filter((ts) => configItem.categoryIds.includes(ts.category.id));

          return timeseriesForConfigItem.length > 0;
        })
        .map((configItem) => {
          const relevantTses = timeseriesData.timeseries
            .filter((ts) => configItem.categoryIds.includes(ts.category.id))
            .map((tsData) => {
              if (configItem.pure) {
                return tsData.pure_values;
              } else {
                return tsData.values;
              }
            });

          const totalTs = [];
          for (var i = 0; i < relevantTses[0].length; i++) {
            const totalValue = relevantTses.map((ts) => ts[i]).reduce((a, b) => a + b, 0);
            totalTs.push(totalValue);
          }

          return {
            borderColor: configItem.color || "#1122ff",
            label: configItem.label,
            data: sma(totalTs, configuration.averagingDays || 365)
              .map((v) => (v * 365) / 12)
              .map((v) => {
                if (configItem.invert) {
                  return -v;
                } else {
                  return v;
                }
              }),
          };
        }),
    };

    return chartData;
  } else {
    return { labels: [], datasets: [] };
  }
}

export default generateChartData;
