/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import React, { useEffect, useState } from "react";

import Tree from "react-d3-tree";
import RichCategories from "domain/RichCategories";
import MDButton from "components/MDButton";
import { Icon } from "@mui/material";

function CategoryNode({ nodeData }) {
  return <div>{"A: " + nodeData.name}</div>;
}

function CategoryTree({ categories, applicationData }) {
  const [treeContainer, setTreeContainer] = useState();
  const [translation, setTranslation] = useState({ x: 0, y: 0 });
  const [rootNode, setRootNode] = useState();

  const richCats = new RichCategories(categories);

  function getRootCategories() {
    if (rootNode != undefined) {
      return [rootNode];
    } else {
      return categories.filter((cat) => !cat.parent_id);
    }
  }

  function getChildrenCategories(parentId) {
    return categories.filter((cat) => cat.parent_id == parentId);
  }

  function getCategoryTreeDataFor(category) {
    return {
      name: category.name,
      id: category.id,
      children: getChildrenCategories(category.id).map((cat) => getCategoryTreeDataFor(cat)),
    };
  }

  function getCategoryTreeData() {
    if (categories) {
      if (rootNode == undefined) {
        return {
          name: "ROOT",
          children: getRootCategories().map((cat) => getCategoryTreeDataFor(cat)),
        };
      } else {
        return getCategoryTreeDataFor(rootNode);
      }
    } else {
      return {};
    }
  }

  function adaptTranslationOnTreeContainer() {
    console.log("Re-adapting");
    if (treeContainer) {
      if (getOrientation() == "horizontal") {
        const dimensions = treeContainer.getBoundingClientRect();
        setTranslation({ x: 30, y: dimensions.height / 2 });
      } else {
        const dimensions = treeContainer.getBoundingClientRect();
        setTranslation({ x: dimensions.width / 2, y: 30 });
      }
    }
  }

  function isWiderThanHeight() {
    if (treeContainer) {
      const dimensions = treeContainer.getBoundingClientRect();

      if (dimensions.width > dimensions.height) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  function getOrientation() {
    return "horizontal";
  }

  useEffect(() => adaptTranslationOnTreeContainer(), [treeContainer, applicationData.windowWidth, applicationData.windowHeight, rootNode]);

  return (
    <Grid container sx={{ height: "100%", width: "100%" }}>
      {rootNode != undefined && (
        <Grid item xs={12}>
          <Grid container justifyContent={"stretch"}>
            <MDButton
              variant={"contained"}
              color="dark"
              fullWidth
              onClick={(ev) => {
                setRootNode(undefined);
              }}
              endIcon={<Icon>clear</Icon>}
            >
              Show all categories
            </MDButton>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} sx={{ height: "100%", width: "100%" }}>
        <div ref={(tc) => setTreeContainer(tc)} style={{ height: "100%", width: "100%" }}>
          <Tree
            data={getCategoryTreeData()}
            orientation={getOrientation()}
            pathFunc="diagonal"
            separation={{ siblings: 0.25, nonSiblings: 0.5 }}
            zoom={0.5}
            nodeSize={{ x: 200, y: 180 }}
            collapsible={false}
            translate={translation}
            onNodeClick={(ev) => {
              if (ev.data.id) {
                setRootNode(richCats.getCategoryById(ev.data.id));
              }
            }}
            nodeLabelComponent={{
              render: <CategoryNode />,
              foreignObjectWrapper: {
                style: {
                  background: "lightblue",
                  border: "1px solid black",
                  width: "24 px",
                  height: "24 px",
                },
              },
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
}

export default CategoryTree;
