/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

import { useState, useEffect, useMemo, useRef } from "react";

import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
// react-router components
import { Routes, Route, Navigate, useLocation, useSearchParams, useNavigate } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";

import createCache from "@emotion/cache";

// Material Dashboard 2 PRO React routes
import routes from "routes";

import "../node_modules/react-vis/dist/style.css";
import "rsuite/dist/rsuite.min.css";
// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import brandBudgex from "assets/images/logos/cropped-cropped-logo-budgex-v3.png";
import logoBudgex from "assets/images/logo_budgex_v0.png";
import LoginPage from "layouts/pages/loginpage";
import { useCookies } from "react-cookie";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { TimeseriesAggregation } from "fdc_expenses";
import { Alert, AlertTitle, Backdrop, CircularProgress, Grid, IconButton, LinearProgress, Snackbar } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { GoogleOAuthProvider } from "@react-oauth/google";
import MDTypography from "components/MDTypography";
import { DateTime } from "luxon";

import ReactGA from "react-ga4";
const TRACKING_ID = "G-KWDZHSXWE8";
ReactGA.initialize(TRACKING_ID);

import RedditPixel from "react-reddit-pixel";

const FdcExpenses = require("fdc_expenses");

function Alerts({ alerts, clearAlertById }) {
  const renderedAlerts = alerts.map((alert, index) => {
    return (
      <Grid item xs={12} key={"Alert with Id " + alert.id}>
        <MDBox mt={1} mb={1}>
          <Snackbar open={true}>
            <Alert
              severity={alert.severity}
              sx={{ width: "100%" }}
              action={
                <IconButton onClick={(ev) => clearAlertById(alert.id)}>
                  <Icon>clear</Icon>
                </IconButton>
              }
            >
              {alert.title != undefined && (
                <AlertTitle>
                  <MDTypography variant="h6">{alert.title}</MDTypography>
                </AlertTitle>
              )}
              {alert.message}
            </Alert>
          </Snackbar>
        </MDBox>
      </Grid>
    );
  });

  const alertsAsSnackBars = alerts.map((alert, index) => {
    return <Snackbar open={true} message={alert.message} />;
  });

  if (alerts.length > 0) {
    // return alertsAsSnackBars;
    return (
      <MDBox mt={1} mb={2}>
        <Grid container>{renderedAlerts}</Grid>
      </MDBox>
    );
  } else {
    return <div />;
  }
}

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor, transparentSidenav, whiteSidenav, darkMode } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const [helpCallback, setHelpCallback] = useState();
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [forceExpand, setForceExpand] = useState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [windowRatio, setWindowRatio] = useState(window.innerWidth / window.innerHeight);
  const [twoFaEnabled, setTwoFaEnabled] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const debouncedFunction = debounce(function handleResize() {
      const newRatio = window.innerWidth / window.innerHeight;

      setWindowRatio(newRatio);

      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    }, 200);

    window.addEventListener("resize", debouncedFunction);
    return () => {
      window.removeEventListener("resize", debouncedFunction);
    };
  }, ["A"]);

  const createNewApiClient = function () {
    const defaultClient = FdcExpenses.ApiClient.instance;
    const OAuth2PasswordBearer = defaultClient.authentications.OAuth2PasswordBearer;

    const apiClient = new FdcExpenses.ApiClient();

    console.log("Creating client for", process.env.REACT_APP_API_URL);

    apiClient.basePath = process.env.REACT_APP_API_URL;
    // apiClient.basePath = "http://127.0.0.1:8001"

    if (cookies.token) {
      apiClient.authentications.OAuth2PasswordBearer.accessToken = cookies.token;
    }

    const apiInstance = new FdcExpenses.DefaultApi(apiClient);

    return apiInstance;
  };

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  function trackSuccesfullPurchase() {
    // This method is only called, after we got back with a ?paymentInitiated
    // So if we now observe a pro account, the we probably just paid
    if (hasProAccount) {
      RedditPixel.init("t2_vycz8dl4", {
        debug: false, // set true to enable logs
      });
      RedditPixel.track("Purchase");
    }
  }

  const location = useLocation();
  useEffect(() => {
    if (location.search.includes("paymentInitiated")) {
      // We just returned from payment, so lets refresh the pro-page aggressively
      for (let counter = 0; counter < 60; counter++) {
        setTimeout(function () {
          console.log("Refreshing");
          updateProAccountFromApi();
          trackSuccesfullPurchase();
        }, 3.5 * counter * 1000);
      }
    }
    if (location.search.includes("demoPlatform")) {
      onLogout();

      apiClient.login("Demo", "d1d1d1", {}, (error, data, response) => {
        if (!error) {
          onLogin(data.access_token);
        }
      });
      navigate({ pathname: "/" });
    }
  }, [location]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;

    ReactGA.set({ user: applicationData.userDetails?.name });

    ReactGA.send({ hitType: "pageview", page: pathname });
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  function trackLoginWithReddit() {
    RedditPixel.init("t2_vycz8dl4", {
      debug: false, // set true to enable logs
    });
    RedditPixel.track("SignUp");
  }

  function trackLogin() {
    // trackLoginWithFacebook();
    trackLoginWithReddit();
  }

  function onLogin(information) {
    trackLogin();
    setCookie("token", information, { path: "/" });
  }

  function isLoggedIn() {
    return cookies.token != undefined;
  }

  const apiClient = useMemo(() => createNewApiClient(), [cookies.token]);

  const [categories, setCategories] = useState([]);
  const [rules, setRules] = useState([]);
  // const [analysisResult, setAnalysisResult] = useState();
  const [csvFiles, setCsvFiles] = useState([]);
  const [manualAccountNames, setManualAccountNames] = useState([]);
  const [dailyTimeseriesData, setDailyTimeseriesData] = useState();
  const [timeseriesDirty, setTimseriesDirty] = useState(true);
  const [bankLinks, setBankLinks] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [graphDefinitions, setGraphDefinitions] = useState([]);
  const [manualTransactions, setManualTransactions] = useState([]);
  const [rawTransactions, setRawTransactions] = useState([]);
  const [categoryAssignment, setCategoryAssignment] = useState(undefined);
  const [userDetails, setUserDetails] = useState();
  const [products, setProducts] = useState([]);
  const [purchases, setPurchases] = useState([]);
  const [proAccount, setProAccount] = useState(false);
  const [proEndDate, setProEndDate] = useState();

  const [isLoadingTransactions, setIsLoadingTransactions] = useState(false);
  const [isLoadingManualTransactions, setIsLoadingManualTransactions] = useState(false);
  const [isLoadingCatAssignment, setIsLoadingCatAssignment] = useState(false);
  const [isLoadingRules, setIsLoadingRules] = useState(false);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [isRefreshingBankLink, setIsRefreshingBankLink] = useState(false);
  const [isLoadingAnalysisV2, setIsLoadingAnalysisV2] = useState(false);
  const [isLoadingTimeseries, setIsLoadingTimeseries] = useState(false);

  const [setupStatusLoading, setSetupStatusLoading] = useState(false);
  const [setupStatusDirty, setSetupStatusDirty] = useState(true);
  const [setupStatus, setSetupStatus] = useState(undefined);

  const [analysisV2, setAnalysisV2] = useState();
  const [analysisV2Dirty, setAnalysisV2Dirty] = useState(true);

  const [myPromoCodes, setMyPromoCodes] = useState([]);

  const [mostRecentRuleInformation, setMostRecentRuleInformation] = useState({});

  // Needed because of changing alerts in setTimeout function
  const alertsRef = useRef([]);
  useEffect(() => {
    alertsRef.current = alerts;
  }, [alerts]);

  function onLogout(information) {
    removeCookie("token", { path: "/" });

    setCategories([]);
    setDailyTimeseriesData(undefined);
    setAnalysisV2Dirty(true);
    setTimseriesDirty(true);
    setGraphDefinitions([]);
    setRawTransactions([]);
    setCategoryAssignment({});
    setSetupStatus(undefined);
    setSetupStatusDirty(true);
  }

  function processError(error, data, response) {
    if (response.statusCode == 401) {
      onLogout();
    }
    if (response.statusCode == 406) {
      addWarningAlert("Editting data is not possible in the demo account. Create your own free account to get started.", 5, "Demo Account");
    }
  }

  function addErrorAlert(text, timeoutSeconds, title) {
    addAlert(text, "error", timeoutSeconds, title);
  }

  function addWarningAlert(text, timeoutSeconds, title) {
    addAlert(text, "warning", timeoutSeconds, title);
  }

  function addInfoAlert(text, timeoutSeconds, title) {
    addAlert(text, "success", timeoutSeconds, title);
  }

  function addAlert(text, severity, timeoutSeconds, title) {
    const copiedAlerts = alerts.slice();
    const newAlert = {
      message: text,
      title: title,
      severity: severity || "info",
      id: Math.floor(Math.random() * 10000),
    };

    copiedAlerts.push(newAlert);

    setAlerts(copiedAlerts);
    if (timeoutSeconds > 0) {
      scheduleAlertRemoval(newAlert.id, timeoutSeconds);
    }
  }

  function scheduleAlertRemoval(id, timeoutSeconds) {
    setTimeout(function () {
      setAlerts(alertsRef.current.filter((al) => al.id != id));
    }, timeoutSeconds * 1000);
  }

  function clearAllAlerts() {
    setAlerts([]);
  }

  function clearAlertById(id) {
    setAlerts(alerts.filter((al) => al.id != id));
  }

  function linkBankWithCode(bankcode) {
    const callback = function (error, data, response) {
      if (!error) {
        window.location.href = data;

        updateBankLinksFromApi();
      } else {
        processError(error, data, response);
      }
    };

    apiClient.linkAccountForBank(bankcode, callback);
    onNewBankLinkCreated();
  }

  function updateProductsFromApi() {
    function callback(error, data, response) {
      if (!error) {
        setProducts(data);
      } else {
        processError(error, data, response);
      }
    }
    if (isLoggedIn()) {
      apiClient.getAllOfferedProducts(callback);
    }
  }

  function updatePurchasesFromApi() {
    function callback(error, data, response) {
      if (!error) {
        setPurchases(data);
      } else {
        processError(error, data, response);
      }
    }
    if (isLoggedIn()) {
      apiClient.getAllPurchases(callback);
    }
  }

  function onProductBought() {
    updatePurchasesFromApi();
  }

  function updateBankLinksFromApi() {
    const callback = function (error, data, response) {
      if (!error) {
        setBankLinks(data);
      } else {
        processError(error, data, response);
      }
    };

    if (isLoggedIn()) {
      apiClient.getBankLinks(callback);
    }
  }

  function deleteBankLink(bankLinkId) {
    apiClient.deleteBankLinkById(bankLinkId, () => {
      updateBankLinksFromApi();
      analysisResultCouldHaveChanged();
    });
  }

  function refreshBankLink(bankLinkId) {
    setIsRefreshingBankLink(true);
    apiClient.updateTransactionsForLink(bankLinkId, () => {
      setIsRefreshingBankLink(false);
      updateBankLinksFromApi();
      analysisResultCouldHaveChanged();
      // addInfoAlert("Transactions refreshed");
    });
  }

  function updateTimeseriesIfNeeded() {
    if (timeseriesDirty && !isLoadingTimeseries) {
      updateDailyTransactionsTimeSeriesFromAPI();
    }
  }

  function updateDailyTransactionsTimeSeriesFromAPI() {
    const processResponse = function (error, data, response) {
      setIsLoadingTimeseries(false);
      if (!error) {
        setTimseriesDirty(false);
        setDailyTimeseriesData(data);
      } else {
        processError(error, data, response);
      }
    };

    if (isLoggedIn()) {
      if (apiClient) {
        setIsLoadingTimeseries(true);
        const dailyAggregation = new TimeseriesAggregation(1, "day");
        apiClient.getTransactionsAsTimeseries(dailyAggregation, processResponse);
      }
    }
  }

  function refreshAnalysisV2FromApi() {
    function callback(error, data, response) {
      if (!error) {
        setAnalysisV2(data);
        setAnalysisV2Dirty(false);
        setIsLoadingAnalysisV2(false);
      } else {
        setIsLoadingAnalysisV2(false);
        processError(error, data, response);
      }
    }
    if (isLoggedIn()) {
      if (apiClient) {
        setIsLoadingAnalysisV2(true);
        apiClient.getTransactionClassificationAnalysisV2(callback);
      }
    }
  }

  function updateAnalysisV2IfNeeded() {
    if (analysisV2Dirty && !isLoadingAnalysisV2) {
      console.log("Refreshing Analysis");
      refreshAnalysisV2FromApi();
    }
  }

  function refreshTransactionsFromApi() {
    function callback(error, data, response) {
      setIsLoadingTransactions(false);
      if (!error) {
        setRawTransactions(data);
      } else {
        processError(error, data, response);
      }
    }
    if (isLoggedIn()) {
      if (apiClient) {
        setIsLoadingTransactions(true);
        apiClient.getAllRawTransactions(callback);
      }
    }
  }

  function areAllTransactionsKnown(categoryAssignment) {
    const categoryAssignmentsThatAreNotFound = Object.keys(categoryAssignment).filter((transactionId) => rawTransactions.filter((t) => t.id.toString() == transactionId).length == 0);
    return categoryAssignmentsThatAreNotFound.length == 0;
  }

  function informUserAboutChange(newlyAssignedCategories) {
    if (newlyAssignedCategories != undefined && categoryAssignment != undefined) {
      var counter = 0;
      for (const [transactionId, categoryId] of Object.entries(newlyAssignedCategories)) {
        if (transactionId in categoryAssignment) {
          const previousCategoryId = categoryAssignment[transactionId];
          if (previousCategoryId != categoryId) {
            counter = counter + 1;
          }
        }
      }

      if (counter > 0) {
        addInfoAlert(counter + " transactions got re-assigned after applying latest rules", 2, "Rules applied");
      }
    }
  }

  function refreshCategoryAssignmentsFromApi(optionalCallback) {
    function callback(error, data, response) {
      setIsLoadingCatAssignment(false);
      if (!error) {
        if (!areAllTransactionsKnown(data.assigned_categories)) {
          console.log("Category Assignment referenced transactions that I did not know, refreshing them");
          refreshTransactionsFromApi();
        }
        setCategoryAssignment(data.assigned_categories);
        informUserAboutChange(data.assigned_categories);
      } else {
        processError(error, data, response);
      }
      if (optionalCallback) {
        optionalCallback();
      }
    }
    if (isLoggedIn()) {
      if (apiClient) {
        setIsLoadingCatAssignment(true);
        apiClient.getCategoryAssignmentV2(callback);
      }
    }
  }

  function updateManualAccountNamesFromApi() {
    function processManualAccountNamesResponse(error, data, response) {
      if (!error) {
        setManualAccountNames(data);
      } else {
        processError(error, data, response);
      }
    }
    if (isLoggedIn()) {
      if (apiClient) {
        apiClient.getAccountNamesForManualUploadedTransactions(processManualAccountNamesResponse);
      }
    }
  }

  function updateCsvFilesFromApi() {
    function processCsvFilesResponse(error, data, response) {
      if (!error) {
        setCsvFiles(data);
      } else {
        processError(error, data, response);
      }
    }

    if (isLoggedIn()) {
      if (apiClient) {
        apiClient.getAllTransactionsFiles(processCsvFilesResponse);
      }
    }
  }

  function updateAccountDetailsFromApi() {
    function processUserDetails(error, data, response) {
      if (!error) {
        setUserDetails(data);
      } else {
        processError(error, data, response);
      }
    }

    if (isLoggedIn()) {
      if (apiClient) {
        apiClient.getUserDetails(processUserDetails);
      }
    }
  }

  function resetTransactionsForTransactionFileById(csvFileId) {
    function onReset(error, data, response) {
      if (!error) {
        updateCsvFilesFromApi();
      } else {
        processError(error, data, response);
      }
    }

    if (isLoggedIn()) {
      if (apiClient) {
        apiClient.resetTransactionsForTransactionFileById(csvFileId, onReset);
      }
    }
  }

  function deleteCsvFile(csvFileId) {
    function onDeleted(error, data, response) {
      if (!error) {
        updateCsvFilesFromApi();
      } else {
        processError(error, data, response);
      }
    }

    if (apiClient) {
      apiClient.deleteTransactionFileById(csvFileId, onDeleted);
    }
  }

  function uploadNewCsvFile(csvFile) {
    function onCreated(error, data, response) {
      if (!error) {
        updateCsvFilesFromApi();
      } else {
        processError(error, data, response);
      }
    }

    if (apiClient) {
      apiClient.uploadTransactionsFile(csvFile, onCreated);
    }
  }

  function updateMyPromoCodes() {
    if (isLoggedIn()) {
      apiClient.getMyPromotionCodes((error, data, response) => {
        if (!error) {
          setMyPromoCodes(data);
        } else {
          processError(error, data, response);
        }
      });
    }
  }

  function updateTransactionsForCsvFile(csvFileId, transactions) {
    const callback = function (error, data, response) {
      if (!error) {
        updateCsvFilesFromApi();
        refreshTransactionsFromApi();
        analysisResultCouldHaveChanged();
      } else {
        processError(error, data, response);
      }
    };
    if (isLoggedIn()) {
      apiClient.createTransactionsForTransactionsFile(csvFileId, transactions, callback);
    }
  }

  function updateCategoriesFromApi() {
    function processCategoriesResponse(error, data, response) {
      setIsLoadingCategories(false);
      if (!error) {
        setCategories(data);
      } else {
        processError(error, data, response);
      }
    }
    if (isLoggedIn()) {
      if (apiClient) {
        setIsLoadingCategories(true);
        apiClient.getAllCategories(processCategoriesResponse);
      }
    }
  }

  function updateRulesFromApi() {
    const callback = function (error, data, response) {
      setIsLoadingRules(false);
      if (!error) {
        setRules(data);
      } else {
        processError(error, data, response);
      }
    };

    if (isLoggedIn()) {
      if (apiClient) {
        setIsLoadingRules(true);
        apiClient.getAllRules(callback);
      }
    }
  }

  function updateGraphDefinitionsFromApi() {
    const callback = function (error, data, response) {
      if (!error) {
        setGraphDefinitions(data);
      } else {
        processError(error, data, response);
      }
    };
    if (isLoggedIn()) {
      if (apiClient) {
        apiClient.getAllGraphDefinitions(callback);
      }
    }
  }

  function getNumberOfConflicts(analysisResult) {
    return analysisResult?.classification_conflicts.length || 0;
  }

  function onRuleAdded() {
    updateRulesFromApi();
    analysisResultCouldHaveChanged();
  }

  function onRuleDeleted() {
    updateRulesFromApi();
    analysisResultCouldHaveChanged();
  }

  function onCategoryAdded() {
    updateCategoriesFromApi();
  }

  function onCategoryModified() {
    updateCategoriesFromApi();
    updateRulesFromApi();
    analysisResultCouldHaveChanged();
  }

  function onNewBankLinkCreated() {
    console.log("Started process for new banklink, so periodic transaction fetching starts");

    for (let minutes = 0; minutes < 7; minutes++) {
      setTimeout(function () {
        console.log("Background fetching because of new banklink creation");
        analysisResultCouldHaveChanged();
      }, (60 * minutes + 10) * 1000);
    }
  }

  function createCategory(newCategory, callback = () => {}) {
    apiClient.createNewCategory(newCategory, (error, data, response) => {
      if (!error) {
        setSetupStatusDirty(true);
        onCategoryAdded();
        updateSetupStatusIfNotComplete();
        callback();
      } else {
        processError(error, data, response);
      }
    });
  }

  function deleteCategory(category, callback = () => {}) {
    apiClient.deleteCategoryById(category.id, (error, data, response) => {
      if (!error) {
        onCategoryModified();
        callback();
      } else {
        processError(error, data, response);
      }
    });
  }

  function modifyCategoryParent(categoryId, newParentId) {
    apiClient.updateCategoryParent(categoryId, newParentId, (error, data, response) => {
      if (!error) {
        onCategoryModified();
      } else {
        processError(error, data, response);
      }
    });
  }

  function modifyCategoryName(categoryId, newName) {
    apiClient.updateCategoryName(categoryId, newName, (error, data, response) => {
      if (!error) {
        onCategoryModified();
      } else {
        processError(error, data, response);
      }
    });
  }

  function updateGraphDefinition(existingGraphId, newGraphDefinition) {
    apiClient.updateGraphDefinition(existingGraphId, newGraphDefinition, (error, data, response) => {
      if (!error) {
        updateGraphDefinitionsFromApi();
      } else {
        processError(error, data, response);
      }
    });
  }

  function saveNewGraphDefinition(newGraphDefinition) {
    apiClient.createNewGraphDefinition(newGraphDefinition, (error, data, response) => {
      if (!error) {
        setSetupStatusDirty(true);
        updateGraphDefinitionsFromApi();
        updateSetupStatusIfNotComplete();
        addInfoAlert("Graph saved", 3, "Success");
      } else {
        if (response?.statusCode == 402) {
          addWarningAlert("Upgrade to Budgex premium if you want to be able to create additional graphs", -1, "Free account limit reached");
        } else {
          processError(error, data, response);
        }
      }
    });
  }

  function deleteGraphDefinitionById(id) {
    apiClient.deleteGraphDefinition(id, (error, data, response) => {
      if (!error) {
        addInfoAlert("Graph Removed", 3, "Success");
      } else {
        processError(error, data, response);
      }
      updateGraphDefinitionsFromApi();
    });
  }

  function disableTwoFa() {
    apiClient.disableTwoFa((error, data, response) => {
      if (!error) {
        updateTwoFaStatus();
      } else {
        processError(error, data, response);
      }
    });
  }

  function enableTwoFa(twoFaToken) {
    apiClient.enableTwoFa(twoFaToken, (error, data, response) => {
      if (!error) {
        updateTwoFaStatus();
      } else {
        processError(error, data, response);
      }
    });
  }

  function updateTwoFaStatus() {
    function callback(error, data, response) {
      if (!error) {
        setTwoFaEnabled(data);
      } else {
        processError(error, data, response);
      }
    }
    if (isLoggedIn()) {
      apiClient.get2faStatus(callback);
    }
  }

  function updateManualTransactionsFromApi() {
    function callback(error, data, response) {
      setIsLoadingManualTransactions(false);
      if (!error) {
        setManualTransactions(data);
      } else {
        processError(error, data, response);
      }
    }
    if (isLoggedIn()) {
      setIsLoadingManualTransactions(true);
      apiClient.getAllManualTransactions(callback);
    }
  }

  function createNewManualTransaction(trans) {
    apiClient.createNewManualTransaction(trans, (error, data, response) => {
      if (!error) {
        onManualTransactionsChanged();
      } else {
        processError(error, data, response);
      }
    });
  }

  function deleteManualTransactionById(id) {
    apiClient.deleteManualTransactionById(id, (error, data, response) => {
      if (!error) {
        onManualTransactionsChanged();
      } else {
        processError(error, data, response);
      }
    });
  }

  function onManualTransactionsChanged() {
    updateManualTransactionsFromApi();
    analysisResultCouldHaveChanged();
    refreshTransactionsFromApi();
  }

  function onRulesChanged() {
    updateRulesFromApi();
    analysisResultCouldHaveChanged();
  }

  function analysisResultCouldHaveChanged() {
    // Dont automatically update Analysis V2, only if it is needed
    refreshCategoryAssignmentsFromApi();
    setAnalysisV2Dirty(true);
    setTimseriesDirty(true);
  }

  function createNewRule(rule) {
    apiClient.createNewRule(rule, (error, data, response) => {
      if (!error) {
        setSetupStatusDirty(true);
        addInfoAlert("Rule added", 3, "Success");
        updateSetupStatusIfNotComplete();

        setMostRecentRuleInformation({ RuleId: data, time: DateTime.now() });
      } else {
        if (response?.statusCode == 402) {
          addWarningAlert("Consider upgrading to premium if you want to be able to create additional classification rules", -1, "Free account limit reached");
        } else {
          processError(error, data, response);
        }
      }
      onRulesChanged();
    });
  }
  function createNewSpecificRuleForTransaction(transactionId, categoryId) {
    apiClient.createSpecificRuleForTransaction(transactionId, categoryId, (error, data, response) => {
      if (!error) {
        setSetupStatusDirty(true);
        addInfoAlert("Transaction assigned to category", 3, "Success");
        updateSetupStatusIfNotComplete();
      } else {
        if (response?.statusCode == 402) {
          addWarningAlert("Free account limit reached. Consider upgrading to premium if you want to be able to create additional classification rules", -1, "Free account limit reached");
        } else {
          processError(error, data, response);
        }
      }
      onRulesChanged();
    });
  }

  function deleteRuleById(ruleId) {
    apiClient.deleteRuleById(ruleId, (error, data, response) => {
      if (!error) {
        onRulesChanged();
      } else {
        processError(error, data, response);
      }
    });
  }

  function deleteMyAccount() {
    apiClient.deleteMyAccount((error, data, response) => {
      if (!error) {
        onLogout();
      } else {
        processError(error, data, response);
      }
    });
  }

  function updateProEndDateFromApi() {
    if (isLoggedIn()) {
      apiClient.getProAccountEndDate((error, data, response) => {
        if (!error) {
          setProEndDate(data);
        }
      });
    }
  }

  function updateProAccountFromApi() {
    if (isLoggedIn()) {
      apiClient.hasProAccount((error, data, response) => {
        if (!error) {
          setProAccount(data);
        }
      });
    }
  }

  function updateSetupStatusFromApi() {
    if (isLoggedIn()) {
      if (setupStatusDirty) {
        setSetupStatusLoading(true);

        apiClient.getSetupStatus((error, data, response) => {
          setSetupStatusLoading(false);
          if (!error) {
            setSetupStatus(data);
            setSetupStatusDirty(false);
          }
        });
      }
    }
  }

  function isSetupStatusComplete() {
    return setupStatus?.rules_created > 0 && setupStatus?.specific_assignments_created > 0 && setupStatus?.categories_created > 0 && setupStatus?.graphs_created > 0;
  }

  function updateSetupStatusIfNotComplete() {
    if (!isSetupStatusComplete()) {
      updateSetupStatusFromApi();
    }
  }

  function reportAProblem(title, description, requisitionId) {
    if (isLoggedIn()) {
      const report = new FdcExpenses.ProblemReport(title, description);
      report.requisition_id = requisitionId;
      apiClient.reportProblem(report, (error, data, response) => {
        if (!error) {
          addInfoAlert("Problem report submitted", 10, "Our teams will investigate this hickup");
        } else {
          processError(error, data, response);
        }
      });
    }
  }

  const callbacks = {
    onCategoryAdded: onCategoryAdded,
    onCategoryModified: onCategoryModified,
    onRuleAdded: onRuleAdded,
    onRuleDeleted: onRuleDeleted,
    onNewBankLinkCreated: onNewBankLinkCreated,
    onManualTransactionsChanged: onManualTransactionsChanged,
    onProductBought: onProductBought,
  };

  const apiCalls = {
    createCategory: createCategory,
    deleteCategory: deleteCategory,
    uploadNewCsvFile: uploadNewCsvFile,
    deleteCsvFile: deleteCsvFile,
    resetTransactionsForTransactionFileById: resetTransactionsForTransactionFileById,
    updateTransactionsForCsvFile: updateTransactionsForCsvFile,
    deleteBankLink: deleteBankLink,
    refreshBankLink: refreshBankLink,
    linkBankWithCode: linkBankWithCode,
    addErrorAlert: addErrorAlert,
    addWarningAlert: addWarningAlert,
    addInfoAlert: addInfoAlert,
    modifyCategoryParent: modifyCategoryParent,
    modifyCategoryName: modifyCategoryName,
    createNewRule: createNewRule,
    createNewSpecificRuleForTransaction: createNewSpecificRuleForTransaction,
    deleteRuleById: deleteRuleById,
    saveNewGraphDefinition: saveNewGraphDefinition,
    updateGraphDefinition: updateGraphDefinition,
    deleteGraphDefinitionById: deleteGraphDefinitionById,
    createNewManualTransaction: createNewManualTransaction,
    deleteManualTransactionById: deleteManualTransactionById,
    updateAnalysisV2IfNeeded: updateAnalysisV2IfNeeded,
    updateTimeseriesIfNeeded: updateTimeseriesIfNeeded,
    setHelpCallback: setHelpCallback,
    setForceExpand: setForceExpand,
    deleteMyAccount: deleteMyAccount,
    reportAProblem: reportAProblem,
    setMiniSidenav: (mini) => setMiniSidenav(dispatch, mini),
    enableTwoFa: enableTwoFa,
    disableTwoFa: disableTwoFa,
  };

  function gatherAllData() {}

  const applicationData = useMemo(() => {
    return {
      categories: categories,
      rules: rules,
      analysisV2: analysisV2,
      // analysisResult: analysisResult,
      rawTransactions: rawTransactions,
      categoryAssignment: categoryAssignment,
      csvFiles: csvFiles,
      manualAccountNames: manualAccountNames,
      dailyTimeseriesData: dailyTimeseriesData,
      bankLinks: bankLinks,
      graphDefinitions: graphDefinitions,
      manualTransactions: manualTransactions,
      isLoadingTransactions: isLoadingTransactions,
      isLoadingManualTransactions: isLoadingManualTransactions,
      isLoadingCatAssignment: isLoadingCatAssignment,
      isLoadingRules: isLoadingRules,
      isLoadingCategories: isLoadingCategories,
      isRefreshingBankLink: isRefreshingBankLink,
      isLoadingAnalysisV2: isLoadingAnalysisV2,
      userDetails: userDetails,
      products: products,
      purchases: purchases,
      miniSidenav: miniSidenav,
      windowRatio: windowRatio,
      windowWidth: windowWidth,
      windowHeight: windowHeight,
      setupStatus: setupStatus,
      setupStatusDirty: setupStatusDirty,
      setupStatusLoading: setupStatusLoading,
      proAccount: proAccount,
      proEndDate: proEndDate,
      myPromoCodes: myPromoCodes,
      twoFaEnabled: twoFaEnabled,
    };
  }, [
    isLoadingAnalysisV2,
    isLoadingCatAssignment,
    isLoadingRules,
    isLoadingCategories,
    isRefreshingBankLink,
    isLoadingManualTransactions,
    isLoadingTransactions,
    categories,
    rules,
    csvFiles,
    manualAccountNames,
    dailyTimeseriesData,
    bankLinks,
    graphDefinitions,
    manualTransactions,
    categoryAssignment,
    rawTransactions,
    miniSidenav,
    purchases,
    products,
    windowRatio,
    proAccount,
    setupStatus,
    setupStatusDirty,
    setupStatusLoading,
    myPromoCodes,
    twoFaEnabled,
  ]);

  function getDailyAggregatedTimeSeriesData() {
    return useMemo(() => {
      const dailyAggregation = new FdcExpenses.TimeseriesAggregation(1, "day");
      apiClient.getTransactionsAsTimeseries(dailyAggregation, processResponse);
    }, []);
  }

  useMemo(() => updateCategoriesFromApi(), [apiClient]);
  useMemo(() => updateRulesFromApi(), [apiClient]);
  // useMemo(() => updateDailyTransactionsTimeSeriesFromAPI(), [apiClient]); This will be done, when needed by the page
  useMemo(() => updateCsvFilesFromApi(), [apiClient]);
  useMemo(() => updateManualAccountNamesFromApi(), [apiClient]);
  useMemo(() => updateBankLinksFromApi(), [apiClient]);
  useMemo(() => updateGraphDefinitionsFromApi(), [apiClient]);
  useMemo(() => updateManualTransactionsFromApi(), [apiClient]);
  useMemo(() => refreshCategoryAssignmentsFromApi(), [apiClient]);
  // useMemo(() => refreshTransactionsFromApi(), [apiClient]); Transactions will be downloaded, indirectly when the category assignment realizes that not everything is known
  useMemo(() => updateAccountDetailsFromApi(), [apiClient]);
  useMemo(() => updatePurchasesFromApi(), [apiClient]);
  useMemo(() => updateProductsFromApi(), [apiClient]);
  useMemo(() => updateProAccountFromApi(), [apiClient]);
  useMemo(() => updateSetupStatusFromApi(), [apiClient]);
  useMemo(() => updateProEndDateFromApi(), [apiClient]);
  useMemo(() => updateMyPromoCodes(), [apiClient]);
  useMemo(() => updateTwoFaStatus(), [apiClient]);

  const cachedRenderedRoutes = useMemo(() => {
    return (
      <Routes>
        {getRoutes(routes(apiClient, categories, rules, csvFiles, manualAccountNames, dailyTimeseriesData, callbacks, apiCalls, applicationData))}
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    );
  }, [
    apiClient,
    categories,
    rules,
    csvFiles,
    manualAccountNames,
    dailyTimeseriesData,
    applicationData,
    cookies.token,
    apiCalls,
    applicationData.graphDefinitions,
    applicationData.twoFaEnabled,
    applicationData.setupStatus,
  ]);

  const loginPageOrRoutes = function (apiClient) {
    if (cookies.token) {
      const dataIsLoaded = setupStatus != undefined && categoryAssignment != undefined;

      return (
        <DashboardLayout>
          <MDBox mb={2}>
            <DashboardNavbar username="Filip" onLogout={onLogout} applicationData={applicationData} helpCallback={helpCallback} />
          </MDBox>
          <MDBox>
            <Alerts alerts={alerts} clearAlertById={clearAlertById} />
          </MDBox>
          {dataIsLoaded && cachedRenderedRoutes}
          {!dataIsLoaded && (
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
              <Grid container justifyContent={"center"}>
                <Grid item xs={12}>
                  <Grid container justifyContent={"center"}>
                    <MDBox mb={5}>
                      <MDTypography variant="h3" sx={{ color: "#FFFFFF" }}>
                        Loading data ...
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent={"center"}>
                    <CircularProgress color="inherit" size={50} />
                  </Grid>
                </Grid>
              </Grid>
            </Backdrop>
          )}
          <MDBox mt={2}>
            <Footer />
          </MDBox>
        </DashboardLayout>
      );
    } else {
      return <LoginPage apiInstance={apiClient} onLogin={onLogin} apiCalls={apiCalls} />;
    }
  };

  return (
    <GoogleOAuthProvider clientId="656152894254-idnlvqiaa5kj0p447ahmvd0g56dr7mf3.apps.googleusercontent.com">
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <CssBaseline />
          {layout === "dashboard" && (
            <>
              <Sidenav
                pro={proAccount}
                forceExpand={forceExpand}
                color={sidenavColor}
                brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandBudgex : brandBudgex}
                brandName="Budgex.eu"
                routes={routes(apiClient, categories, rules, csvFiles, manualAccountNames, dailyTimeseriesData, callbacks, apiCalls, applicationData)}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
            </>
          )}
          {layout === "vr" && <Configurator />}
          {loginPageOrRoutes(apiClient)}
        </LocalizationProvider>
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
}
