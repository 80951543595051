import { Card, Grid, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import GenericCard from "components/TitledCard/GenericCard";
import { useState } from "react";
import { Product } from "./Product";

export function Products({ applicationData, apiClient, callbacks, onClose, apiCalls }) {
  const [promoCodeText, setPromoCodeText] = useState("");
  const [validatedPromo, setValidatedPromo] = useState(undefined);
  const [lastValidationResult, setLastValidationResult] = useState(undefined);

  function tryToValidatePromoCode() {
    apiClient.validatePromoCode(promoCodeText, (error, data, response) => {
      if (!error) {
        if (data?.price_reduction_for_receiver != undefined) {
          console.log("Validated with", data);
          setLastValidationResult(true);
          setValidatedPromo(data);
        } else {
          setLastValidationResult(false);
          setValidatedPromo(undefined);
        }
      } else {
        setLastValidationResult(false);
        setValidatedPromo(undefined);
      }
    });
  }

  const renderedProducts = applicationData.products.map((product) => (
    <Grid item xs={6} md={4}>
      <MDBox m={1}>
        <Product product={product} apiClient={apiClient} callbacks={callbacks} onClose={onClose} validatedPromo={validatedPromo} />
      </MDBox>
    </Grid>
  ));
  return (
    <Grid container justifyContent={"space-around"}>
      {renderedProducts}
      <Grid item xs={12}>
        <MDBox m={1}>
          <GenericCard
            topleft={
              <MDBox m={1}>
                <MDTypography variant="h6">Promotional Code</MDTypography>
              </MDBox>
            }
          >
            <Card>
              <MDBox m={1}>
                <Grid container>
                  {lastValidationResult != undefined && lastValidationResult == false && (
                    <MDBox m={1}>
                      <MDTypography variant="caption" color="error">
                        Not a valid promo code
                      </MDTypography>
                    </MDBox>
                  )}
                  {lastValidationResult != undefined && lastValidationResult == true && (
                    <MDBox m={1}>
                      <MDTypography variant="caption" color="success">
                        Valid promo code !
                      </MDTypography>
                    </MDBox>
                  )}
                  <Grid item xs={12}>
                    <TextField
                      id="outlined-basic"
                      label="Promotion Code"
                      variant="outlined"
                      value={promoCodeText}
                      onChange={(ev) => {
                        setPromoCodeText(ev.target.value);
                        setLastValidationResult(undefined);
                        setValidatedPromo(undefined);
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justifyContent="center">
                      <MDBox m={3}>
                        <MDButton color="success" onClick={tryToValidatePromoCode}>
                          Validate Promotion Code
                        </MDButton>
                      </MDBox>
                    </Grid>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </GenericCard>
        </MDBox>
      </Grid>
    </Grid>
  );
}
