import { Card, Grid, Icon, Dialog, TextField } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { AccountSetup } from "../graphbuilder/AccountSetup";
import DetailsCard from "../ruleanalysis/detailscard";
import GenericCard from "components/TitledCard/GenericCard";
import { CloseableCard } from "components/TitledCard/GenericCard";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Products } from "./Products";
import { Purchases } from "./Purchases";

const ENABLE_REFERRAL_CODE = false;

function SubscriptionPage({ applicationData, apiCalls, apiClient, callbacks }) {
  const [showProductsPopup, setShowProductsPopup] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (location.search.includes("showProducts")) {
      setShowProductsPopup(true);
    }
  }, [location]);

  return (
    <Grid container>
      <Dialog open={showProductsPopup} fullWidth={true} maxWidth={"xl"}>
        <CloseableCard onClose={() => setShowProductsPopup(false)}>
          <Products applicationData={applicationData} apiClient={apiClient} callbacks={callbacks} onClose={() => setShowProductsPopup(false)} apiCalls={apiCalls} />
        </CloseableCard>
      </Dialog>

      <Grid item xs={12} md={12}>
        <MDBox m={1}>
          {/* <Card> */}
          <MDBox m={1}>
            {/* <GenericCard topleft={<MDTypography variant="h6">Budgex Subscriptions</MDTypography>}> */}
            <Grid container>
              <Grid item xs={12}>
                <Grid container justifyContent="center">
                  <MDButton color="dark" onClick={() => setShowProductsPopup(true)}>
                    Buy a Budgex Pro-subscription
                  </MDButton>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <MDBox mt={3}>
                  <Purchases applicationData={applicationData} />
                </MDBox>
              </Grid>
            </Grid>
            {/* </GenericCard> */}
          </MDBox>
          {/* </Card> */}
        </MDBox>
      </Grid>

      {ENABLE_REFERRAL_CODE && applicationData?.myPromoCodes?.length > 0 && (
        <Grid item xs={12} md={12}>
          <MDBox m={1}>
            <Card>
              <MDBox m={1}>
                <GenericCard topleft={<MDTypography variant="h6">Referral Code</MDTypography>}>
                  <Grid container>
                    <Grid item xs={12}>
                      <MDTypography variant="caption" fontWeight="regular" color="text">
                        Tell your friends about Budgex ! If they use your
                        <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                          {" "}
                          Personal referral code
                        </MDTypography>
                        , you both benefit !
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12}>
                      <MDTypography variant="caption" fontWeight="regular" color="text">
                        For every friend that buys a Budgex Premium Account with your code, you will get
                        <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                          {" "}
                          {Math.round(applicationData?.myPromoCodes[0].number_of_free_days_for_sender / 31)} months of Budgex Premium
                        </MDTypography>
                        . On top of that, your friend will get
                        <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
                          {" "}
                          a discount of {applicationData?.myPromoCodes[0].price_reduction_for_receiver} EUR !
                        </MDTypography>
                      </MDTypography>
                    </Grid>

                    <Grid item xs={12}>
                      <DetailsCard
                        keyValuePairs={{
                          "Your personal referral code": applicationData?.myPromoCodes[0].uuid,
                        }}
                      />
                    </Grid>
                  </Grid>
                </GenericCard>
              </MDBox>
            </Card>
          </MDBox>
        </Grid>
      )}
    </Grid>
  );
}

export default SubscriptionPage;
