import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Button, Card, Checkbox, FormControl, Grid, IconButton, InputLabel, List, ListItem, MenuItem, Select } from "@mui/material";
import React, { useMemo, useState } from "react";
import MDTypography from "components/MDTypography";
import ClearIcon from "@mui/icons-material/Clear";
import Papa from "papaparse";
import DataTable from "examples/Tables/DataTable";
import MemoryIcon from "@mui/icons-material/Memory";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { Transaction } from "fdc_expenses";
import moment from "moment";
import { DateTime } from "luxon";
import MDInput from "components/MDInput";
import TransactionDetails from "../ruleanalysis/transactiondetails";
import { MultiPageList } from "./MultiPageList";
import { CloseableCard } from "components/TitledCard/GenericCard";
import ReactJoyride from "react-joyride";
import { stopJoyRideWhenFinished } from "utils/JoyRideUtils";
import FDDemo from "utils/FDDemo";

const steps = [
  {
    target: ".DataTable",
    content: <MDTypography variant="button">This shows the raw unparsed data in your CSV File</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".AccountName",
    content: <MDTypography variant="button">Use this field to provide a name for the account that this CSV file represents. Eg 'MasterCard'</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".SelectCounterpart",
    content: <MDTypography variant="button">Use this dropdown to select the column that contains the information about the counterpart of the transactions</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".SelectAmount",
    content: <MDTypography variant="button">Use this dropdown to select the column that contains the information about the amount of the transactions</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".SelectDate",
    content: <MDTypography variant="button">Use this dropdown to select the column that contains the information about the transaction-date in your transaction</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".SelectComments",
    content: <MDTypography variant="button">Use this dropdown to select the column that contains the information about the comments in your transaction</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".SelectDescription",
    content: <MDTypography variant="button">Use this dropdown to select the column that contains the information about the description in your transaction</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".SignConvention",
    content: (
      <MDTypography variant="button">
        Make sure to select the correct sign convention of the CSV file here. Think about whether the negative numbers in this CSV file represent expenses or incoming transactions
      </MDTypography>
    ),
    disableBeacon: true,
  },
  {
    target: ".DateFormat",
    content: <MDTypography variant="button">Make sure to select the correct date format. This is required to properly parse the date of the date column</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".SkipRows",
    content: (
      <MDTypography variant="button">
        This field can be used to indicate that the first N rows should be skipped. This can be used in case that the first rows of the CSV file do not contain relevant data
      </MDTypography>
    ),
    disableBeacon: true,
  },
  {
    target: ".FirstRowIsHeader",
    content: (
      <MDTypography variant="button">
        This checkbox indicates whether the first row of the CSV file should be interpreted as the header for the columns or whether it represents the first transaction
      </MDTypography>
    ),
    disableBeacon: true,
  },

  {
    target: ".Validate",
    content: <MDTypography variant="button">When you have configured the parse options, click this button to test-run the parsing</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".ParseProblems",
    content: (
      <MDTypography variant="button">
        "After running the validation, this shows an overview of the CSV-file lines that were not succesfully parsed. This can happen if the row did not contain sufficient information (date,
        amount,...). Review this, to be sure that your CSV file is parsed correctly
      </MDTypography>
    ),
    disableBeacon: true,
  },
  {
    target: ".ParsedTransactions",
    content: <MDTypography variant="button">After running the validation, this shows an overview of the CSV-file lines that were parsed succesfully</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".Save",
    content: (
      <MDTypography variant="button">
        Click this button to save the parse results to your Budgex account. After clicking this button, all parsed transactions are available for classification
      </MDTypography>
    ),
    disableBeacon: true,
  },
];

function ColumnSelector({ columnNames, selectedColumn, setSelectedColumn, title }) {
  const items = (columnNames || []).map((c) => {
    return <MenuItem value={c}>{c}</MenuItem>;
  });

  return (
    <MDBox ml={1} mr={1} m={1}>
      <FormControl fullWidth>
        <InputLabel>Column for {title}</InputLabel>
        <Select variant="standard" label="Select Column" value={selectedColumn} onChange={(ev) => setSelectedColumn(ev.target.value)} fullWidth>
          {items}
        </Select>
      </FormControl>
    </MDBox>
  );
}

export function CsvFileParser({ csvFile, manualAccountNames, apiCalls, onClose, externalDemo }) {
  const [skipRows, setSkipRows] = useState(0);
  const [useHeaderRow, setUseHeaderRow] = useState(true);
  const [showJoyRide, setShowJoyRide] = useState(externalDemo || false);

  const parseResult = useMemo(() => {
    return Papa.parse(csvFile?.file_content || "");
  }, [csvFile]);

  const tableData = useMemo(() => {
    const headerOffset = useHeaderRow ? 1 : 0;

    if (parseResult.data.length > skipRows + headerOffset) {
      const columns = parseResult.data[skipRows].map((cell, index) => {
        if (useHeaderRow) {
          return { Header: "Column " + (cell || index), accessor: "Col_" + index.toString() };
        } else {
          return { Header: "Column " + index, accessor: "Col_" + index.toString() };
        }
      });

      const rows = parseResult.data.slice(skipRows + headerOffset).map((row) => {
        const rowMap = {};
        row.forEach((cell, index) => {
          rowMap["Col_" + index.toString()] = cell;
        });
        return rowMap;
      });

      return { columns: columns, rows: rows };
    }

    return { columns: [], rows: [] };
  }, [csvFile, skipRows, useHeaderRow]);

  const possibleColumnNames = useMemo(() => {
    return tableData.columns.map((c) => c.Header);
  }, [tableData, skipRows]);

  const [counterPartColumn, setCounterPartColumn] = useState("");
  const [amountColumn, setAmountColumn] = useState("");
  const [dateColumn, setDateColumn] = useState("");
  const [commentsColumn, setCommentsColumn] = useState("");
  const [descriptionColumn, setDescriptionColumn] = useState("");
  const [amountSignConvention, setAmountSignConvention] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [validated, setValidated] = useState(false);

  const [problems, setProblems] = useState([]);
  const [parsedTransactions, setParsedTransactions] = useState([]);

  const dateFormats = [
    ["dd.MM.yyyy", "DD.MM.YYYY"],
    ["dd/MM/yyyy", "DD/MM/YYYY"],
    ["dd-MM-yyyy", "DD-MM-YYYY"],
  ];

  const [dateFormat, setDateFormat] = useState(dateFormats[0]);

  function isValidParseDefinition() {
    const amountDefined = amountColumn != "";
    const dateDefined = dateColumn != "";

    // TODO: Validate whether date format is compatible with the data

    return amountDefined && dateDefined;
  }

  function getColumnAccessor(columnName) {
    return tableData.columns.filter((col) => col.Header == columnName)[0].accessor;
  }

  function generateTransactions() {
    const generatedTransactions = tableData.rows.map((row, index) => {
      const trans = new Transaction();
      const problems = [];
      const headerOffset = useHeaderRow ? 1 : 0;

      const rowText = parseResult.data[parseFloat(skipRows) + parseFloat(index) + parseFloat(headerOffset)];

      trans.account = accountName;

      if (row[getColumnAccessor(amountColumn)] != undefined && !isNaN(parseFloat(row[getColumnAccessor(amountColumn)]))) {
        trans.amount = parseFloat(row[getColumnAccessor(amountColumn)]);
        if (amountSignConvention) {
          trans.amount = -1 * trans.amount;
        }
      } else {
        problems.push("Can not parse amount in row : '" + rowText + "'");
      }

      const parsedDate = DateTime.fromFormat(row[getColumnAccessor(dateColumn)], dateFormat, { zone: "utc" });

      if (row[getColumnAccessor(dateColumn)] != undefined && parsedDate.invalid == null) {
        trans.timestamp = parsedDate.toJSDate();
      } else {
        problems.push("Can not parse date in row : '" + rowText + "'");
      }

      if (counterPartColumn != "") {
        trans.counter_part_name = row[getColumnAccessor(counterPartColumn)];
      }

      if (commentsColumn != "") {
        trans.comments = row[getColumnAccessor(commentsColumn)];
      }

      if (descriptionColumn != "") {
        trans.description = row[getColumnAccessor(descriptionColumn)];
      }

      if (problems.length == 0) {
        return { transaction: trans, problems: problems };
      } else {
        return { problems: problems };
      }
    });

    return generatedTransactions;
  }

  function validateParsing() {
    const transactionAnalysis = generateTransactions();
    const probs = transactionAnalysis.flatMap((d) => d.problems);
    const trans = transactionAnalysis.filter((d) => d.problems.length == 0).map((d) => d.transaction);

    console.log(transactionAnalysis);
    setProblems(probs);
    setValidated(true);
    setParsedTransactions(trans);
  }

  function uploadTransactionsToServer() {
    const transactionAnalysis = generateTransactions();
    const problems = transactionAnalysis.flatMap((d) => d.problems);

    setProblems(problems);

    const transactions = transactionAnalysis.filter((d) => d.problems.length == 0).map((d) => d.transaction);
    if (transactions.length > 0) {
      apiCalls.updateTransactionsForCsvFile(csvFile?.id, transactions);
    }
  }

  const isValid = useMemo(() => isValidParseDefinition(), [counterPartColumn, amountColumn, dateColumn]);

  const renderedProblems = problems.map((problem, index) => (
    <Grid item xs={12}>
      <MDTypography variant="button">{"Warning #" + index + ": " + problem}</MDTypography>
    </Grid>
  ));

  const renderedTransactions = (
    <MultiPageList
      items={parsedTransactions}
      itemName="Parsed Transactions"
      itemsPerPage={5}
      itemRenderFunction={(t) => (
        <MDBox m={1}>
          <Card>
            <MDBox m={1}>
              <TransactionDetails transaction={t} />
            </MDBox>
          </Card>
        </MDBox>
      )}
    />
  );

  // const renderedTransactions = parsedTransactions.map((trans, index) => (

  return (
    <CloseableCard onClose={onClose} onHelp={() => setShowJoyRide(true)}>
      <Grid container>
        <Grid item xs={12} ml={2} mr={2}>
          <MDBox ml={2} mr={2} mt={2} className={"DataTable"}>
            <DataTable table={tableData} entriesPerPage={false} />
          </MDBox>
        </Grid>
        <Grid item xs={12} ml={2} mr={2} mb={2}>
          <Grid container className={"AccountName"}>
            <Grid item xs={4}>
              <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                Account name
              </MDTypography>
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                inputValue={accountName}
                options={manualAccountNames || []}
                multiple={false}
                freeSolo={true}
                onInputChange={(event, value) => {
                  setAccountName(value);
                  setValidated(false);
                  setProblems([]);
                }}
                renderInput={(params) => {
                  return <MDInput {...params} variant="standard" />;
                }}
              ></Autocomplete>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} ml={2} mr={2} mb={2}>
          <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
            Select columns
          </MDTypography>
        </Grid>

        <Grid item xs={12} ml={2} mr={2}>
          <Grid container>
            <Grid item xs={4} className={"SelectCounterpart"}>
              <ColumnSelector
                columnNames={possibleColumnNames}
                selectedColumn={counterPartColumn}
                setSelectedColumn={(c) => {
                  setCounterPartColumn(c);
                  setValidated(false);
                  setProblems([]);
                }}
                title={"counterpartname"}
              />
            </Grid>
            <Grid item xs={4} className={"SelectAmount"}>
              <ColumnSelector
                columnNames={possibleColumnNames}
                selectedColumn={amountColumn}
                setSelectedColumn={(c) => {
                  setAmountColumn(c);
                  setValidated(false);
                  setProblems([]);
                }}
                title="amount"
              />
            </Grid>
            <Grid item xs={4} className={"SelectDate"}>
              <ColumnSelector
                columnNames={possibleColumnNames}
                selectedColumn={dateColumn}
                setSelectedColumn={(c) => {
                  setDateColumn(c);
                  setValidated(false);
                  setProblems([]);
                }}
                title="date"
              />
            </Grid>
            <Grid item xs={4} className={"SelectComments"}>
              <ColumnSelector
                columnNames={possibleColumnNames}
                selectedColumn={commentsColumn}
                setSelectedColumn={(c) => {
                  setCommentsColumn(c);
                  setValidated(false);
                  setProblems([]);
                }}
                title="comments"
              />
            </Grid>

            <Grid item xs={4} className={"SelectDescription"}>
              <ColumnSelector
                columnNames={possibleColumnNames}
                selectedColumn={descriptionColumn}
                setSelectedColumn={(c) => {
                  setDescriptionColumn(c);
                  setValidated(false);
                  setProblems([]);
                }}
                title="description"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} ml={2} mr={2} mb={2} mt={2}>
          <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
            Parse options
          </MDTypography>
        </Grid>
        <Grid item xs={12} ml={2} mr={2} mb={1}>
          <Grid container>
            <Grid item xs={4}>
              <MDBox m={1}>
                <FormControl fullWidth className={"SignConvention"}>
                  <InputLabel>Sign convention</InputLabel>
                  <Select value={amountSignConvention} onChange={(ev) => setAmountSignConvention(ev.target.value)} fullWidth variant="standard">
                    <MenuItem value={true}>Positive means expense</MenuItem>
                    <MenuItem value={false}>Positive means income</MenuItem>
                  </Select>
                </FormControl>
              </MDBox>
            </Grid>
            <Grid item xs={4}>
              <MDBox m={1}>
                <FormControl fullWidth className={"DateFormat"}>
                  <InputLabel>Date format</InputLabel>
                  <Select value={dateFormat} onChange={(ev) => setDateFormat(ev.target.value)} fullWidth variant="standard">
                    {dateFormats.map((df) => {
                      return <MenuItem value={df[0]}>{df[1]}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </MDBox>
            </Grid>

            <Grid item xs={4}>
              <MDBox m={1}>
                <FormControl fullWidth className={"SkipRows"}>
                  {/* <InputLabel>Skip rows</InputLabel> */}
                  <MDInput variant="standard" label="Skip Rows" type="number" value={skipRows} onChange={(ev) => setSkipRows(ev.target.value)}>
                    {skipRows}
                  </MDInput>
                </FormControl>
              </MDBox>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <MDBox m={1}>
              <FormControl fullWidth className={"FirstRowIsHeader"}>
                <InputLabel>First row is header</InputLabel>
                <Checkbox checked={useHeaderRow} onChange={(ev) => setUseHeaderRow(ev.target.checked)} variant="standard" fullWidth value={useHeaderRow} label="Use Header Row"></Checkbox>
              </FormControl>
            </MDBox>
          </Grid>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="center" mt={2} ml={2} alignItems="center">
          <MDBox mb={2}>
            <Grid container justifyContent={"space-around"}>
              <Grid item xs={12}>
                <MDTypography variant="button" fontWeight="medium" textTransform="capitalize">
                  Parse result
                </MDTypography>
              </Grid>
              <Grid item xs={12}>
                <Accordion fullWidth>
                  <AccordionSummary className={"ParseProblems"} fullWidth>
                    <MDTypography variant="h6">{problems.length} warnings</MDTypography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <MDBox ml={2} m={1}>
                      <Grid container>{renderedProblems}</Grid>
                    </MDBox>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary className={"ParsedTransactions"}>
                    <MDTypography variant="h6">{parsedTransactions.length} parsed transactions</MDTypography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <MDBox ml={2} m={1}>
                      <Grid container>{renderedTransactions}</Grid>
                    </MDBox>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </MDBox>
        </Grid>
        <Grid item xs={12} ml={2} mr={2} mb={3} mt={3}>
          <Grid container justifyContent={"space-around"}>
            <Grid item>
              <MDButton
                className={"Save"}
                onClick={() => {
                  uploadTransactionsToServer();
                  onClose();
                }}
                disabled={!validated}
              >
                Save
              </MDButton>
            </Grid>
            <Grid item>
              <MDButton className={"Validate"} onClick={() => validateParsing()}>
                Validate Parsing Configuration
              </MDButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <FDDemo
        run={showJoyRide}
        steps={steps}
        onStop={() => {
          if (externalDemo) {
            setShowJoyRide(false);
            onClose();
          }
        }}
      />
    </CloseableCard>
  );
}
