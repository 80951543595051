import { Dialog, Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Category } from "fdc_expenses";
import CreateNewCategoryCard from "layouts/categorymanagement/CreateNewCategoryCard";
import CategorySelector from "layouts/dashboards/trends/categoryselector";
import SingleAutocompleteCategorySelector from "layouts/pages/classificationflow/singleautocompletecategoryselector";
import ReactJoyride from "react-joyride";
import { stopJoyRideWhenFinished } from "utils/JoyRideUtils";

import SingleCategorySelector from "layouts/pages/classificationflow/singlecategoryselector";
import TransactionDetails from "layouts/pages/ruleanalysis/transactiondetails";
import { createRef, useState } from "react";
import FDDemo from "utils/FDDemo";
const FdcExpenses = require("fdc_expenses");

const steps = [
  {
    target: ".SingleClassifier",
    content: <MDTypography variant="button">Use this page to assign a category to this transaction, without creating a rule that can apply to multiple categories</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".TransactionDetails",
    content: <MDTypography variant="button">You get an overview of the details of the relevant transaction here</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".SelectCategory",
    content: <MDTypography variant="button">Using this dropdown you can select the category that you want to assign</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".CreateCategory",
    content: <MDTypography variant="button">Or create click here to create a new category first</MDTypography>,
    disableBeacon: true,
  },
  {
    target: ".SaveRule",
    content: <MDTypography variant="button">When you have selected the correct category, you can save the assignment here</MDTypography>,
    disableBeacon: true,
  },
];

function SingleTransactionClassifier({ transaction, categories, onCancel, defaultCategory, apiCalls, showDemo, onDemoFinished, onNewRuleCreated }) {
  const [category, setCategory] = useState(defaultCategory);
  const [showCreateNewCategory, setShowCreateNewCategory] = useState(false);

  function createNewRule() {
    if (transaction && category) {
      apiCalls.createNewSpecificRuleForTransaction(transaction.id, category.id);
      onNewRuleCreated();
    }
  }

  return (
    <Grid container className={"SingleClassifier"}>
      <Grid item>
        <MDBox mb={3} mr={2} className={"TransactionDetails"}>
          <TransactionDetails transaction={transaction} />
        </MDBox>
      </Grid>

      <Grid item xs={12} mt={2} mb={1}>
        <Grid container spacing={2} display="flex" alignItems="stretch" justifyContent="space-evenly">
          <Grid item className={"CreateCategory"}>
            <MDButton onClick={(ev) => setShowCreateNewCategory(true)} startIcon={<Icon>add</Icon>}>
              Create new category
            </MDButton>
          </Grid>
          <Grid item className={"SelectCategory"}>
            <SingleAutocompleteCategorySelector
              categories={categories}
              selectedCategory={category || null}
              selectedCategoryChanged={(selectedCategory, selectedCategoryLabel) => {
                if (selectedCategory) {
                  setCategory(selectedCategory);
                } else {
                  setCategory(null);
                }
              }}
            />
          </Grid>
          <Grid item>
            <MDBox className={"SaveRule"}>
              <MDButton color="success" onClick={createNewRule} disabled={category == undefined}>
                Assign Category to Transaction
              </MDButton>
            </MDBox>
          </Grid>
        </Grid>
      </Grid>

      <Dialog open={showCreateNewCategory}>
        <CreateNewCategoryCard categories={categories} onClose={(ev) => setShowCreateNewCategory(false)} apiCalls={apiCalls} />
      </Dialog>

      <FDDemo run={showDemo} steps={steps} onStop={onDemoFinished} />
    </Grid>
  );
}

export default SingleTransactionClassifier;
