import { Card, Grid, Icon, IconButton, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Divider } from "rsuite";

export function SetupStep({ title, done, tasks, description }) {
  const renderedTasks = tasks.map((task, index) => {
    return (
      <Grid item xs={12} key={"SetupStep_" + index}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <MDTypography variant="caption">{task.name}</MDTypography>
          </Grid>

          <Grid item>
            <Grid container justifyContent="space-between" alignItems="center">
              {task.done && <Icon sx={{ color: "green" }}>check_circle</Icon>}
              <Tooltip title="Show me how">
                <IconButton onClick={(ev) => task.callback()}>
                  <Icon>help</Icon>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  });

  return (
    <MDBox m={1}>
      <Card style={{ backgroundColor: "#F5F5F5" }}>
        <MDBox m={1}>
          <Grid container>
            <Grid item xs={12}>
              <MDTypography fontWeight="medium" variant="h6">
                {done && <Icon sx={{ color: "green" }}>check_circle</Icon>}
                {title}
              </MDTypography>
              <Grid item xs={12}>
                <MDTypography variant="caption">{description}</MDTypography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
            {renderedTasks}
          </Grid>
        </MDBox>
      </Card>
    </MDBox>
  );
}
