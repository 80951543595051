import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";
import { AccountSetup } from "../graphbuilder/AccountSetup";

function SetupAccountPage({ apiCalls, applicationData }) {
  return (
    <MDBox m={1}>
      <Card>
        <Grid container>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <MDBox m={1}>
                <MDTypography variant="h5">Setup your account</MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <AccountSetup apiCalls={apiCalls} applicationData={applicationData} />
          </Grid>
        </Grid>
      </Card>
    </MDBox>
  );
}

export default SetupAccountPage;
