import Grid from "@mui/material/Grid";
import { TextField } from "@mui/material";
import { ConditionTile } from "./ConditionTile";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

export function AmountCondition({ condition, onConditionChanged, onDelete }) {
  const minValue = condition.min;
  const maxValue = condition.max;

  const notModifier = condition.invert && (
    <MDTypography variant="caption" fontWeight="bold">
      NOT
    </MDTypography>
  );

  return (
    <ConditionTile title="Amount Filter" onDelete={onDelete} condition={condition} onConditionChanged={onConditionChanged}>
      <Grid container justifyContent={"space-evenly"}>
        <Grid item xs={12}>
          <MDBox mb={1}>
            <MDTypography variant="caption">Use negative numbers for expenses and positive numbers for incoming transactions</MDTypography>
          </MDBox>
        </Grid>
        <Grid item xs={12}>
          <MDBox mb={1}>
            <MDTypography variant="caption">
              Matches all transactions where the amount is {notModifier} between {minValue} and {maxValue}
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid item>
          <TextField
            type="number"
            label={"Minimum"}
            value={minValue}
            onChange={(ev) => {
              const copiedCondition = Object.assign({}, condition);
              copiedCondition.min = ev.target.value;
              onConditionChanged(copiedCondition);
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            type="number"
            label={"Maximum"}
            value={maxValue}
            onChange={(ev) => {
              const copiedCondition = Object.assign({}, condition);
              copiedCondition.max = ev.target.value;
              onConditionChanged(copiedCondition);
            }}
          />
        </Grid>
      </Grid>
    </ConditionTile>
  );
}
