import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DetailsCard from "../ruleanalysis/detailscard";

export function Purchase({ purchase }) {
  const hasBeenPaid = purchase.payment_date != undefined;
  const oldEnoughtToDelete = true;
  return (
    <MDBox m={1}>
      <Card style={{ backgroundColor: hasBeenPaid ? "#FFFFFF" : "#EEEEEE" }}>
        <Grid container>
          <Grid item xs={12}>
            <DetailsCard
              title={purchase.product_name}
              keyValuePairs={{
                "Purchase date": purchase.purchase_date.toDateString(),
                "Payment received": hasBeenPaid ? "Yes" : "No",
                "Payment Date": purchase.payment_date?.toDateString() || "/",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <MDBox mt={2}></MDBox>
          </Grid>
          {!hasBeenPaid && (
            <Grid item xs={12}>
              <Grid container justifyContent="space-around">
                <MDButton
                  color="dark"
                  onClick={(ev) => {
                    window.location.replace(purchase.payment_link);
                  }}
                >
                  Pay
                </MDButton>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Card>
    </MDBox>
  );
}
