import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import RichCategories from "domain/RichCategories";
import { useEffect, useMemo, useState } from "react";
import { TreePicker, CheckTreePicker } from "rsuite";

function MultiAutocompleteCategorySelector({ selectedCategories, selectedCategoriesChanged, categories, label }) {
  const categoryNames = useMemo(() => categories.map((cat) => cat.name), [categories]);

  const options = useMemo(() => {
    const richCats = new RichCategories(categories);

    const allCategoryNames = categories.map((cat) => cat.name);

    return categories.map((cat) => {
      const nameOccursMoreThanOnce = allCategoryNames.indexOf(cat.name) != allCategoryNames.lastIndexOf(cat.name);

      if (nameOccursMoreThanOnce) {
        const prefix = richCats.getCategoryById(cat.parent_id)?.name || "";

        return { label: prefix + "/" + cat.name, id: cat.id };
      } else {
        return { label: cat.name, id: cat.id };
      }
    });
  }, [categories]);

  function handleChange(event, values) {
    if (values) {
      const richCats = new RichCategories(categories);

      var valuesArray = values;

      if (!Array.isArray(values)) {
        valuesArray = [values];
      }

      const selectedCategories = valuesArray.map((value) => richCats.getCategoryById(value.id));
      const selectedCategoriesLabels = valuesArray.map((value) => value.label);

      selectedCategoriesChanged(selectedCategories, selectedCategoriesLabels);
    } else {
      selectedCategoriesChanged([], []);
    }
  }

  const value = useMemo(() => {
    if (selectedCategories) {
      const selectedCategoryIds = selectedCategories.map((cat) => cat.id);
      const relevantValues = options.filter((option) => selectedCategoryIds.includes(option.id));

      return relevantValues;
    } else {
      return [];
    }
  }, [selectedCategories, options]);

  const richCats = new RichCategories(categories);

  function toData(category) {
    const children = richCats.getAllChildrenOf(category).map((cat) => toData(cat));
    if (children.length > 0) {
      return {
        label: category.name,
        value: category.id,
        children: children,
      };
    } else {
      return {
        label: category.name,
        value: category.id,
      };
    }
  }

  const data = richCats.getRootCategories().map((cat) => toData(cat));

  // Works kinda nice, except for a problem with Z index in dialogs
  return (
    <CheckTreePicker
      placement="auto"
      cascade={false}
      defaultExpandAll
      placeholder={label || "Select categories"}
      data={data}
      menuStyle={{ zIndex: 999999 }}
      value={selectedCategories.map((cat) => cat.id)}
      onChange={(values) => {
        selectedCategoriesChanged(values.map((v) => richCats.getCategoryById(v)));
      }}
    />
  );

  // return (
  //   <Autocomplete
  //     multiple={true}
  //     value={value}
  //     onChange={handleChange}
  //     getOptionLabel={(option) => option.label}
  //     options={options || []}
  //     isOptionEqualToValue={(option, value) => option == value}
  //     renderInput={(params) => {
  //       return <MDInput {...params} variant="standard" />;
  //     }}
  //   />
  // );
}

export default MultiAutocompleteCategorySelector;
