import { Tabs, Tab } from "@mui/material";
import MDBox from "components/MDBox";
import { useState } from "react";

import PrivacyPolicy from "./PrivacyPolicy";
import ServiceTerms from "./ServiceTerms";

function Policies({}) {
  const [tabValue, setTabValue] = useState(0);

  return (
    <div>
      <Tabs value={tabValue} onChange={(ev, newValue) => setTabValue(newValue)}>
        <Tab label="Service Terms" />
        <Tab label="Privacy Policy" />
      </Tabs>
      {tabValue == 0 && (
        <MDBox m={1} mt={2}>
          <ServiceTerms />
        </MDBox>
      )}
      {tabValue == 1 && (
        <MDBox m={1} mt={2}>
          <PrivacyPolicy />
        </MDBox>
      )}
    </div>
  );
}

export default Policies;
