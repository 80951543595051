import { Grid } from "@mui/material";
import React from "react";
import { TransactionsFile } from "fdc_expenses";
import { DropZone } from "./DropZone";
import MDBox from "components/MDBox";

export function TransactionsFilesToolbar({ apiCalls }) {
  function onFileAdded(acceptedFiles) {
    console.log("Got some files", acceptedFiles);

    const reader = new FileReader();
    reader.onload = function (event) {
      var contents = event.target.result;
      console.log("The content of the file is ", contents);

      const tf = new TransactionsFile();
      tf.file_content = contents;
      tf.file_name = acceptedFiles[0].name;
      apiCalls.uploadNewCsvFile(tf);
    };
    reader.readAsText(acceptedFiles[0]);
  }

  return (
    <Grid container>
      <Grid>
        <MDBox className="UploadButton">
          <DropZone onFileAdded={onFileAdded} />
        </MDBox>
      </Grid>
    </Grid>
  );
}
