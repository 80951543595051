import { Badge, Card, Checkbox, Chip, Dialog, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Icon, IconButton, Radio, RadioGroup, TextField } from "@mui/material";
import { data } from "browserslist";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { CloseableCard } from "components/TitledCard/GenericCard";
import RichCategories from "domain/RichCategories";
import { useLocation } from "react-router-dom";
import { ruleContainsText } from "utils/RuleUtils";
import { generateRuleText } from "utils/RuleUtils";
import MultiAutocompleteCategorySelector from "../classificationflow/multiautocompletecategoryselector";
import { TransactionList } from "../expensestable/TransactionListPage";

const { useEffect, useState } = require("react");

export function RuleTile({ statistics, rule, richCats, onShowTransactions, apiCalls, showTransactionsButton }) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <MDBox mb={3}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <MDTypography fontWeight="bold">{rule.specific ? "Category assignment" : "Rule"}</MDTypography>
            </Grid>
            <Grid item>
              {!rule.frozen && (
                <IconButton onClick={(ev) => apiCalls.deleteRuleById(rule.id)}>
                  <Icon fontSize="small">delete</Icon>
                </IconButton>
              )}
            </Grid>
          </Grid>
        </MDBox>
      </Grid>

      <Grid item xs={12}>
        <MDTypography variant="caption" fontWeight="regular" color="text">
          Number of matching transactions:
        </MDTypography>
        <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
          {" " + statistics.matching_transaction_ids?.length}
        </MDTypography>
      </Grid>

      <Grid item xs={12}>
        <MDTypography variant="caption" fontWeight="medium" textTransform="capitalize">
          {generateRuleText(rule)}
        </MDTypography>
      </Grid>

      <Grid item xs={12}>
        <MDBox mt={3}>
          <Grid container justifyContent={"space-between"}>
            <Grid item>
              <MDBox m={0}>
                <Chip label={" " + richCats.getCategoryById(rule.categoryId).name} />
              </MDBox>
            </Grid>
            {showTransactionsButton && (
              <Grid item>
                <MDBox m={0}>
                  <MDButton variant="outlined" color="dark" startIcon={<Icon>view_list</Icon>} onClick={(ev) => onShowTransactions(statistics.matching_transaction_ids)}>
                    Show matched transactions
                  </MDButton>
                </MDBox>
              </Grid>
            )}
          </Grid>
        </MDBox>
      </Grid>
    </Grid>
  );
}

function RuleStatisticsToolBar({
  includeNormal,
  setIncludeNormal,
  indludeSystem,
  setIncludeSystem,
  includeSpecific,
  setIncludeSpecific,
  sorting,
  setSorting,
  searchText,
  setSearchText,
  selectedCategories,
  setSelectedCategories,
  categories,
}) {
  return (
    <MDBox ml={1}>
      <Grid container justifyContent="space-around" alignItems={"center"}>
        <Grid item>
          <FormControl>
            <FormLabel>Sorting</FormLabel>
            <RadioGroup value={sorting} onChange={(ev) => setSorting(ev.target.value)}>
              <FormControlLabel value="matches" control={<Radio />} label="Number of matching Transactions" />
              <FormControlLabel value="id" control={<Radio />} label="Creation time" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item>
          <FormGroup>
            <FormLabel>Selection</FormLabel>
            <FormControlLabel label="Include normal Rules" control={<Checkbox checked={includeNormal} onChange={(ev) => setIncludeNormal(ev.target.checked)} />} />
            <FormControlLabel label="Include System Rules" control={<Checkbox checked={indludeSystem} onChange={(ev) => setIncludeSystem(ev.target.checked)} />} />
            <FormControlLabel label="Include single transaction assignments" control={<Checkbox checked={includeSpecific} onChange={(ev) => setIncludeSpecific(ev.target.checked)} />} />
          </FormGroup>
        </Grid>
        <Grid item>
          <MultiAutocompleteCategorySelector selectedCategories={selectedCategories} selectedCategoriesChanged={setSelectedCategories} categories={categories} label="Select categories" />
        </Grid>
        <Grid item>
          <Grid container justifyContent="flex-end">
            <TextField id="outlined-basic" label="Search" variant="outlined" value={searchText} onChange={(ev) => setSearchText(ev.target.value)} />
            <IconButton onClick={() => setSearchText("")}>
              <Icon>clear</Icon>
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </MDBox>
  );
}

function RuleStatisticsPage({ applicationData, apiClient, apiCalls, callbacks }) {
  useEffect(() => apiCalls.updateAnalysisV2IfNeeded());

  const [includeNormal, setIncludeNormal] = useState(true);
  const [includeSystem, setIncludeSystem] = useState(false);
  const [includeSpecific, setIncludeSpecific] = useState(false);

  const [searchText, setSearchText] = useState("");

  const [sorting, setSorting] = useState("matches");
  const [selectedCategories, setSelectedCategories] = useState([]);

  const [offset, setOffset] = useState(0);
  const resultsPerPage = 10;

  const [transactionsToShow, setTransactionsToShow] = useState(undefined);

  const location = useLocation();
  useEffect(() => {
    apiCalls.setHelpCallback(undefined); // TODO
  }, [location]);

  var offsetEnd = undefined;

  var ruleTiles = "";
  var relevantRules = undefined;

  function onNext() {
    setOffset(offsetEnd);
  }

  function onPrevious() {
    setOffset(Math.max(0, offset - resultsPerPage));
  }

  if (applicationData.analysisV2?.rule_statistics) {
    const richCats = new RichCategories(applicationData.categories);
    const allIds = Object.keys(applicationData.analysisV2?.rule_statistics);
    const allSelectedCategoryIds = selectedCategories.map((cat) => cat.id);
    relevantRules = allIds
      ?.map((id) => {
        return { statistics: applicationData.analysisV2?.rule_statistics[id], rule: applicationData.rules.filter((rule) => rule.id == id)[0] };
      })
      .filter((data) => data.rule)
      .filter((data) => !data.rule.specific || includeSpecific)
      .filter((data) => !data.rule.frozen || includeSystem)
      .filter((data) => allSelectedCategoryIds.length == 0 || allSelectedCategoryIds.includes(data.rule.categoryId))
      .filter((data) => searchText == "" || ruleContainsText(data.rule, searchText))
      .filter((data) => {
        const isNormalRule = !data.rule.specific && !data.rule.frozen;
        return !isNormalRule || includeNormal;
      })
      .sort(function (r1, r2) {
        if (sorting == "matches") {
          return r2.statistics.matching_transaction_ids.length - r1.statistics.matching_transaction_ids.length;
        } else {
          return r2.rule.id - r1.rule.id;
        }
      });

    offsetEnd = Math.min(offset + resultsPerPage, relevantRules.length);

    ruleTiles = relevantRules.slice(offset, offset + resultsPerPage).map((data) => (
      <Grid item md={12} xl={6} key={data.rule.id} alignItems={"stretch"}>
        <MDBox m={1} style={{ height: "100%" }}>
          <Card style={{ height: "100%" }}>
            <MDBox m={2} ml={3}>
              <RuleTile statistics={data.statistics} rule={data.rule} richCats={richCats} onShowTransactions={setTransactionsToShow} apiCalls={apiCalls} showTransactionsButton={true} />
            </MDBox>
          </Card>
        </MDBox>
      </Grid>
    ));
  }

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <RuleStatisticsToolBar
            includeNormal={includeNormal}
            includeSpecific={includeSpecific}
            includeSystem={includeSystem}
            setIncludeNormal={setIncludeNormal}
            setIncludeSpecific={setIncludeSpecific}
            setIncludeSystem={setIncludeSystem}
            sorting={sorting}
            setSorting={setSorting}
            searchText={searchText}
            setSearchText={setSearchText}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
            categories={applicationData.categories}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {ruleTiles}
        <Grid item xs={12}>
          <MDBox m={3}>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <MDTypography variant="button" fontWeight="light" textTransform="capitalize">
                  Showing {offset || 1} - {offsetEnd || 1} of {relevantRules?.length || 0} rules
                </MDTypography>
              </Grid>
              <Grid item>
                <IconButton onClick={onPrevious}>
                  {/* <NavigateBeforeIcon /> */}
                  <Icon>navigate_before</Icon>
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton onClick={onNext}>
                  {/* <NavigateNextIcon /> */}
                  <Icon>navigate_next</Icon>
                </IconButton>
              </Grid>
            </Grid>
          </MDBox>
        </Grid>
      </Grid>
      <Dialog fullWidth maxWidth="xl" open={transactionsToShow != undefined}>
        <CloseableCard onClose={() => setTransactionsToShow(undefined)}>
          <MDBox ml={1} mr={1}>
            <TransactionList
              categories={applicationData.categories}
              categoryAssignment={applicationData.categoryAssignment}
              apiClient={apiClient}
              callbacks={callbacks}
              applicationData={applicationData}
              useCookies={false}
              apiCalls={apiCalls}
              transactions={applicationData?.rawTransactions.filter((t) => transactionsToShow != undefined && transactionsToShow.includes(t.id))}
            />
          </MDBox>
        </CloseableCard>
      </Dialog>
    </div>
  );
}

export default RuleStatisticsPage;
