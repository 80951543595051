import MDTypography from "components/MDTypography";

const humanReadableMapping = { counter_part_name: "Counterpart name" };

function getHumanReadableVersion(fieldName) {
  if (fieldName in humanReadableMapping) {
    return humanReadableMapping[fieldName];
  } else {
    return fieldName;
  }
}

function generateTextForAmountCondition(condition) {
  return "Amount Condition";
}

function generateTextForDateCondition(condition) {
  return "Date Condition";
}

function generateTextForStringCondition(condition) {
  const comparisonWord = condition.exact_match ? " be the same as " : " contain ";
  return (
    <MDTypography variant="caption" fontWeight="regular" color="text" key={"StringCondition" + crypto.randomUUID()}>
      {getHumanReadableVersion(condition.field_name)} should {comparisonWord}{" "}
      <MDTypography variant="caption" fontWeight="medium">
        {"'" + condition.query + "'"}
      </MDTypography>
    </MDTypography>
  );
}

function generateTextForAndCondition(condition) {
  const listOfComponentsToPush = ["( "];

  for (let i = 0; i < condition.underlying_conditions.length; i++) {
    listOfComponentsToPush.push(generateTextForCondition(condition.underlying_conditions[i]));
    if (i < condition.underlying_conditions.length - 1) {
      listOfComponentsToPush.push(
        <MDTypography variant="caption" key={"WordAnd" + crypto.randomUUID()} color="text" fontWeight="medium" textTransform="capitalize">
          {" "}
          AND{" "}
        </MDTypography>
      );
    }
  }
  listOfComponentsToPush.push(" )");
  return listOfComponentsToPush;
}

function generateTextForOrCondition(condition) {
  const listOfComponentsToPush = ["( "];

  for (let i = 0; i < condition.underlying_conditions.length; i++) {
    listOfComponentsToPush.push(generateTextForCondition(condition.underlying_conditions[i]));
    if (i < condition.underlying_conditions.length - 1) {
      listOfComponentsToPush.push(
        <MDTypography key={"WordOr" + crypto.randomUUID()} variant="caption" color="text" fontWeight="medium" textTransform="capitalize">
          {" "}
          OR{" "}
        </MDTypography>
      );
    }
  }
  listOfComponentsToPush.push(" )");
  return listOfComponentsToPush;
}

function generateTextForCondition(condition) {
  if (condition.amount_condition) {
    return generateTextForAmountCondition(condition.amount_condition);
  } else if (condition.date_condition) {
    return generateTextForDateCondition(condition.date_condition);
  } else if (condition.string_condition) {
    return generateTextForStringCondition(condition.string_condition);
  } else if (condition.and_condition) {
    return generateTextForAndCondition(condition.and_condition);
  } else if (condition.or_condition) {
    return generateTextForOrCondition(condition.or_condition);
  }
}

export function generateRuleText(rule) {
  if (rule.specific) {
    return (
      <MDTypography variant="caption" fontWeight="medium">
        Specific assignment
      </MDTypography>
    );
  }
  if (rule.frozen) {
    return (
      <MDTypography variant="caption" fontWeight="medium">
        Budgex Back-up Rule
      </MDTypography>
    );
  }
  return generateTextForCondition(rule.condition);
}

function extractAllStrings(condition) {
  if (condition.amount_condition) {
    return "";
  } else if (condition.date_condition) {
    return "";
  } else if (condition.string_condition) {
    return condition.string_condition.query;
  } else if (condition.and_condition) {
    return condition.and_condition.underlying_conditions.map((uc) => extractAllStrings(uc)).join();
  } else if (condition.or_condition) {
    return condition.or_condition.underlying_conditions.map((uc) => extractAllStrings(uc)).join();
  }
  return "";
}

export function ruleContainsText(rule, text) {
  return extractAllStrings(rule.condition).toLowerCase().includes(text.toLowerCase());
}
